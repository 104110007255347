import {
  IBrokerCodeInfo,
  ICloneApplicationInput,
  ICloneApplicationOutput,
  ICreateApplicationFromAcordInput,
  ICreateApplicationInput,
  ICreateApplicationOutput,
  IGetAvailableCarriersForAgentInput,
  IGetAvailableCarriersForAgentOutput,
  IGetCarrierRankingInput,
  IGetCarrierRankingOutput,
} from '@coverforce-platform/cf-common-api-model';
import { Carrier } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  cloneApplication as requestCloneApplication,
  createApplication as requestCreateApplication,
  createApplicationFromAcord as requestCreateApplicationFromAcord,
  getApplication,
} from '../../../externalServices/V2/applicationV2';
import { getBrokerCodesByAgencyId } from '../../../externalServices/V2/brokerConfig';
import { getCarrierRankingByPolicyTypeId as requestGetCarrierRankingByPolicyTypeId } from '../../../externalServices/V2/carrierRankingV2';
import { getAvailableCarriers as requestGetAvailableCarriers } from '../../../externalServices/V2/carriersV2';
import { PAGE_ROUTES } from '../../../globalConstants';
import { error as errorAlert } from '../../../ui-core/Notification';
import { ACTION_TYPE, NEW_QUOTE_MESSAGES } from './constants';
import { INewQuoteStore } from './interface';
import { getSupportedCarriers } from './utils';

// Action name
const {
  SET_NEW_QUOTE_CARRIERS,
  SET_NEW_QUOTE_CARRIERS_ERROR,
  SET_NEW_QUOTE_SUPPORTED_CARRIERS,
  SET_NEW_QUOTE_SUPPORTED_CARRIERS_ERROR,
  CLEAR_NEW_QUOTE_STORE,
  SET_SUPPORTED_CARRIERS_LOADING,
  FETCH_APPLICATION_BY_ID_LOADING,
  FETCH_APPLICATION_BY_ID,
  FETCH_APPLICATION_BY_ID_ERROR,
} = ACTION_TYPE;

const initCarriersStoreState = {
  carriersData: [],
  carriersLoading: true,
  carriersError: null,
};

const initSupportedCarriersStore = {
  supportedCarriersData: {},
  supportedCarriersLoading: false,
  supportedCarriersError: null,
};

const initAppetiteDetailsStore = {
  appetiteData: [],
  appetiteLoading: false,
  appetiteError: null,
  inAppetiteCarriers: [],
  notInAppetiteCarriers: [],
  noBrokerCodeCarriers: [],
};

const initBrokerConfigData = { brokerIdConfigs: undefined, brokerConfigsLoading: false, brokerConfigsError: undefined };

export const useNewQuoteStore = create<INewQuoteStore>()(
  devtools(
    (set, get) => ({
      carriersStore: {
        ...initCarriersStoreState,
      },
      supportedCarriersStore: {
        ...initSupportedCarriersStore,
      },
      newQuoteModalForm: undefined,

      newApplicationId: '',
      createNewApplicationLoading: false,
      presetAccountId: undefined,
      cloningApplication: undefined,
      clonedApplicationId: undefined,
      clonedApplication: {
        loading: false,
        error: undefined,
        data: undefined,
      },
      appetiteDetailsStore: { ...initAppetiteDetailsStore },

      brokerConfigDataStore: { ...initBrokerConfigData },

      fetchApplicationById: async (applicationId: string) => {
        try {
          set(
            { clonedApplication: { loading: true, error: undefined, data: undefined } },
            false,
            FETCH_APPLICATION_BY_ID_LOADING,
          );
          const application = await getApplication({ applicationId });
          set(
            { clonedApplication: { loading: false, error: undefined, data: application } },
            false,
            FETCH_APPLICATION_BY_ID,
          );
          return application;
        } catch (e) {
          set(
            { clonedApplication: { loading: false, error: e, data: undefined } },
            false,
            FETCH_APPLICATION_BY_ID_ERROR,
          );
          return;
        }
      },

      fetchAvailableCarriersByAgentId: async (params: IGetAvailableCarriersForAgentInput) => {
        try {
          const response: IGetAvailableCarriersForAgentOutput = await requestGetAvailableCarriers({
            agentId: params?.agentId,
          });
          set(
            {
              carriersStore: { carriersData: response?.availableCarriers, carriersError: null, carriersLoading: false },
            },
            false,
            SET_NEW_QUOTE_CARRIERS,
          );
        } catch (error: any) {
          set(
            { carriersStore: { carriersData: [], carriersError: error, carriersLoading: false } },
            false,
            SET_NEW_QUOTE_CARRIERS_ERROR,
          );
        }
      },

      fetchCarrierRankingByPolicyTypeId: async (params: IGetCarrierRankingInput) => {
        try {
          set(
            {
              supportedCarriersStore: {
                ...initSupportedCarriersStore,
                supportedCarriersLoading: true,
              },
            },
            false,
            SET_SUPPORTED_CARRIERS_LOADING,
          );
          const response: IGetCarrierRankingOutput = await requestGetCarrierRankingByPolicyTypeId({
            agentId: params?.agentId,
            policyTypeId: params?.policyTypeId,
          });
          const {
            carriersStore: { carriersData },
          } = get();

          const supportedCarriersArr: Array<{
            carrierId: Carrier;
            carrierDisplayName: string;
          }> = getSupportedCarriers(carriersData, response?.onCarriers, params?.policyTypeId);

          set(
            {
              supportedCarriersStore: {
                supportedCarriersData: { [params?.policyTypeId]: supportedCarriersArr },
                supportedCarriersError: null,
                supportedCarriersLoading: false,
              },
            },
            false,
            SET_NEW_QUOTE_SUPPORTED_CARRIERS,
          );
        } catch (error: any) {
          set(
            {
              supportedCarriersStore: {
                supportedCarriersData: {},
                supportedCarriersError: error,
                supportedCarriersLoading: false,
              },
            },
            false,
            SET_NEW_QUOTE_SUPPORTED_CARRIERS_ERROR,
          );
        }
      },

      fetchBrokerConfigs: async ({ agencyId, brokerConfigsLoading = true }) => {
        try {
          set(
            {
              brokerConfigDataStore: {
                ...initBrokerConfigData,
                brokerConfigsLoading,
              },
            },
            false,
            ACTION_TYPE.SET_NEW_QUOTE_BROKER_CONFIGS_LOADING,
          );
          const agencyBrokerCodes = await getBrokerCodesByAgencyId({
            agencyId,
          });
          const brokerIdConfigs: { [key: string]: IBrokerCodeInfo[] } = {};
          agencyBrokerCodes.brokerCodes.forEach((item) => {
            if (item.isEnabled) {
              if (brokerIdConfigs[item.carrierId]) {
                brokerIdConfigs[item.carrierId].push(item);
              } else {
                brokerIdConfigs[item.carrierId] = [item];
              }
            }
          });
          set(
            {
              brokerConfigDataStore: {
                brokerIdConfigs,
                brokerConfigsLoading: false,
                brokerConfigsError: undefined,
              },
            },
            false,
            ACTION_TYPE.SET_NEW_QUOTE_BROKER_CONFIGS,
          );
        } catch (err) {
          set(
            {
              brokerConfigDataStore: {
                brokerIdConfigs: undefined,
                brokerConfigsLoading: false,
                brokerConfigsError: err,
              },
            },
            false,
            ACTION_TYPE.SET_NEW_QUOTE_BROKER_CONFIGS_ERROR,
          );
        }
      },

      createNewApplication: async (params: ICreateApplicationInput) => {
        try {
          set({ createNewApplicationLoading: true }, false, ACTION_TYPE.CREATE_NEW_APPLICATION_LOADING);
          const response: ICreateApplicationOutput = await requestCreateApplication(params);
          set(
            { newApplicationId: response?.applicationId, createNewApplicationLoading: false },
            false,
            ACTION_TYPE.CREATE_NEW_APPLICATION,
          );
        } catch (error: any) {
          errorAlert(error?.[0]?.errorMessage);
          set(
            { newApplicationId: '', createNewApplicationLoading: false },
            false,
            ACTION_TYPE.CREATE_NEW_APPLICATION_ERROR,
          );
        }
      },

      cloneApplication: async (params: ICloneApplicationInput) => {
        try {
          set({ createNewApplicationLoading: true }, false, ACTION_TYPE.CLONE_APPLICATION_LOADING);
          const response: ICloneApplicationOutput = await requestCloneApplication(params);
          set(
            { newApplicationId: response?.newApplicationId, createNewApplicationLoading: false },
            false,
            ACTION_TYPE.CLONE_APPLICATION,
          );
          return response;
        } catch (error: any) {
          errorAlert(error?.[0]?.errorMessage || NEW_QUOTE_MESSAGES.applicationCloneFailed);
          set({ newApplicationId: '', createNewApplicationLoading: false }, false, ACTION_TYPE.CLONE_APPLICATION_ERROR);
          return undefined;
        }
      },

      createNewApplicationFromAcord: async (params: ICreateApplicationFromAcordInput) => {
        try {
          set({ createNewApplicationLoading: true }, false, ACTION_TYPE.CREATE_NEW_APPLICATION_FROM_ACCORD_LOADING);
          const response = await requestCreateApplicationFromAcord(params);
          set(
            { newApplicationId: response?.applicationId, createNewApplicationLoading: false },
            false,
            ACTION_TYPE.CREATE_NEW_APPLICATION_FROM_ACCORD,
          );
        } catch (error: any) {
          errorAlert(error?.[0]?.errorMessage);
          set(
            { newApplicationId: '', createNewApplicationLoading: false },
            false,
            ACTION_TYPE.CREATE_NEW_APPLICATION_FROM_ACCORD_ERROR,
          );
        }
      },

      updateNewQuoteByKey: async (storeKey: keyof INewQuoteStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, ACTION_TYPE.UPDATE_NEW_QUOTE_STORE_BY_KEY),

      clearNewQuoteStore: () => {
        const { presetAccountId } = get();
        set(
          {
            carriersStore: {
              ...initCarriersStoreState,
            },

            supportedCarriersStore: {
              ...initSupportedCarriersStore,
            },

            brokerConfigDataStore: { ...initBrokerConfigData },
            newApplicationId: '',
            createNewApplicationLoading: false,
            presetAccountId: location.pathname.includes(PAGE_ROUTES.ACCOUNTS) ? presetAccountId : undefined,
            newQuoteModalForm: undefined,
            clonedApplicationId: undefined,
            clonedApplication: {
              loading: false,
              error: undefined,
              data: undefined,
            },
          },
          false,
          CLEAR_NEW_QUOTE_STORE,
        );
      },
    }),
    { name: ACTION_TYPE.NAME, anonymousActionType: ACTION_TYPE.ANONYMOUS_ACTION },
  ),
);
