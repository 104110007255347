import { usePaymentStore } from '../../payment/store';
import { RenderPaymentPlanFeeBreakUpView } from './componentUtils';
import { PREMIUM_BREAK_UP_TITLE } from './constants';
import { CFModalStyled } from './styles';

const PaymentFeeBreakUp = () => {
  const { selectedPaymentPlanId, paymentPlans } = usePaymentStore();
  const selectedPaymentPlan = paymentPlans?.find((plan) => plan.cfPaymentPlanId === selectedPaymentPlanId);

  return (
    <CFModalStyled footer={null} title={PREMIUM_BREAK_UP_TITLE} width={600}>
      {RenderPaymentPlanFeeBreakUpView(selectedPaymentPlan!)}
    </CFModalStyled>
  );
};

export default PaymentFeeBreakUp;
