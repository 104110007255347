import { ContactsOutlined, PullRequestOutlined, SolutionOutlined, TeamOutlined } from '@ant-design/icons';

import { useConfigurationStore } from '../../configuration/store';

export const getSettingsMenu = () => {
  const { userTheme } = useConfigurationStore.getState();

  return [
    {
      id: 'settingsBrokerConfig',
      icon: SolutionOutlined,
      to: '/settings/brokerconfig',
      label: 'Broker Configuration',
      visible: true,
    },
    {
      id: 'settingsCarrierConfig',
      icon: PullRequestOutlined,
      to: '/settings/carrierconfig',
      label: 'Carrier Rankings',
      visible: !userTheme?.config?.disabledCarrierRankingsMenu,
    },
    { id: 'settingsTeamManagement', icon: TeamOutlined, to: '/settings/team', label: 'Team Management', visible: true },
    {
      id: 'settingsNetworkContacts',
      icon: ContactsOutlined,
      to: '/settings/network-contacts',
      label: 'Network Contacts',
      visible: true,
    },
  ];
};
