import { Duration } from '@coverforce-platform/cf-common-types';

import { IGenerateReportStore } from './interface';

export const GENERATE_REPORT_MESSAGES = {
  ERROR_GENERATE_REPORT: 'Some error occurred while generating report',
  SUCCESS_GENERATE_REPORT: 'Report is generated successfully!',
};

export const GENERATE_REPORT_STORE_META_DATA = {
  UPDATE_GENERATE_REPORT_STORE_BY_KEY: 'UPDATE_GENERATE_REPORT_STORE_BY_KEY',
  GENERATE_REPORT_LOADING: 'GENERATE_REPORT_LOADING',
  ERROR_GENERATE_REPORT: 'ERROR_GENERATE_REPORT',
};

export const GENERATE_REPORT_QUESTION_KEYS = {
  REPORT_TYPE: 'reportType',
  TO_EMAIL_LIST: 'toEmailList',
  REPORT_DURATION: 'reportDuration',
  TIME_FRAME_DATE_RANGE: 'timeFrameDateRange',
  IS_REPORT_DOWNLOAD: 'isReportDownload',
};

export const REPORT_DURATION_CUSTOM_OPTION = {
  displayValue: 'Custom',
  toolTipText:
    'Select a custom report duration date range, start and end date will be asked in the next question once this option is selected',
  value: 'CUSTOM' as unknown as Duration,
};

export const DEFAULT_DATE_TIME_FRAME_START = '00:00:00';

export const DEFAULT_DATE_TIME_FRAME_END = '23:59:59';

export const GENERATE_REPORT_STORE_KEYS: { [key: string]: keyof IGenerateReportStore } = {
  GENERATE_REPORT_FORM: 'generateReportForm',
  GENERATE_REPORT_ELEMENTS: 'generateReportElements',
  GENERATE_REPORT_LOADING: 'generateReportLoading',
};
