import { IQuotesStore } from '../components/quotes/interface';

export const QUOTES_STORE_KEYS: { [key: string]: keyof IQuotesStore } = {
  QUOTES_DATA: 'quotesData',
  SELECTED_QUOTE: 'selectedQuote',
  REGENERATE_QUOTE_CARRIER_ID: 'regenerateQuoteCarrierId',
};

export const FE_QUOTE_FAILURE_CODES = {
  DCL_STATE: 'declined_reason_state',
  DCL_UW: 'declined_reason_underwriter',
  CTO: 'carrier_timeout',
};
