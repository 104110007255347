import { Duration } from '@coverforce-platform/cf-common-types';

import { MenuConfig } from '../../../components/menuActionButton/interface';
import { MENU_ITEM_KEY } from '../../../globalConstants';
import { IReportCenterStore } from './interface';

export const REPORT_PAGE = {
  TITLE: 'Reports Center',
  SUB_TITLE:
    'Generate your reports here. You can generate one-off or schedule recurring application or quote level reports.',
};

export const REPORT_CENTER_STORE_META_DATA = {
  FETCH_REPORT_SCHEDULES_LOADING: 'FETCH_REPORT_SCHEDULES_LOADING',
  SET_REPORT_SCHEDULES: 'SET_REPORT_SCHEDULES',
  UPDATE_REPORT_CENTER_STORE_BY_KEY: 'UPDATE_REPORT_CENTER_STORE_BY_KEY',
  SET_REPORT_TYPE_INFO: 'SET_REPORT_TYPE_INFO',
};

export const REPORT_CENTER_MESSAGES = {
  ERROR_FETCH_REPORT_SCHEDULES: 'Some error occurred while fetching the reporting schedules',
  ERROR_DELETE_REPORT_SCHEDULE: 'Some error occurred while deleting report schedule',
  SUCCESS_DELETE_REPORT_SCHEDULE: 'Report Schedule deleted successfully!',
  ERROR_FETCH_REPORT_TYPE_INFO: 'Some error occurred while fetching the report type info',
};

export const REPORT_CENTER_STORE_KEYS: { [key: string]: keyof IReportCenterStore } = {
  DELETE_SCHEDULE_REPORT_LOADING: 'deleteScheduledReportLoading',
};

export const MENU_CONFIG: MenuConfig[] = [
  {
    label: 'Edit Schedule',
    key: MENU_ITEM_KEY.EDIT,
  },
  {
    label: 'Delete Schedule',
    key: MENU_ITEM_KEY.DELETE,
    extraData: { danger: 'danger' },
  },
];

export const ReportDurationInfo = [
  {
    displayValue: 'Current week',
    toolTipText: 'Data from the last seven days',
    value: Duration.CURRENT_WEEK,
  },
  {
    displayValue: 'Last week',
    toolTipText: 'Data from the previous week, Monday to Sunday',
    value: Duration.LAST_WEEK,
  },
  {
    displayValue: 'Last 2 weeks',
    toolTipText: 'Data from the previous 2 weeks, Monday to Sunday',
    value: Duration.LAST_TWO_WEEKS,
  },
  {
    displayValue: 'Month to date',
    toolTipText: 'Data from the start of the current month until today',
    value: Duration.MTD,
  },
  {
    displayValue: 'Year to date',
    toolTipText: 'Data from the start of the current year until today',
    value: Duration.YTD,
  },
  {
    displayValue: 'Last 24 hours',
    toolTipText: 'Data from the past 24 hours',
    value: Duration.LAST_24_HOURS,
  },
  {
    displayValue: 'All time',
    toolTipText: 'Data from the beginning to the present',
    value: Duration.ALL_TIME,
  },
];
