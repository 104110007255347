import { ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';

import CFButton from '../../../ui-core/V2/cfButton/cfButton';
import CFFileDropper from '../../../ui-core/V2/cfFileDropper/cfFileDropper';
import CFFormItem from '../../../ui-core/V2/cfFormItem/cfFormItem';
import CFModal from '../../../ui-core/V2/cfModal/cfModal';
import CFTable from '../../../ui-core/V2/cfTable/cfTable';

export const UploadDocumentButton = styled(CFButton)``;

export const UploadDocumentButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  margin-bottom: 0px;
  padding-bottom: 20px;
`;

export const CFFileDropperStyled = styled(CFFileDropper)`
  // .ant-upload {
  //   padding: 4px 0 !important;
  // }
`;

export const DocUploadTextStyled = styled.p<{
  disabled: boolean;
}>`
  color: ${(p) => (p.disabled ? p.theme.colors.grayscale.blackOpacity : p.theme.custom.application.blackOpacity)};
  font-size: ${(p) => p?.theme?.typography?.fontSize?.mini};
`;

export const InboxOutlinedStyled = styled(InboxOutlined)<{
  disabled: boolean;
}>`
  opacity: ${(p) => (p.disabled ? 0.25 : 1)};
  > svg {
    color: ${(p) => p?.theme?.colorScheme?.primary};
    font-size: ${(p) => p?.theme?.typography?.fontSize?.xl};
  }
`;

export const InboxOutlinedWrapperStyled = styled.p`
  margin-bottom: 0px !important;
`;

export const UploadDocumentFormItemStyled = styled(CFFormItem)`
  .ant-upload-list-item .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
`;

export const ViewDocumentWrapperStyled = styled.div``;

export const ViewDocumentTitleStyled = styled.div`
  font-size: ${(p) => p?.theme?.typography?.fontSize?.extendedMedium};
  font-weight: ${(p) => p?.theme?.typography?.fontWeight?.mediumBold};
`;

export const ViewDocumentTableStyled = styled(CFTable)`
  margin-top: 8px;
  .ant-table-tbody > tr > td {
    padding: 12px 16px !important;
  }
`;

export const DownloadDocumentButtonStyled = styled(CFButton)`
  margin-right: 10px;
`;

export const DeleteDocumentButtonStyled = styled(CFButton)``;

export const DateCellStyled = styled.div`
  white-space: nowrap;
`;

export const ViewActionButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CFModalStyled = styled(CFModal)`
  top: 70px;
  .ant-modal-content {
    max-height: 85vh !important;
  }
`;

export const IconWrapper = styled.div`
  width: fit-content;
`;

export const ModalContentWrapper = styled.div`
  display: flex;
`;

export const ExclaimationMarkStyled = styled(ExclamationCircleOutlined)`
  color: ${(p) => p.theme.colors.common.warningColor};
  margin-right: 20px;
  > svg {
    width: 25px;
    height: 25px;
    margin-top: 4px;
  }
`;

export const EmptyMessageStyled = styled.div`
  margin-bottom: 20px;
`;

export const DocumentCenterModalContentStyled = styled.div<{ isVisible: boolean }>`
  ${(p) => (p?.isVisible ? 'display: block' : 'display: none')}
`;

export const ConfirmModalContentStyled = styled.div<{ isVisible: boolean }>`
  ${(p) => (p?.isVisible ? 'display: block' : 'display: none')}
`;
