import { IDependencyInfo } from '@coverforce-platform/cf-common-api-model';

import { CONDITION_PROPS } from '../../globalConstants';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { evaluateCondition } from '../../utils/evaluateCondition';
import { CONDITION_TYPE } from '../applicationQuestion/constants';
import { IApplicationQuestion } from '../applicationQuestion/interface';

export const isChildQuestionRender = ({
  dependentQuestion,
  question,
  getFieldValue,
  prefixDataIndex = [],
  form,
  value,
}: {
  dependentQuestion: IDependencyInfo;
  question: IApplicationQuestion;
  getFieldValue?: Function;
  prefixDataIndex?: string[];
  form?: IFormInstance;
  value?: any;
}): boolean => {
  const dataIndex: string[] = prefixDataIndex
    ? prefixDataIndex.concat([question.dataIndex || ''])
    : [question.dataIndex || ''];

  const formValue = value ?? (getFieldValue && getFieldValue(dataIndex));

  if (form && form.getFieldError(dataIndex)?.length > 0) {
    return false;
  }

  switch (dependentQuestion.dependencyType) {
    // if dependency question has CONDITIONAL type
    case CONDITION_TYPE.CONDITIONAL: {
      if (question.dataIndex) {
        // check and render child question when dependency condition is bool
        if (typeof dependentQuestion?.condition === 'boolean') {
          if (formValue === dependentQuestion?.condition) {
            return true;
          }
        }

        // check condition has or operator or not, if yes then we have to check condition for multiple value
        else if (typeof dependentQuestion?.condition === 'string' && dependentQuestion?.condition?.includes('|')) {
          if (!Array.isArray(formValue) && dependentQuestion.condition.split('|').includes(formValue)) {
            return true;
          }
          // incase of multi select, formValue is an array, we need to iterate over all the selected options
          if (Array.isArray(formValue) && formValue.length > 0) {
            return formValue.some((val) => dependentQuestion?.condition?.split('|').includes(val));
          }
        } else if (
          typeof dependentQuestion?.condition === 'string' &&
          (dependentQuestion?.condition?.includes('<') ||
            dependentQuestion?.condition?.includes('>') ||
            dependentQuestion?.condition?.includes('>=') ||
            dependentQuestion?.condition?.includes('<=') ||
            dependentQuestion?.condition?.includes('&&') ||
            dependentQuestion?.condition?.includes('||'))
        ) {
          // TODO: required testing on this.
          if (
            evaluateCondition(
              `${CONDITION_PROPS.CURRENT_VALUE} ${dependentQuestion?.condition}`,
              formValue,
              question.subtype || question.type,
            )
          ) {
            return true;
          }
        }

        // check entered value by user is same as condition is yes then need to render child question
        else if (
          formValue &&
          typeof formValue === 'string' &&
          typeof dependentQuestion?.condition === 'string' &&
          formValue.toLowerCase() === dependentQuestion.condition.toLowerCase()
        ) {
          return true;
        }
      }
      return false;
    }

    default:
      return false;
  }
};
