import { useRoutes } from 'react-router-dom';

export const getPublicRoutes = () => [
  {
    path: '/public/payment-redirect/:carrierId',
    element: <div>Payment Redirect</div>,
  },
];

const PublicAppRoutes = () => {
  const routes = getPublicRoutes();
  return useRoutes(routes);
};

export default PublicAppRoutes;
