import { IAppetiteInfo, ICheckAppetiteInput } from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { checkAppetite as requestAppetiteDetails } from '../../externalServices/V2/appetite';
import { APPETITE_TYPE } from '../modals/newQuoteV2/constants';
import { useNewQuoteStore } from '../modals/newQuoteV2/store';
import { ACCORD_CARRIER_LIST_STORE_KEYS, ACTION_TYPE } from './constants';
import { IAppetiteCarrierListContainerStore, IAppetiteField } from './interface';

export const initAppetiteDetailsStore = {
  appetiteData: [],
  appetiteLoading: false,
  appetiteError: null,
  inAppetiteCarriers: [],
  notInAppetiteCarriers: [],
  noBrokerCodeCarriers: [],
};

export const useAppetiteCarrierListContainerStore = create<IAppetiteCarrierListContainerStore>()(
  devtools(
    (set, get) => ({
      inAppetiteCheckboxGroup: [],
      notInAppetiteCheckboxGroup: [],
      selectedCarriers: [],
      appetiteDetailsStore: { ...initAppetiteDetailsStore },

      fetchAppetiteDetails: async (params: ICheckAppetiteInput) => {
        const { updateAppetiteCarrierListContainerByKey } = get();
        const {
          brokerConfigDataStore,
          clonedApplication,
          carriersStore: { carriersData },
        } = useNewQuoteStore.getState();
        try {
          set(
            {
              appetiteDetailsStore: {
                ...initAppetiteDetailsStore,
                appetiteLoading: true,
              },
            },
            false,
            ACTION_TYPE.SET_APPETITE_LOADING,
          );
          const { brokerIdConfigs } = brokerConfigDataStore;
          const response: IAppetiteInfo[] = await requestAppetiteDetails(params);
          const inAppetiteCarriers: IAppetiteField[] = [];
          const notInAppetiteCarriers: IAppetiteField[] = [];
          const noBrokerCodeCarriers: IAppetiteField[] = [];
          const inAppetiteSelectedCarriers: string[] = [];
          const notInAppetiteSelectedCarriers: string[] = [];

          // hasAppetite contract will change from backend soon
          if (response) {
            response.forEach((item) => {
              const carrierShortName = carriersData?.filter((carrier) => carrier.carrierId === item.carrier)[0]
                ?.carrierShortName;
              if (!brokerIdConfigs || (brokerIdConfigs && brokerIdConfigs[item.carrier])) {
                if (
                  item?.hasAppetite === APPETITE_TYPE.IN_APPETITE ||
                  item?.hasAppetite === APPETITE_TYPE.MAYBE_IN_APPETITE
                ) {
                  if (clonedApplication?.data?.applicationDetails?.carriersSelected?.includes(item.carrier)) {
                    inAppetiteSelectedCarriers.push(item.carrier);
                  }
                  inAppetiteCarriers.push({ label: carrierShortName, value: item.carrier });
                } else {
                  if (clonedApplication?.data?.applicationDetails?.carriersSelected?.includes(item.carrier)) {
                    notInAppetiteSelectedCarriers.push(item.carrier);
                  }
                  notInAppetiteCarriers.push({ label: carrierShortName, value: item.carrier });
                }
              } else {
                noBrokerCodeCarriers.push({ label: carrierShortName, value: item.carrier });
              }
            });
          }

          if (inAppetiteSelectedCarriers.length > 0) {
            updateAppetiteCarrierListContainerByKey(
              ACCORD_CARRIER_LIST_STORE_KEYS.IN_APPETITE_CHECKBOX_GROUP,
              inAppetiteSelectedCarriers,
            );
          }
          if (notInAppetiteSelectedCarriers.length > 0) {
            updateAppetiteCarrierListContainerByKey(
              ACCORD_CARRIER_LIST_STORE_KEYS.NOT_IN_APPETITE_CHECKBOX_GROUP,
              notInAppetiteSelectedCarriers,
            );
          }

          set(
            {
              appetiteDetailsStore: {
                appetiteData: response,
                appetiteLoading: false,
                appetiteError: null,
                inAppetiteCarriers,
                notInAppetiteCarriers,
                noBrokerCodeCarriers,
              },
            },
            false,
            ACTION_TYPE.SET_APPETITE,
          );
        } catch (error: any) {
          set(
            {
              appetiteDetailsStore: {
                appetiteData: [],
                appetiteLoading: false,
                appetiteError: error,
                inAppetiteCarriers: [],
                notInAppetiteCarriers: [],
                noBrokerCodeCarriers: [],
              },
            },
            false,
            ACTION_TYPE.SET_SET_APPETITE_ERROR,
          );
        }
      },

      updateAppetiteCarrierListContainerByKey: async (storeKey, value) =>
        set(() => ({ [storeKey]: value }), false, ACTION_TYPE.UPDATE_APPETITE_CARRIER_LIST_CONTAINER_STORE_BY_KEY),

      clearAppetiteCarrierListContainerStore: () => {
        set(
          {
            inAppetiteCheckboxGroup: [],
            notInAppetiteCheckboxGroup: [],
            selectedCarriers: [],
            appetiteDetailsStore: { ...initAppetiteDetailsStore },
          },
          false,
          ACTION_TYPE.CLEAR_APPETITE_CARRIER_LIST_CONTAINER_STORE,
        );
      },
    }),

    {
      anonymousActionType: ACTION_TYPE.ANONYMOUS_ACTION_NAME,
      name: ACTION_TYPE.STORE_NAME,
    },
  ),
);
