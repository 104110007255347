import styled from 'styled-components/macro';

export const SubInfoWrapperStyled = styled.div`
  display: flex;
  margin: -20px 0px 8px 0px;
  justify-content: flex-end;
`;

export const IndustryCodeLinkWrapperStyled = styled.div`
  margin-left: 34%;
`;

export const SubInfoStyled = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: ${(p) => p?.theme?.colorScheme?.primary};
  :hover {
    color: ${(p) => p.theme?.colorScheme?.primaryHover};
  }
`;
