import { DependencyType } from '@coverforce-platform/cf-common-types';

import { DROPDOWN_MODE } from '../../ui-core/V2/cfSelect/constants';
import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { IApplicationQuestion } from '../applicationQuestion/interface';

export const UNDERWRITING_ACCORD_SECTION_KEYS = {
  IS_ADD_UNDERWRITING_SUPPLEMENT: 'isAddUnderwritingSupplement',
  ATTACH_TO: 'attachTo',
};

export const ATTACH_UWQ_VALUE = {
  ALL: 'ALL',
  NONE: 'NONE',
};

export const UNDERWRITING_ACCORD_SECTION_NOTE = {
  ALL: 'All: The underwriting questions supplement will be attached to all the ACORD forms selected.',
  NONE: 'None: The underwriting questions supplement will be generated as a separate file.',
};

export const RADIO_BUTTON_VALUES = {
  TRUE: 'true',
  FALSE: 'false',
};

export const UNDERWRITING_ACCORD_QUESTIONS: IApplicationQuestion[] = [
  {
    type: ANTD_QUESTION_TYPE.RADIO,
    name: 'Include underwriting questions supplemental form?',
    dataIndex: UNDERWRITING_ACCORD_SECTION_KEYS.IS_ADD_UNDERWRITING_SUPPLEMENT,
    rules: [{ required: true, message: `You must select an option.` }],
    options: [
      { label: 'Yes', value: RADIO_BUTTON_VALUES.TRUE },
      { label: 'No', value: RADIO_BUTTON_VALUES.FALSE },
    ],
    dependentQuestions: [
      {
        dependencyType: DependencyType.CONDITIONAL,
        condition: RADIO_BUTTON_VALUES.TRUE,
        childQuestionIds: [UNDERWRITING_ACCORD_SECTION_KEYS.ATTACH_TO],
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'Attach UWQs supplement to:',
    dataIndex: UNDERWRITING_ACCORD_SECTION_KEYS.ATTACH_TO,
    placeholder: 'Please Select',
    options: [
      { label: 'All', value: ATTACH_UWQ_VALUE.ALL },
      { label: 'Separate', value: ATTACH_UWQ_VALUE.NONE },
    ],
    rules: [{ required: true, message: `Please select valid option(s).` }],
    mode: DROPDOWN_MODE.MULTIPLE,
    hasParent: true,
    tooltip:
      'Choose whether you want the underwriting questions supplement to be attached to specific forms or generated as a separate file',
  },
];
