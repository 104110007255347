import { convertBase64ToBlob } from './convertBase64ToBlob';

export const getDownloadableObject = (base64data: string, fileName: string) => {
  const blob = convertBase64ToBlob(base64data);

  return {
    url: window.URL.createObjectURL(blob),
    name: fileName,
  };
};
