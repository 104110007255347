import styled from 'styled-components/macro';

export const HiscoxPaymentIframeStyled = styled.iframe`
  width: 100%;
  height: 380px;
  border: none;
`;

export const PaymentIframeInfoWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 20px 15px;
  margin-top: 20px;
`;

export const PaymentIframeInfoTextStyled = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
  color: ${(p) => p?.theme?.colors?.grayscale?.black};
`;
