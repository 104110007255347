import { IFile } from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  deleteFile as deleteApplicationFile,
  downloadFile as downloadApplicationDocument,
  searchFile as getApplicationFiles,
  uploadFile as uploadApplicationDocument,
} from '../../../externalServices/V2/fileService';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { useProfileV2Store } from '../../../pages/v2/profile/store';
import { errorV2 } from '../../../ui-core/Notification';
import { DOCUMENT_CENTER_MESSAGES, DOCUMENT_CENTER_STORE_META_DATA } from './constants';
import { IApplicationDocumentCenter } from './interface';

export const useApplicationDocumentCenterStore = create<IApplicationDocumentCenter>()(
  devtools(
    (set) => ({
      viewDocumentsLoading: false,
      uploadDocumentLoading: false,
      fetchDocumentsError: null,
      applicationDocumentsList: [],
      defaultFileType: undefined,
      defaultFilter: {
        fileType: null,
      },

      updateDocumentCenterByKey: async (storeKey: keyof IApplicationDocumentCenter, value: any) =>
        set(() => ({ [storeKey]: value }), false, DOCUMENT_CENTER_STORE_META_DATA.UPDATE_DOCUMENT_CENTER_STORE_BY_KEY),

      fetchDocuments: async (loading = true) => {
        const { applicationData } = useApplicationStore.getState();
        const { applicationId = '' } = applicationData || {};
        set({ viewDocumentsLoading: loading }, false, DOCUMENT_CENTER_STORE_META_DATA.VIEW_DOCUMENTS_LOADING);
        if (applicationId) {
          try {
            const response = await getApplicationFiles({ applicationId });
            set({ applicationDocumentsList: response?.files, fetchDocumentsError: null });
          } catch (error: any) {
            set({ applicationDocumentsList: [], fetchDocumentsError: error });
            errorV2(error?.[0]?.errorMessage || DOCUMENT_CENTER_MESSAGES.ERROR_FETCH_DOCUMENTS);
          }
        }
        set({ viewDocumentsLoading: false }, false, DOCUMENT_CENTER_STORE_META_DATA.VIEW_DOCUMENTS_LOADING);
      },

      deleteDocument: async ({ fileId }) => {
        const { applicationData } = useApplicationStore.getState();
        const { applicationId } = applicationData || {};
        await deleteApplicationFile({ applicationId: applicationId!, fileId });
      },

      downloadDocument: async ({ fileId }) => {
        const { applicationData } = useApplicationStore.getState();
        const { applicationId } = applicationData || {};
        const response = await downloadApplicationDocument({ applicationId: applicationId!, fileId });
        return response?.presignedDownloadURL;
      },

      uploadDocument: async ({ file, fileType, fileName, fileDescription }) => {
        const { applicationData } = useApplicationStore.getState();
        const { applicationId } = applicationData || {};
        const { userData } = useProfileV2Store.getState();

        const fileClone: IFile = {
          base64EncodedFile: file,
          fileName,
        };
        await uploadApplicationDocument({
          applicationId: applicationId!,
          file: fileClone,
          fileType,
          label: fileDescription,
          uploadedBy: userData?.agentId || '',
        });
      },

      clearDocumentCenterStore: () => {
        set(
          {
            viewDocumentsLoading: false,
            uploadDocumentLoading: false,
            fetchDocumentsError: null,
            applicationDocumentsList: [],
            defaultFileType: undefined,
            defaultFilter: {
              fileType: null,
            },
          },
          false,
          DOCUMENT_CENTER_STORE_META_DATA.CLEAR_DOCUMENT_CENTER_STORE,
        );
      },
    }),
    {
      name: DOCUMENT_CENTER_STORE_META_DATA.STORE_NAME,
      anonymousActionType: DOCUMENT_CENTER_STORE_META_DATA.ANONYMOUS_ACTION_NAME,
    },
  ),
);
