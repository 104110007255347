import { ICarrierJobDescriptionDetail, IEmployeeInfo, ILocationInfo } from '@coverforce-platform/cf-common-api-model';
import { USState } from '@coverforce-platform/cf-common-types';
import { get as _get } from 'lodash';

import { getInternalClassCodeByCarrier } from '../../externalServices/V2/location';
import { FALSY_VALUES } from '../../globalConstants';
import { deNormalizeMoneyInput } from '../../helpers/Utils';
import { useApplicationStore } from '../../pages/v2/application/store';
import { getSelectedCarriersFromApplication } from '../../pages/v2/application/utils';
import { errorV2 } from '../../ui-core/Notification';
import { getNcciStateClassCodeByExtendedId } from '../../utils/getNcciStateClassCodeByExtendedId';
import { cloneDeep } from '../../utils/lodash';
import { useLocationDetailsStore } from '../locationDetails/store';
import { IAddressData } from '../question/locationInputV2/interface';
import {
  CARRIERS_CLASS_CODE_CONFIG,
  EMPLOYEE_INFO_ERROR_MESSAGES,
  ENABLED_CARRIERS_FOR_CLASS_CODE,
  FIELD_KEYS,
  ZERO_VALUES,
} from './constants';
import { ACTION, IActiveKeys } from './interface';
import { useEmployeeInformationStore } from './store';

const getEmployeeInformationArray = (
  locations: ILocationInfo[],
  selectedLocationIndex: number,
): IEmployeeInfo[] | undefined => {
  return !!locations && locations?.length > 0 && locations[selectedLocationIndex]
    ? locations[selectedLocationIndex]?.employeeInformation
    : undefined;
};

export const getFormLocation = (addressData: IAddressData, selectedLocationIndex: number) => {
  return addressData[`location_${selectedLocationIndex}`]?.address;
};

export const setCarrierNCCIClassCode = async (jobCodeExtendedId: string | undefined, state: string) => {
  const { currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
  const { locationDetailsForm: form } = useLocationDetailsStore.getState();
  const {
    setCarrierInternalClassCodes,
    setCarriersInternalClassCodesLoading,
    selectedEmployeeTypeIndex,
    carriersValidForInternalClassCodes,
    classCodes,
  } = useEmployeeInformationStore.getState();

  setCarriersInternalClassCodesLoading(true);

  for (const carrier of carriersValidForInternalClassCodes) {
    const carrierConfig = CARRIERS_CLASS_CODE_CONFIG[`${carrier}`];
    if (jobCodeExtendedId?.length && carrierConfig) {
      try {
        const classCode = getNcciStateClassCodeByExtendedId(classCodes, jobCodeExtendedId);
        const internalClassCodesResponse = await getInternalClassCodeByCarrier({
          carrier: carrierConfig?.carrier,
          state: state as USState,
          classCode: classCode || '',
        });

        if (internalClassCodesResponse?.length > 0) {
          setCarrierInternalClassCodes(selectedEmployeeTypeIndex, internalClassCodesResponse, carrier);
        } else {
          setCarrierInternalClassCodes(selectedEmployeeTypeIndex, [], carrier);
        }
      } catch (error: any) {
        errorV2(error?.[0]?.errorMessage);
        form?.setFields([
          {
            name: [
              selectedLocationIndex,
              FIELD_KEYS.employeeInformation,
              selectedEmployeeTypeIndex,
              `${carrierConfig?.carrier}`,
            ],
            value: undefined,
          },
        ]);
        setCarrierInternalClassCodes(selectedEmployeeTypeIndex, [], carrier);
      }
    }
  }
  setCarriersInternalClassCodesLoading(false);
};

export const autoPopulateEmployeesTypeAndHandleError = ({
  employeeTypeIndex,
  value,
  errorKey,
  autoPopulateValueKey,
}: {
  employeeTypeIndex: number;
  value: any;
  errorKey: string;
  autoPopulateValueKey: string;
}) => {
  const { currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
  const { locationDetailsForm: form } = useLocationDetailsStore.getState();
  const { updateLocationEmployee } = useEmployeeInformationStore.getState();

  // Clearing an error
  const numOfFullTimeEmployees =
    Number(
      form?.getFieldValue([
        selectedLocationIndex,
        FIELD_KEYS.employeeInformation,
        employeeTypeIndex,
        FIELD_KEYS.numFullTimeEmployees,
      ]),
    ) || 0;
  const numOfPartTimeEmployees =
    Number(
      form?.getFieldValue([
        selectedLocationIndex,
        FIELD_KEYS.employeeInformation,
        employeeTypeIndex,
        FIELD_KEYS.numPartTimeEmployees,
      ]),
    ) || 0;

  if (numOfFullTimeEmployees + numOfPartTimeEmployees > 0) {
    form?.setFields([
      {
        name: [selectedLocationIndex, FIELD_KEYS.employeeInformation, employeeTypeIndex, errorKey],
        errors: [],
      },
    ]);
  }

  // auto populating fields
  if (Number(value) === 0) {
    updateLocationEmployee({
      locationIndex: selectedLocationIndex,
      employeeTypeIndex,
      fieldName: autoPopulateValueKey,
      fieldValue: '0',
    });
    form?.setFields([
      {
        name: [selectedLocationIndex, FIELD_KEYS.employeeInformation, employeeTypeIndex, autoPopulateValueKey],
        errors: [],
        value: '0',
      },
    ]);
  } else if (Number(value) > 0) {
    form?.setFields([
      {
        name: [selectedLocationIndex, FIELD_KEYS.employeeInformation, employeeTypeIndex, autoPopulateValueKey],
        errors: [],
      },
    ]);
  }
};

const validatorForNumOfEmployers = (name: any, value: any) => {
  const { locationDetailsForm: form } = useLocationDetailsStore.getState();
  // Form list stores the name path as '0.employeeInformation.0.numPartTimeEmployees'.
  const numPartTimeEmployees =
    form?.getFieldValue([...name?.fullField?.split('.')?.slice(0, 3), FIELD_KEYS.numPartTimeEmployees]) || 0;
  const numFullTimeEmployees =
    form?.getFieldValue([...name?.fullField?.split('.')?.slice(0, 3), FIELD_KEYS.numFullTimeEmployees]) || 0;

  if (value !== null && numFullTimeEmployees + numPartTimeEmployees <= 0) {
    return Promise.reject(
      'You should have at least one employee. If this is a shell class, enter 1 full-time employee with $1 payroll.',
    );
  }

  return Promise.resolve();
};

export const getEmployeeInfoFormItemConfig = (selectedLocationIndex: number, employeeTypeIndex: number) => {
  return {
    ncciStateClassCode: {
      id: `${selectedLocationIndex}_formItem_employeeInformation_${employeeTypeIndex}_${FIELD_KEYS.jobCodeExtendedId}`,
      name: [employeeTypeIndex, FIELD_KEYS.jobCodeExtendedId],
      label: 'State Workers Comp Class Code',
      rules: [
        {
          required: true,
          message: 'Please select employee type.',
        },
      ],
    },

    numFullTimeEmployees: {
      id: `${selectedLocationIndex}_formItem_${FIELD_KEYS.numFullTimeEmployees}_${employeeTypeIndex}`,
      name: [employeeTypeIndex, FIELD_KEYS.numFullTimeEmployees],
      label: 'Number of Full Time Employees',
      rules: [
        {
          required: true,
          validator(name: any, value: any) {
            if (FALSY_VALUES.includes(value)) {
              return Promise.reject('Please enter full time employees.');
            }
            return validatorForNumOfEmployers(name, value);
          },
        },
      ],
    },

    numPartTimeEmployees: {
      id: `${selectedLocationIndex}_formItem_${FIELD_KEYS.numPartTimeEmployees}_${employeeTypeIndex}`,
      name: [employeeTypeIndex, FIELD_KEYS.numPartTimeEmployees],
      label: 'Number of Part Time Employees',
      rules: [
        {
          required: true,
          validator(name: any, value: any) {
            if (FALSY_VALUES.includes(value)) {
              return Promise.reject('Please enter part time employees.');
            }
            return validatorForNumOfEmployers(name, value);
          },
        },
      ],
    },

    fullTimeEmployeesPayroll: {
      id: `${selectedLocationIndex}_formItem_${FIELD_KEYS.fullTimeEmployeesPayroll}_${employeeTypeIndex}`,
      name: [employeeTypeIndex, FIELD_KEYS.fullTimeEmployeesPayroll],
      label: 'Full Time Employees Payroll',
      rules: [
        {
          required: true,
          validator(name: any, value: any) {
            if (FALSY_VALUES.includes(value)) {
              return Promise.reject('Please enter full time payroll.');
            }
            return Promise.resolve();
          },
        },
        ({ getFieldValue }: { getFieldValue: Function }) => ({
          validator(name: any, value: any) {
            // Here falsy value resolve because it is handled by required rule, which is first
            if (FALSY_VALUES.includes(value)) {
              return Promise.resolve();
            }

            const numFullTimeEmployees =
              Number(getFieldValue([...name?.fullField?.split('.').slice(0, 3), FIELD_KEYS.numFullTimeEmployees])) || 0;
            if (value !== undefined && numFullTimeEmployees === 0 && !ZERO_VALUES.includes(value?.toString()?.trim())) {
              return Promise.reject('Full Time Employees Payroll must be zero');
            }
            if (value !== undefined && numFullTimeEmployees !== 0 && ZERO_VALUES.includes(value?.toString()?.trim())) {
              return Promise.reject('Full Time Employees Payroll must be greater than zero');
            }
            return Promise.resolve();
          },
        }),
      ],
    },

    partTimeEmployeesPayroll: {
      id: `${selectedLocationIndex}_formItem_${FIELD_KEYS.partTimeEmployeesPayroll}_${employeeTypeIndex}`,
      name: [employeeTypeIndex, FIELD_KEYS.partTimeEmployeesPayroll],
      label: 'Part Time Employees Payroll',
      rules: [
        {
          required: true,
          validator(name: any, value: any) {
            if (FALSY_VALUES.includes(value)) {
              return Promise.reject('Please enter part time payroll.');
            }
            return Promise.resolve();
          },
        },
        ({ getFieldValue }: { getFieldValue: Function }) => ({
          validator(name: any, value: any) {
            // Here falsy value resolve because it is handled by required rule, which is first
            if (FALSY_VALUES.includes(value)) {
              return Promise.resolve();
            }

            const numPartTimeEmployees =
              Number(getFieldValue([...name?.fullField?.split('.').slice(0, 3), FIELD_KEYS.numPartTimeEmployees])) || 0;
            if (value !== undefined && numPartTimeEmployees === 0 && !ZERO_VALUES.includes(value?.toString()?.trim())) {
              return Promise.reject('Part Time Employees Payroll must be zero');
            }
            if (value !== undefined && numPartTimeEmployees !== 0 && ZERO_VALUES.includes(value?.toString()?.trim())) {
              return Promise.reject('Part Time Employees Payroll must be greater than zero');
            }
            return Promise.resolve();
          },
        }),
      ],
    },
  };
};

export const handleAutoFillCarrierNCCIClassCode = async () => {
  const {
    setCarrierInternalClassCodes,
    setCarriersInternalClassCodesLoading,
    carriersValidForInternalClassCodes,
    classCodes,
  } = useEmployeeInformationStore.getState();
  const { currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
  const { locations, locationDetailsForm } = useLocationDetailsStore.getState();
  const employeeInfoArray = getEmployeeInformationArray(locations, selectedLocationIndex);
  setCarriersInternalClassCodesLoading(true);
  for (const carrier of carriersValidForInternalClassCodes) {
    const carrierConfig = CARRIERS_CLASS_CODE_CONFIG[`${carrier}`];
    try {
      if (employeeInfoArray) {
        for (const [employeeIndex, employeeInfo] of employeeInfoArray.entries()) {
          if (employeeInfo?.jobCodeExtendedId && carrierConfig) {
            const internalClassCodesResponse = await getInternalClassCodeByCarrier({
              carrier: carrierConfig?.carrier,
              state: locations[selectedLocationIndex]?.address?.state.toUpperCase() as USState,
              classCode: getNcciStateClassCodeByExtendedId(classCodes, employeeInfo?.jobCodeExtendedId) || '',
            });

            const jobDescriptionId =
              (employeeInfo?.carrierJobDescriptionDetails || []).find((item) => item.carrier === carrierConfig?.carrier)
                ?.jobDescriptionId || '';

            // the second check is just to make sure that this data is just having the key for the carrier internal class code.
            if (jobDescriptionId && !jobDescriptionId?.includes('-')) {
              const internalClassCodeDisplayValue = internalClassCodesResponse?.find(
                (classCode) => classCode?.value?.split('-')[0]?.trim() === jobDescriptionId,
              );

              locationDetailsForm?.setFields([
                {
                  name: [
                    selectedLocationIndex,
                    FIELD_KEYS.employeeInformation,
                    employeeIndex,
                    `${carrierConfig?.carrier}`,
                  ],
                  value: internalClassCodeDisplayValue?.value,
                },
              ]);
            }
            setCarrierInternalClassCodes(employeeIndex, internalClassCodesResponse, carrier);
          }
        }
      } else {
        const employeeInfo = _get(
          locationDetailsForm?.getFieldsValue(),
          [selectedLocationIndex, FIELD_KEYS.employeeInformation],
          [],
        );

        employeeInfo?.forEach((employeeIndex: number) => {
          locationDetailsForm?.setFields([
            {
              name: [selectedLocationIndex, FIELD_KEYS.employeeInformation, employeeIndex, `${carrierConfig?.carrier}`],
              value: undefined,
            },
          ]);
          setCarrierInternalClassCodes(employeeIndex, [], carrier);
        });
      }
    } catch (error: any) {
      errorV2(error?.[0]?.errorMessage || EMPLOYEE_INFO_ERROR_MESSAGES.FAILED_TO_FETCH_STATE_CARRIER_CLASS_CODES);
      const employeeInfo = _get(
        locationDetailsForm?.getFieldsValue(),
        [selectedLocationIndex, FIELD_KEYS.employeeInformation],
        [],
      );

      employeeInfo?.forEach((employeeIndex: number) => {
        locationDetailsForm?.setFields([
          {
            name: [selectedLocationIndex, FIELD_KEYS.employeeInformation, employeeIndex, `${carrierConfig?.carrier}`],
            value: undefined,
          },
        ]);
        setCarrierInternalClassCodes(employeeIndex, [], carrier);
      });
    }
  }
  setCarriersInternalClassCodesLoading(false);
};

export const handleAutoFillNCCIClassAndEmployeeTypeDetails = async (locations: ILocationInfo[]) => {
  const { setClassCodes, fetchClassCodes } = useEmployeeInformationStore.getState();
  const { locationDetailsForm: form } = useLocationDetailsStore.getState();
  const { currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
  const employeeInfoArray = getEmployeeInformationArray(locations, selectedLocationIndex);
  const selectedLocationState = locations?.[selectedLocationIndex]?.address?.state;

  try {
    await fetchClassCodes(selectedLocationState as USState);
    if (employeeInfoArray) {
      employeeInfoArray?.forEach((item: IEmployeeInfo, employeeTypeIndex: number) => {
        const jobCodeExtendedId = _get(item, [FIELD_KEYS.jobCodeExtendedId])?.trim() || '';

        form?.setFields([
          {
            name: [
              selectedLocationIndex,
              FIELD_KEYS.employeeInformation,
              employeeTypeIndex,
              FIELD_KEYS.jobCodeExtendedId,
            ],
            value: jobCodeExtendedId,
          },
        ]);

        form?.setFieldValue(
          [selectedLocationIndex, FIELD_KEYS.employeeInformation, employeeTypeIndex, FIELD_KEYS.numFullTimeEmployees],
          item?.numFullTimeEmployees,
        );
        form?.setFieldValue(
          [selectedLocationIndex, FIELD_KEYS.employeeInformation, employeeTypeIndex, FIELD_KEYS.numPartTimeEmployees],
          item?.numPartTimeEmployees,
        );
        form?.setFieldValue(
          [
            selectedLocationIndex,
            FIELD_KEYS.employeeInformation,
            employeeTypeIndex,
            FIELD_KEYS.fullTimeEmployeesPayroll,
          ],
          item?.fullTimeEmployeesPayroll,
        );
        form?.setFieldValue(
          [
            selectedLocationIndex,
            FIELD_KEYS.employeeInformation,
            employeeTypeIndex,
            FIELD_KEYS.partTimeEmployeesPayroll,
          ],
          item?.partTimeEmployeesPayroll,
        );
      });
    }
  } catch (error: any) {
    errorV2(error?.[0]?.errorMessage || EMPLOYEE_INFO_ERROR_MESSAGES.FAILED_GET_EMPLOYEE_TYPE_DETAILS);
    employeeInfoArray?.forEach((item: IEmployeeInfo, employeeTypeIndex: number) => {
      form?.setFields([
        {
          name: [
            selectedLocationIndex,
            FIELD_KEYS.employeeInformation,
            employeeTypeIndex,
            FIELD_KEYS.jobCodeExtendedId,
          ],
          value: undefined,
        },
      ]);
    });
    setClassCodes([]);
  }
};

export const getEmployeeInformationApiPayloadUtil = (employeeInfoPayload: any) => {
  const { applicationData, currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
  const { carriersInternalClassCodes, classCodes } = useEmployeeInformationStore.getState();
  const applicationCarriersSelected = getSelectedCarriersFromApplication(applicationData);

  return {
    employeeInformation: employeeInfoPayload?.employeeInformation?.map((item: IEmployeeInfo, index: number) => {
      const existingCarrierJobDescriptionDetails =
        applicationData?.locationDetails?.[selectedLocationIndex]?.employeeInformation?.[index]
          ?.carrierJobDescriptionDetails;
      let carrierJobDescriptionDetails: ICarrierJobDescriptionDetail[] =
        existingCarrierJobDescriptionDetails && existingCarrierJobDescriptionDetails?.length > 0
          ? cloneDeep(existingCarrierJobDescriptionDetails)
          : [];

      const employeeInfo = {
        ...item,
        numFullTimeEmployees: Number(item?.numFullTimeEmployees),
        numPartTimeEmployees: Number(item?.numPartTimeEmployees),
        partTimeEmployeesPayroll: Number(deNormalizeMoneyInput(String(item?.partTimeEmployeesPayroll))),
        fullTimeEmployeesPayroll: Number(deNormalizeMoneyInput(String(item?.fullTimeEmployeesPayroll))),
        ncciStateClassCode: getNcciStateClassCodeByExtendedId(classCodes, item?.jobCodeExtendedId || ''),
        jobCodeExtendedId: item?.jobCodeExtendedId,
      };

      ENABLED_CARRIERS_FOR_CLASS_CODE.forEach((carrier) => {
        const carrierInternalClassCodes = carriersInternalClassCodes[index]?.[carrier];
        if (applicationCarriersSelected.includes(carrier) && carrierInternalClassCodes?.length === 0) {
          carrierJobDescriptionDetails = carrierJobDescriptionDetails.filter(
            (carrierItem) => carrierItem.carrier !== carrier,
          );
        }
        if (applicationCarriersSelected.includes(carrier) && carrierInternalClassCodes?.length > 0) {
          const existingCarrierCode = carrierJobDescriptionDetails.find(
            (carrierItem) => carrierItem.carrier === carrier,
          );

          if (existingCarrierCode) {
            const existingItemIndex = carrierJobDescriptionDetails.indexOf(existingCarrierCode);
            carrierJobDescriptionDetails[existingItemIndex].jobDescriptionId = (
              (employeeInfo[`${carrier}` as keyof IEmployeeInfo] as string) || ''
            )
              ?.split('-')?.[0]
              ?.trim();
          } else {
            carrierJobDescriptionDetails.push({
              carrier,
              jobDescriptionId: ((employeeInfo[`${carrier}` as keyof IEmployeeInfo] as string) || '')
                ?.split('-')?.[0]
                ?.trim(),
            });
          }
        }
        delete employeeInfo?.[`${carrier}` as keyof IEmployeeInfo];
      });

      employeeInfo.carrierJobDescriptionDetails = carrierJobDescriptionDetails;

      return employeeInfo;
    }),
  };
};

export const getActiveKeys = ({ action, data }: { action: ACTION; data: any }): IActiveKeys => {
  const { activeKeys } = useEmployeeInformationStore.getState();
  const deepCloneActiveKeys = cloneDeep(activeKeys);

  switch (action) {
    case ACTION.ADD: {
      const objectKeys = Object.keys(deepCloneActiveKeys.keys);
      objectKeys.forEach((key) => (deepCloneActiveKeys.keys[Number(key)] = []));

      return {
        keys: { ...deepCloneActiveKeys.keys, [activeKeys.nextFieldKey]: [activeKeys.nextFieldKey.toString()] },
        nextFieldKey: activeKeys.nextFieldKey + 1,
      };
    }
    case ACTION.DELETE: {
      delete deepCloneActiveKeys.keys[data?.field?.key];
      return deepCloneActiveKeys;
    }

    default:
      return deepCloneActiveKeys;
  }
};

export const handleAccordionError = (errors: any) => {
  const { activeKeys, handleActiveKeys } = useEmployeeInformationStore.getState();
  const deepCloneActiveKeys = cloneDeep(activeKeys);
  const accordionErrorKeys: number[] = [];
  errors?.errorFields?.forEach((error: any) => {
    if (!accordionErrorKeys.includes(error?.name?.[2])) {
      accordionErrorKeys.push(error?.name?.[2]);
    }
  });

  Object.keys(activeKeys.keys)?.forEach((key, index) => {
    if (accordionErrorKeys.includes(index)) {
      deepCloneActiveKeys.keys[Number(key)] = [key.toString()];
    }
  });

  handleActiveKeys(deepCloneActiveKeys);
};
