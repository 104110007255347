import { IDownloadAcordForApplicationRequestInput } from '@coverforce-platform/cf-common-api-model';
import { IAcordDownloadTransactionId } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { downloadAcord, requestAcord } from '../../../externalServices/V2/acord';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { DOWNLOAD_ACORD_METADATA } from './constants';
import { IDownloadAcordStore } from './interface';

export const useDownloadAcordStore = create<IDownloadAcordStore>()(
  devtools(
    (set) => ({
      downloadAcordForm: undefined,
      isDownloadingAcord: false,
      selectedAcordForms: [],

      requestAcord: async (payload: IDownloadAcordForApplicationRequestInput) => {
        const response = await requestAcord(payload);
        return response;
      },

      downloadAcord: async (acordDownloadTransactionId: IAcordDownloadTransactionId) => {
        const { applicationData } = useApplicationStore.getState();

        const response = await downloadAcord({
          applicationId: applicationData?.applicationId ?? '',
          acordDownloadTransactionId,
        });
        return response;
      },

      updateDownloadAcordStoreByKey: async (storeKey: keyof IDownloadAcordStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, DOWNLOAD_ACORD_METADATA.UPDATE_DOWNLOAD_ACORD_STORE_BY_KEY),

      clearDownloadAccordStore: () => {
        set(
          { downloadAcordForm: undefined, isDownloadingAcord: false, selectedAcordForms: [] },
          false,
          DOWNLOAD_ACORD_METADATA.CLEAR_DOWNLOAD_ACORD_STORE,
        );
      },
    }),

    { anonymousActionType: DOWNLOAD_ACORD_METADATA.ANONYMOUS_ACTION, name: DOWNLOAD_ACORD_METADATA.STORE_NAME },
  ),
);
