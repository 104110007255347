import createInstance from '../common/createInstance';
import { defaultRequestInterceptor, defaultResponseInterceptor } from '../common/interceptor';
import methods from '../common/methods';
import { STATIC_FILES_SERVICE, STATIC_FILES_SERVICE_PARAM } from './constants';

const axiosStaticFileService = createInstance(STATIC_FILES_SERVICE_PARAM);

defaultRequestInterceptor({ axiosInstance: axiosStaticFileService, serviceName: STATIC_FILES_SERVICE });
defaultResponseInterceptor({ axiosInstance: axiosStaticFileService, serviceName: STATIC_FILES_SERVICE });

export default methods(axiosStaticFileService);
