import { CheckCircleOutlined, SettingOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';

import Anchor from '../../ui-core/V2/anchor/anchor';

export const NotificationHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NotificationTitleLinkStyled = styled(Anchor)`
  display: flex;
  align-items: center;
  text-decoration: underline;

  :hover {
    text-decoration: underline;
  }
`;

export const NotificationTitleIconStyled = styled(SettingOutlined)`
  margin-left: 4px;
`;

export const MarkAsReadWrapperStyled = styled.div
  .attrs((props: any) => props)
  .withConfig({
    shouldForwardProp: (props: any) => !['unreadCount'].includes(props),
  })`
  display: flex;
  align-items: center;
  text-decoration: underline;

  cursor: ${(p) => (p.unreadCount > 0 ? 'pointer' : '')};
  color: ${(p) => (p.unreadCount > 0 ? p?.theme?.colorScheme?.primary : p.theme.colors.grayscale.darkGray)};
  :hover {
    color: ${(p) => (p.unreadCount > 0 ? p.theme?.colorScheme?.primaryHover : p.theme.colors.grayscale.darkGray)};
  }
`;

export const MarkAsReadTextStyled = styled.div`
  padding: 0px 4px;
`;

export const MarkAsReadIconStyled = styled(CheckCircleOutlined)``;
