import styled from 'styled-components/macro';

import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';

export const ApplicationQuestionFormItemStyled = styled(CFFormItem)`
  width: 100%;
  .ant-form-item-row {
    display: flex;
  }
  .ant-form-item-label {
    overflow: inherit;
    display: flex;
    font-weight: ${(p) => p.theme.typography.fontWeight.medium};
    text-align: left;
    align-items: flex-start;
  }
  label {
    white-space: pre-line;
    height: auto;
  }
  margin: 0;
`;

export const TooltipStyled = styled.span`
  a {
    color: ${(p) => p.theme.colorScheme?.primary};
    &:hover {
      color: ${(p) => p.theme.colorScheme?.primaryHover};
    }
  }
`;
