import { PopupButton } from 'react-calendly';
import styled from 'styled-components/macro';

export const PopupButtonStyled = styled(PopupButton)`
  border: none;
  background: none;
  cursor: pointer;
  font-size: ${(p) => p?.theme?.typography?.fontSize?.medium};
  color: rgb(0, 0, 0, 0.88);
  padding-left: 0;
`;
