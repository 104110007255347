import { CalendarOutlined, CommentOutlined, HomeOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';

import { helpCenterLink } from '../../../globalConstants';
import Anchor from '../../../ui-core/V2/anchor/anchor';
import { ANCHOR_TARGET } from '../../../ui-core/V2/anchor/constants';
import { CFMenuItem } from '../../../ui-core/V2/cfMenu/cfMenu';
import { useConfigurationStore } from '../../configuration/store';
import { CFNavMenuStyled } from '../../navBarV2/styles';
import { IHelpMenuProps } from './interface';
import { PopupButtonStyled } from './styles';

const HelpMenu = ({ onClickContactUs }: IHelpMenuProps) => {
  const theme: any = useTheme();
  const { userTheme } = useConfigurationStore();

  return (
    <CFNavMenuStyled id='nav_menu_help' backgroundColor={theme.colors.grayscale.white}>
      <CFMenuItem key='help_center' id='help_center' icon={<HomeOutlined />}>
        <Anchor id='anchor_help_center' href={helpCenterLink} target={ANCHOR_TARGET.BLANK}>
          Help Center
        </Anchor>
      </CFMenuItem>
      <CFMenuItem key='submit_request' id='submit_request' icon={<CommentOutlined />} onClick={onClickContactUs}>
        Submit a request
      </CFMenuItem>
      {userTheme?.links?.demo && (
        <>
          <CFMenuItem key='schedule_demo' id='schedule_demo' icon={<CalendarOutlined />}>
            <PopupButtonStyled
              url={userTheme?.links?.demo}
              rootElement={document.getElementById('root')!}
              text='Schedule Demo'
            />
          </CFMenuItem>
        </>
      )}
    </CFNavMenuStyled>
  );
};

export default HelpMenu;
