import { Tag } from 'antd';
import styled from 'styled-components/macro';

export const TagStyled = styled(Tag)`
  display: flex;
  white-space: normal;
  padding: 14px;
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  color: ${(p) => p.theme.colors.grayscale.black};
`;

export const IconWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  min-width: 25px;
`;

export const TextStyled = styled.span``;
