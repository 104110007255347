import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';

import CFForm from '../../ui-core/V2/cfForm/cfForm';
import CFModalV2 from '../../ui-core/V2/cfModal/cfModal';
import CFRow from '../../ui-core/V2/cfRow/cfRow';

export const RequestToBindModalStyled = styled(CFModalV2)`
  width: 600px !important;
`;

export const RequestToBindDetailsWrapper = styled(CFRow)`
  justify-content: space-between;
  margin: 14px 0px;
`;

export const RequestToBindMessageInfoCircleOutlinedStyled = styled(InfoCircleOutlined)`
  font-size: ${(p) => p.theme.typography.fontSize.extendedMedium};
`;

export const RequestToBindFormStyled = styled(CFForm)`
  margin: 18px 8px 28px 0px;
`;
