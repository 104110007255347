import {
  IAppetiteInfo,
  IBrokerCodeInfo,
  ICheckAppetiteInput,
  ICreateApplicationFromAcordInput,
  ICreateApplicationInput,
  ICreateApplicationOutput,
  IGetAccountsInput,
  IGetAccountsOutput,
  IGetAllPolicyTypesOutput,
  IGetAvailableCarriersForAgentInput,
  IGetAvailableCarriersForAgentOutput,
  IGetCarrierRankingInput,
  IGetCarrierRankingOutput,
} from '@coverforce-platform/cf-common-api-model';
import { Carrier } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { INDUSTRY_CODES } from '../../../constants/appetite';
import { getAccounts as requestGetAccounts } from '../../../externalServices/V2/accountsV2';
import { checkAppetite as requestAppetiteDetails } from '../../../externalServices/V2/appetite';
import {
  createApplication as requestCreateApplication,
  createApplicationFromAcord as requestCreateApplicationFromAcord,
  getAllNaicsCodes,
  getAllSicCodes,
} from '../../../externalServices/V2/applicationV2';
import { getBrokerCodesByAgencyId } from '../../../externalServices/V2/brokerConfig';
import {
  getAllPolicyTypes as requestGetPolicyTypes,
  getCarrierRankingByPolicyTypeId as requestGetCarrierRankingByPolicyTypeId,
} from '../../../externalServices/V2/carrierRankingV2';
import { getAvailableCarriers as requestGetAvailableCarriers } from '../../../externalServices/V2/carriersV2';
import { PAGE_ROUTES } from '../../../globalConstants';
import { useCarrierConfigStore } from '../../../pages/v2/carrierConfig/store';
import { error as errorAlert } from '../../../ui-core/Notification';
import { parseIndustryCodesToLabelValue } from '../../../utils/parseIndustryCodesToLabelValue';
import { ACTION_TYPE, APPETITE_TYPE } from './constants';
import { IAppetiteField, INewQuoteStore } from './interface';
import { getSupportedCarriers } from './utils';

// Action name
const {
  SET_NEW_QUOTE_CARRIERS,
  SET_NEW_QUOTE_CARRIERS_ERROR,
  SET_NEW_QUOTE_POLICY_TYPE,
  SET_NEW_QUOTE_POLICY_TYPE_ERROR,
  SET_NEW_QUOTE_SUPPORTED_CARRIERS,
  SET_NEW_QUOTE_SUPPORTED_CARRIERS_ERROR,
  SET_NEW_QUOTE_ACCOUNTS,
  SET_NEW_QUOTE_ACCOUNTS_ERROR,
  CLEAR_NEW_QUOTE_STORE,
  SET_NEW_QUOTE_NAICS_CODE,
  SET_NEW_QUOTE_NAICS_CODE_ERROR,
  SET_NEW_QUOTE_APPETITE,
  SET_NEW_QUOTE_APPETITE_ERROR,
  SET_SUPPORTED_CARRIERS_LOADING,
  SET_INDUSTRY_CODE_TYPE,
  SET_NEW_QUOTE_SIC_CODE,
  SET_NEW_QUOTE_SIC_CODE_ERROR,
} = ACTION_TYPE;

const initCarriersStoreState = {
  carriersData: [],
  carriersLoading: true,
  carriersError: null,
};

const initPolicyTypeStore = {
  policyTypesData: [],
  policyTypesLoading: true,
  policyTypesError: null,
};

const initSupportedCarriersStore = {
  supportedCarriersData: {},
  supportedCarriersLoading: false,
  supportedCarriersError: null,
};

const initAllAccountsStore = {
  allAccountsData: [],
  allAccountsLoading: true,
  allAccountsError: null,
};

const initNaicsCodesStore = {
  naicsCodesData: [],
  naicsCodesLoading: true,
  naicsCodesError: null,
};
const initSicCodesStore = {
  sicCodesData: [],
  sicCodesLoading: true,
  sicCodesError: null,
};

const initAppetiteDetailsStore = {
  appetiteData: [],
  appetiteLoading: false,
  appetiteError: null,
  inAppetiteCarriers: [],
  notInAppetiteCarriers: [],
  noBrokerCodeCarriers: [],
};

const initBrokerConfigData = { brokerIdConfigs: undefined, brokerConfigsLoading: false, brokerConfigsError: undefined };

export const useNewQuoteStore = create<INewQuoteStore>()(
  devtools(
    (set, get) => ({
      carriersStore: {
        ...initCarriersStoreState,
      },
      policyTypeStore: {
        ...initPolicyTypeStore,
      },
      supportedCarriersStore: {
        ...initSupportedCarriersStore,
      },
      allAccountsStore: { ...initAllAccountsStore },
      naicsCodesStore: { ...initNaicsCodesStore },
      sicCodesStore: { ...initSicCodesStore },

      newApplicationId: '',
      createNewApplicationLoading: false,
      presetAccountId: undefined,
      appetiteDetailsStore: { ...initAppetiteDetailsStore },

      brokerConfigDataStore: { ...initBrokerConfigData },

      selectedIndustryCodeType: INDUSTRY_CODES.NAICS.TYPE,

      setSelectedIndustryCodeType: (industryCodeType) => {
        set({ selectedIndustryCodeType: industryCodeType }, false, SET_INDUSTRY_CODE_TYPE);
      },

      fetchAvailableCarriersByAgentId: async (params: IGetAvailableCarriersForAgentInput) => {
        try {
          const response: IGetAvailableCarriersForAgentOutput = await requestGetAvailableCarriers({
            agentId: params?.agentId,
          });
          set(
            {
              carriersStore: { carriersData: response?.availableCarriers, carriersError: null, carriersLoading: false },
            },
            false,
            SET_NEW_QUOTE_CARRIERS,
          );
        } catch (error: any) {
          set(
            { carriersStore: { carriersData: [], carriersError: error, carriersLoading: false } },
            false,
            SET_NEW_QUOTE_CARRIERS_ERROR,
          );
        }
      },

      fetchPolicyType: async () => {
        try {
          const allowedPoliciesForAgency = useCarrierConfigStore.getState().allowedPoliciesForAgency;
          const response: IGetAllPolicyTypesOutput = await requestGetPolicyTypes();

          const allowedPolicyTypes = allowedPoliciesForAgency?.map((item) => item.policyTypeId);

          const filteredPolicyTypes = response?.policyTypes?.filter((policyType) => {
            if (allowedPolicyTypes?.length > 0) {
              return allowedPolicyTypes?.includes(policyType.policyTypeId) ? true : false;
            }
            return true;
          });

          set(
            {
              policyTypeStore: {
                policyTypesData: filteredPolicyTypes,
                policyTypesError: null,
                policyTypesLoading: false,
              },
            },
            false,
            SET_NEW_QUOTE_POLICY_TYPE,
          );
        } catch (error: any) {
          set(
            { policyTypeStore: { policyTypesData: [], policyTypesError: error, policyTypesLoading: false } },
            false,
            SET_NEW_QUOTE_POLICY_TYPE_ERROR,
          );
        }
      },

      fetchCarrierRankingByPolicyTypeId: async (params: IGetCarrierRankingInput) => {
        try {
          set(
            {
              supportedCarriersStore: {
                ...initSupportedCarriersStore,
                supportedCarriersLoading: true,
              },
            },
            false,
            SET_SUPPORTED_CARRIERS_LOADING,
          );
          const response: IGetCarrierRankingOutput = await requestGetCarrierRankingByPolicyTypeId({
            agentId: params?.agentId,
            policyTypeId: params?.policyTypeId,
          });
          const {
            carriersStore: { carriersData },
          } = get();

          const supportedCarriersArr: Array<{
            carrierId: Carrier;
            carrierDisplayName: string;
          }> = getSupportedCarriers(carriersData, response?.onCarriers, params?.policyTypeId);

          set(
            {
              supportedCarriersStore: {
                supportedCarriersData: { [params?.policyTypeId]: supportedCarriersArr },
                supportedCarriersError: null,
                supportedCarriersLoading: false,
              },
            },
            false,
            SET_NEW_QUOTE_SUPPORTED_CARRIERS,
          );
        } catch (error: any) {
          set(
            {
              supportedCarriersStore: {
                supportedCarriersData: {},
                supportedCarriersError: error,
                supportedCarriersLoading: false,
              },
            },
            false,
            SET_NEW_QUOTE_SUPPORTED_CARRIERS_ERROR,
          );
        }
      },

      fetchAllAccounts: async (params: IGetAccountsInput) => {
        try {
          const response: IGetAccountsOutput = await requestGetAccounts({
            agencyId: params?.agencyId,
          });
          const fetchedAccounts = response?.accounts.map((account) => ({
            accountId: account?.accountId,
            accountName: account?.accountName,
            accountPrimaryState: account?.primaryAddress?.state,
          }));
          set(
            {
              allAccountsStore: {
                allAccountsData: fetchedAccounts,
                allAccountsError: null,
                allAccountsLoading: false,
              },
            },
            false,
            SET_NEW_QUOTE_ACCOUNTS,
          );
        } catch (error: any) {
          set(
            { allAccountsStore: { allAccountsData: [], allAccountsError: error, allAccountsLoading: false } },
            false,
            SET_NEW_QUOTE_ACCOUNTS_ERROR,
          );
        }
      },

      fetchNaicsCodes: async () => {
        const { naicsCodesStore } = get();
        if (naicsCodesStore.naicsCodesData.length > 0) {
          return;
        }
        try {
          const response = await getAllNaicsCodes();
          set(
            {
              naicsCodesStore: {
                naicsCodesData: parseIndustryCodesToLabelValue({
                  naicsCodeInfos: response?.naicsCodeInfos,
                  isValueNaicsExtendedId: true,
                }),
                naicsCodesLoading: false,
                naicsCodesError: null,
              },
            },
            false,
            SET_NEW_QUOTE_NAICS_CODE,
          );
        } catch (error: any) {
          set(
            {
              naicsCodesStore: {
                naicsCodesData: [],
                naicsCodesLoading: false,
                naicsCodesError: error,
              },
            },
            false,
            SET_NEW_QUOTE_NAICS_CODE_ERROR,
          );
        }
      },

      fetchSicCodes: async () => {
        const { sicCodesStore } = get();
        if (sicCodesStore.sicCodesData.length > 0) {
          return;
        }
        try {
          const response = await getAllSicCodes();
          set(
            {
              sicCodesStore: {
                sicCodesData: parseIndustryCodesToLabelValue({ sicCodeInfos: response.sicCodeInfos }),
                sicCodesLoading: false,
                sicCodesError: null,
              },
            },
            false,
            SET_NEW_QUOTE_SIC_CODE,
          );
        } catch (error: any) {
          set(
            {
              sicCodesStore: {
                sicCodesData: [],
                sicCodesLoading: false,
                sicCodesError: error,
              },
            },
            false,
            SET_NEW_QUOTE_SIC_CODE_ERROR,
          );
        }
      },

      fetchAppetiteDetails: async (params: ICheckAppetiteInput) => {
        try {
          set({
            appetiteDetailsStore: {
              ...initAppetiteDetailsStore,
              appetiteLoading: true,
            },
          });
          const { brokerConfigDataStore } = get();
          const { brokerIdConfigs } = brokerConfigDataStore;
          const response: IAppetiteInfo[] = await requestAppetiteDetails(params);
          const inAppetiteCarriers: IAppetiteField[] = [];
          const notInAppetiteCarriers: IAppetiteField[] = [];
          const noBrokerCodeCarriers: IAppetiteField[] = [];
          const {
            carriersStore: { carriersData },
          } = get();
          // hasAppetite contract will change from backend soon
          if (response) {
            response.forEach((item) => {
              const carrierShortName = carriersData?.filter((carrier) => carrier.carrierId === item.carrier)[0]
                ?.carrierShortName;
              if (!brokerIdConfigs || (brokerIdConfigs && brokerIdConfigs[item.carrier])) {
                if (
                  item?.hasAppetite === APPETITE_TYPE.IN_APPETITE ||
                  item?.hasAppetite === APPETITE_TYPE.MAYBE_IN_APPETITE
                ) {
                  inAppetiteCarriers.push({ label: carrierShortName, value: item.carrier });
                } else {
                  notInAppetiteCarriers.push({ label: carrierShortName, value: item.carrier });
                }
              } else {
                noBrokerCodeCarriers.push({ label: carrierShortName, value: item.carrier });
              }
            });
          }

          set(
            {
              appetiteDetailsStore: {
                appetiteData: response,
                appetiteLoading: false,
                appetiteError: null,
                inAppetiteCarriers,
                notInAppetiteCarriers,
                noBrokerCodeCarriers,
              },
            },
            false,
            SET_NEW_QUOTE_APPETITE,
          );
        } catch (error: any) {
          set(
            {
              appetiteDetailsStore: {
                appetiteData: [],
                appetiteLoading: false,
                appetiteError: error,
                inAppetiteCarriers: [],
                notInAppetiteCarriers: [],
                noBrokerCodeCarriers: [],
              },
            },
            false,
            SET_NEW_QUOTE_APPETITE_ERROR,
          );
        }
      },

      fetchBrokerConfigs: async ({ agencyId, brokerConfigsLoading = true }) => {
        try {
          set({
            brokerConfigDataStore: {
              ...initBrokerConfigData,
              brokerConfigsLoading,
            },
          });
          const agencyBrokerCodes = await getBrokerCodesByAgencyId({
            agencyId,
          });
          const brokerIdConfigs: { [key: string]: IBrokerCodeInfo[] } = {};
          agencyBrokerCodes.brokerCodes.forEach((item) => {
            if (item.isEnabled) {
              if (brokerIdConfigs[item.carrierId]) {
                brokerIdConfigs[item.carrierId].push(item);
              } else {
                brokerIdConfigs[item.carrierId] = [item];
              }
            }
          });
          set(
            {
              brokerConfigDataStore: {
                brokerIdConfigs,
                brokerConfigsLoading: false,
                brokerConfigsError: undefined,
              },
            },
            false,
            ACTION_TYPE.SET_NEW_QUOTE_BROKER_CONFIGS,
          );
        } catch (err) {
          set(
            {
              brokerConfigDataStore: {
                brokerIdConfigs: undefined,
                brokerConfigsLoading: false,
                brokerConfigsError: err,
              },
            },
            false,
            ACTION_TYPE.SET_NEW_QUOTE_BROKER_CONFIGS_ERROR,
          );
        }
      },

      createNewApplication: async (params: ICreateApplicationInput) => {
        try {
          set({ createNewApplicationLoading: true });
          const response: ICreateApplicationOutput = await requestCreateApplication(params);
          set({ newApplicationId: response?.applicationId, createNewApplicationLoading: false });
        } catch (error: any) {
          errorAlert(error?.[0]?.errorMessage);
          set({ newApplicationId: '', createNewApplicationLoading: false });
        }
      },

      createNewApplicationFromAcord: async (params: ICreateApplicationFromAcordInput) => {
        try {
          set({ createNewApplicationLoading: true });
          const response = await requestCreateApplicationFromAcord(params);
          set({ newApplicationId: response?.applicationId, createNewApplicationLoading: false });
        } catch (error: any) {
          errorAlert(error?.[0]?.errorMessage);
          set({ newApplicationId: '', createNewApplicationLoading: false });
        }
      },

      updateNewQuoteByKey: async (storeKey: keyof INewQuoteStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, ACTION_TYPE.UPDATE_NEW_QUOTE_STORE_BY_KEY),

      clearNewQuoteStore: () => {
        const { presetAccountId } = get();
        set(
          {
            carriersStore: {
              ...initCarriersStoreState,
            },
            policyTypeStore: {
              ...initPolicyTypeStore,
            },
            supportedCarriersStore: {
              ...initSupportedCarriersStore,
            },
            allAccountsStore: { ...initAllAccountsStore },
            naicsCodesStore: { ...initNaicsCodesStore },
            sicCodesStore: { ...initSicCodesStore },

            appetiteDetailsStore: { ...initAppetiteDetailsStore },
            brokerConfigDataStore: { ...initBrokerConfigData },
            newApplicationId: '',
            createNewApplicationLoading: false,
            presetAccountId: location.pathname.includes(PAGE_ROUTES.ACCOUNTS) ? presetAccountId : undefined,
            selectedIndustryCodeType: INDUSTRY_CODES.NAICS.TYPE,
          },
          false,
          CLEAR_NEW_QUOTE_STORE,
        );
      },
    }),
    { name: ACTION_TYPE.NAME, anonymousActionType: ACTION_TYPE.ANONYMOUS_ACTION },
  ),
);
