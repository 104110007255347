import styled from 'styled-components/macro';

import CFForm from '../../ui-core/V2/cfForm/cfForm';

export const UnderWritingCardStyled = styled.div`
  margin: 30px 0;

  .ant-card-body {
    padding: 40px;
  }
  background: transparent;
`;

export const AccordionTitleStyled = styled.div`
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  color: ${(p) => p?.theme?.colors?.grayscale?.black};
`;

export const VerticalSpace = styled.div`
  margin-top: 30px;
`;

export const NoUwQuestion = styled.div`
  margin-left: 10px;
`;

export const CFFormStyled = styled(CFForm)`
  .ant-form-item-row {
    display: flex;
  }
  .ant-form-item-label {
    label {
      color: ${(p) => p?.theme?.colors?.grayscale?.black};
      font-weight: ${(p) => p.theme.typography.fontWeight.medium};
      height: auto;
      margin-right: 50px;
      display: flex;
      align-items: flex-start;
    }
  }

  .question-wrapper {
    & > div > div.ant-form-item {
      padding-bottom: 10px;
      border-bottom: ${(p) => `1px solid ${p.theme.colors.primary.borderLight}`};
    }
  }
`;

export const ErrorRetryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
