import { Carrier, PolicyType } from '@coverforce-platform/cf-common-types';
import { Form } from 'antd';
import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { FEATURE_FLAG, PAGE_ROUTES } from '../../../globalConstants';
import { useAccountStore } from '../../../pages/v2/account/store';
import { useProfileV2Store } from '../../../pages/v2/profile/store';
import { success, toastInfo } from '../../../ui-core/Notification';
import CFLoader from '../../../ui-core/V2/cfLoader/cfLoader';
import CFModal from '../../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import getBase64FromFile from '../../../utils/getBase64FromFile';
import { pushEvent } from '../../../utils/googleAnalytics';
import { getGrowthbookFeatureValueByKey } from '../../../utils/growthbook';
import AccordFieldsForNewQuote from '../../accordFieldsForNewQuote/accordFieldsForNewQuote';
import { ACCORD_FIELDS_STORE_KEYS } from '../../accordFieldsForNewQuote/constants';
import { useAccordFieldsForNewQuoteStore } from '../../accordFieldsForNewQuote/store';
import AppetiteCarrierListContainer from '../../appetiteCarrierListContainer/appetiteCarrierListContainer';
import { useAppetiteCarrierListContainerStore } from '../../appetiteCarrierListContainer/store';
import ErrorRetry from '../../errorRetry/errorRetry';
import NewQuoteFormFieldFormField from '../../newQuoteFormField/newQuoteFormField';
import { useNewQuoteFormFieldStore } from '../../newQuoteFormField/store';
import {
  ACORD_MESSAGES,
  FORM_CONFIG,
  GA_ACTION,
  GA_LABEL,
  NEW_QUOTE_MESSAGES,
  NEW_QUOTE_STORE_KEYS,
} from './constants';
import { IApplicationAcordConfig, INewQuoteModalForm } from './interface';
import { useNewQuoteStore } from './store';
import { CFFormStyled } from './styles';
import {
  autoFillCloneApplicationValues,
  getAppetiteDetails,
  getGACategory,
  getStateFromAccountId,
  isPolicyTypeAllowedForAcord,
} from './utils';

const NewQuoteModal = () => {
  const { userData, selectedUserProfile } = useProfileV2Store();
  const { clearCfModal } = useCFModalStore();
  const {
    presetAccountId,
    supportedCarriersStore,
    newApplicationId,
    createNewApplicationLoading,
    fetchAvailableCarriersByAgentId,
    fetchBrokerConfigs,
    createNewApplication,
    createNewApplicationFromAcord,
    clearNewQuoteStore,
    clonedApplicationId,
    cloneApplication,
    clonedApplication,
    updateNewQuoteByKey,
  } = useNewQuoteStore();

  const {
    naicsCodesStore,
    allAccountsStore,
    fetchPolicyType,
    fetchAllAccounts,
    fetchNaicsCodes,
    setAccountId,
    accountId,
    selectedIndustryCodeType,
    clearNewQuoteFormFieldStore,
  } = useNewQuoteFormFieldStore();
  const { clearAccordFieldsForNewQuote, isAcordUpload, fileList, updateAccordFieldsByKey } =
    useAccordFieldsForNewQuoteStore();

  const { clearAppetiteCarrierListContainerStore, selectedCarriers, appetiteDetailsStore } =
    useAppetiteCarrierListContainerStore();

  // Fields Config
  const { fetchApplications } = useAccountStore();
  const { supportedCarriersData, supportedCarriersLoading } = supportedCarriersStore;
  const { allAccountsData, allAccountsLoading } = allAccountsStore;
  const { accountsField, policyTypeField } = FORM_CONFIG(selectedIndustryCodeType);
  const theme = useTheme();

  const fetchIndustryCodes = useMemo(() => fetchNaicsCodes, [fetchNaicsCodes]);

  const { appetiteData } = appetiteDetailsStore;

  // miscellaneous
  const navigate = useNavigate();

  const [form] = Form.useForm<INewQuoteModalForm>();

  const allowedPolicyTypesForAcord: IApplicationAcordConfig[] = getGrowthbookFeatureValueByKey(
    FEATURE_FLAG.APPLICATION_CREATION_ACORD_CONFIG,
  );

  useEffect(() => {
    updateNewQuoteByKey(NEW_QUOTE_STORE_KEYS.NEW_QUOTE_MODAL_FORM, form);
    presetAccountId && setAccountId(presetAccountId);
  }, [form, presetAccountId, setAccountId, updateNewQuoteByKey]);

  useEffect(
    () => () => {
      clearNewQuoteStore();
      clearNewQuoteFormFieldStore();
      clearAccordFieldsForNewQuote();
      clearAppetiteCarrierListContainerStore();
      clearCfModal();
    },
    [
      clearAccordFieldsForNewQuote,
      clearNewQuoteStore,
      clearAppetiteCarrierListContainerStore,
      clearCfModal,
      clearNewQuoteFormFieldStore,
    ],
  );

  useEffect(() => {
    (async () => {
      await autoFillCloneApplicationValues();
    })();
  }, [clonedApplicationId, form, naicsCodesStore.naicsCodesLoading, allAccountsLoading]);

  useEffect(() => {
    // on mount api calls
    if (userData && selectedUserProfile?.agencyId && userData?.agentId) {
      fetchAllAccounts({ agencyId: selectedUserProfile.agencyId });
      fetchPolicyType();
      fetchAvailableCarriersByAgentId({
        agentId: userData.agentId,
      });
      fetchIndustryCodes();
      fetchBrokerConfigs({ agencyId: selectedUserProfile.agencyId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Prefill state when agent opens newquote modal from accounts page
  useEffect(() => {
    if (presetAccountId) {
      const stateInfo = getStateFromAccountId(allAccountsData, presetAccountId);
      form?.setFieldsValue({ state: stateInfo?.code });
      if (allAccountsData?.find((item) => item.accountId === presetAccountId)) {
        form?.setFieldValue(accountsField.name, presetAccountId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccountsData]);

  useEffect(() => {
    const supportedCarriers = supportedCarriersData?.[form?.getFieldValue(policyTypeField.name)];
    form?.setFieldsValue({
      carriers: supportedCarriers?.length > 0 ? supportedCarriers.map(({ carrierId }) => carrierId) : [],
    });

    supportedCarriers?.length > 0 && getAppetiteDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedCarriersData]);

  useEffect(() => {
    if (newApplicationId) {
      if (isAcordUpload) {
        toastInfo({
          title: <div style={{ fontSize: '14px' }}>{ACORD_MESSAGES.FORM_UPLOADED_APPLICATION_CREATING}</div>,
          iconColor: theme?.colorScheme?.primary,
        });
        if (presetAccountId) {
          fetchApplications({ accountId: presetAccountId });
        } else {
          navigate(`${PAGE_ROUTES.ACCOUNTS}${accountId}`);
        }
      } else {
        navigate(`${PAGE_ROUTES.APPLICATION}${newApplicationId}`);
      }
      clearCfModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, newApplicationId, clearCfModal, isAcordUpload, accountId]);

  const createApplicationAcord = async (validation: INewQuoteModalForm) => {
    if (fileList.length === 0) {
      updateAccordFieldsByKey(ACCORD_FIELDS_STORE_KEYS.ACCORD_ERROR, true);
    } else {
      const acordFiles = [];
      for (const file of fileList) {
        if (file.originFileObj) {
          const base64Data = await getBase64FromFile(file.originFileObj);
          acordFiles.push({ fileBase64: base64Data as string, fileName: file.originFileObj.name });
        }
      }
      createNewApplicationFromAcord({
        accountId: presetAccountId || (validation.account as PolicyType),
        carriersSelected: selectedCarriers as Carrier[],
        policyTypes: [validation.policyType as PolicyType],
        files: acordFiles,
      });
    }
  };

  const handleCancelClick = () => {
    pushEvent({
      category: getGACategory(),
      action: clonedApplicationId ? GA_ACTION.CLOSE_CLONE_APP_MODAL : GA_ACTION.CLOSE_NEW_QUOTE_MODAL,
      label: clonedApplicationId ? GA_LABEL.CLONE_MODAL_CLOSE : GA_LABEL.CLOSE_NEW_QUOTE_MODAL,
    });
  };

  const handleStartClick = async () => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.START_NEW_APPLICATION,
      label: GA_LABEL.START_NEW_APPLICATION,
    });
    const validation = await form.validateFields();
    const formData = await form?.getFieldsValue();
    if (isAcordUpload) {
      await createApplicationAcord(validation);
    } else {
      selectedCarriers?.length > 0 &&
        createNewApplication({
          accountId: validation.account as PolicyType,
          carriersSelected: selectedCarriers as Carrier[],
          policyTypes: [validation.policyType as PolicyType],
          naicsExtendedId: formData?.naicsCode?.split('-')?.[1]?.trim(),
        });
    }
  };

  const handleCloneClick = async () => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.CLONE_APPLICATION_BTN_CLICK,
      label: GA_LABEL.CLONE_MODAL_SUCCESS,
    });

    const validation = await form.validateFields();
    if (selectedCarriers?.length > 0) {
      const policySelected = validation.policyType ? [validation.policyType as PolicyType] : [];
      const newApplication = await cloneApplication({
        applicationId: clonedApplicationId!,
        carriers: selectedCarriers as Carrier[],
        policyTypes: policySelected,
      });
      if (newApplication) {
        success(NEW_QUOTE_MESSAGES.applicationCloneSuccess);
        navigate(`${PAGE_ROUTES.APPLICATION}${newApplication?.newApplicationId}`);
      }
    }
  };

  return (
    <CFModal
      title={!clonedApplicationId ? 'Start New Quote' : 'Clone Application'}
      okText={!clonedApplicationId ? 'Start' : 'Clone'}
      onOk={!clonedApplicationId ? handleStartClick : handleCloneClick}
      onCancel={handleCancelClick}
      cancelButtonProps={{
        id: 'btn_new_quote_cancel',
        disabled: createNewApplicationLoading,
      }}
      okButtonProps={{
        id: 'btn_new_quote_ok',
        loading: createNewApplicationLoading,
        disabled: (appetiteData?.length > 0 && selectedCarriers?.length === 0) || clonedApplication?.loading,
      }}
      disableCloseIcon={createNewApplicationLoading}
      maskClosable={!createNewApplicationLoading}
      width={622}
    >
      <CFFormStyled
        id='form_new_quote_modal'
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        disabled={isAcordUpload && createNewApplicationLoading}
        style={{
          opacity: isAcordUpload && createNewApplicationLoading ? 0.5 : 1,
        }}
      >
        {clonedApplication?.loading && <CFLoader />}
        {!clonedApplication?.loading && !clonedApplication?.error && <NewQuoteFormFieldFormField />}

        {!clonedApplication?.loading && clonedApplication?.error && (
          <ErrorRetry
            errorText={'An error occurred while fetching application'}
            onRetry={autoFillCloneApplicationValues}
          />
        )}

        <AppetiteCarrierListContainer />

        {!clonedApplicationId && (
          <AccordFieldsForNewQuote
            isAccordUploadDisabled={
              !isPolicyTypeAllowedForAcord(allowedPolicyTypesForAcord, form.getFieldValue(policyTypeField.name)) ||
              createNewApplicationLoading ||
              supportedCarriersLoading
            }
          />
        )}
      </CFFormStyled>
    </CFModal>
  );
};

export default memo(NewQuoteModal);
