import { ApplicationFieldPathEnum, Carrier } from '@coverforce-platform/cf-common-types';

import { OWNER_OFFICER_QUESTION_KEYS } from '../ownerOfficerDetailsModal/constants';
import { IOwnerDetailsStore } from './interface';

export const OWNER_OFFICER_DETAILS_STORE_META_DATA = {
  SET_OWNER_OFFICER_DETAILS: 'SET_OWNER_OFFICER_DETAILS',
  OWNER_OFFICER_DETAILS_LOADING: 'OWNER_OFFICER_DETAILS_LOADING',
  UPDATE_OWNER_OFFICER_STORE_BY_KEY: 'UPDATE_OWNER_OFFICER_STORE_BY_KEY',
  ADD_OWNER_OFFICER_DETAILS: 'ADD_OWNER_OFFICER_DETAILS',
  SET_ERROR_OWNER_OFFICER_DETAILS: 'SET_ERROR_OWNER_OFFICER_DETAILS',
  UPDATE_OWNER_OFFICER_DETAILS: 'UPDATE_OWNER_OFFICER_DETAILS',
  DELETE_OWNER_OFFICER_DETAIL: 'DELETE_OWNER_OFFICER_DETAIL',
  AUTO_FILL_OWNER_OFFICER_DETAILS: 'AUTO_FILL_OWNER_OFFICER_DETAILS',
  CLEAR_OWNER_OFFICER_STORE: 'CLEAR_OWNER_OFFICER_STORE',
  ANONYMOUS_ACTION_NAME: 'OWNER_OFFICER_ANONYMOUS_ACTION',
  STORE_NAME: 'OWNER_OFFICER_STORE',
  SET_OWNERS_OFFICERS_TITLE_OPTIONS: 'SET_OWNERS_OFFICERS_TITLE_OPTIONS',
  SET_OWNERS_OFFICERS_CLASS_CODE: 'SET_OWNERS_OFFICERS_CLASS_CODE',
  FAILED_TO_FETCH_STATE_CLASS_CODES: 'FAILED_TO_FETCH_STATE_CLASS_CODES',
};

export const OWNER_OFFICER_DETAILS_STORE_KEYS: { [key: string]: keyof IOwnerDetailsStore } = {
  OWNER_OFFICER_DETAILS_LIST: 'ownerOfficerDetailsList',
  OWNER_OFFICER_DETAILS_ERROR: 'ownerOfficerDetailsError',
  OWNER_OFFICER_DETAILS_LOADING: 'ownerOfficerDetailsLoading',
  OWNER_OFFICER_DETAILS_TAB_LOADING: 'ownerOfficerDetailsTabLoading',
};

export const OWNER_OFFICER_KEYS_MAPPING = {
  [OWNER_OFFICER_QUESTION_KEYS.FIRST_NAME]: 'First Name',
  [OWNER_OFFICER_QUESTION_KEYS.LAST_NAME]: 'Last Name',
  [OWNER_OFFICER_QUESTION_KEYS.ANNUAL_PAYROLL]: 'Annual Payroll',
  [OWNER_OFFICER_QUESTION_KEYS.LOCATION_ID]: 'Location',
  [OWNER_OFFICER_QUESTION_KEYS.JOB_CODE_EXTENDED_ID]: 'Employee type',
  [OWNER_OFFICER_QUESTION_KEYS.DOB]: 'Date Of Birth',
};

export const OWNER_OFFICER_FIELDS_PATH = [
  ApplicationFieldPathEnum.OWNERS_FIRST_NAME,
  ApplicationFieldPathEnum.OWNERS_LAST_NAME,
  ApplicationFieldPathEnum.OWNERS_ANNUAL_PAYROLL,
  ApplicationFieldPathEnum.OWNERS_EMPLOYEE_JOB_CLASS_CODE,
];

export const OPTIONAL_FIELDS = [
  OWNER_OFFICER_QUESTION_KEYS.OWNERSHIP_PERCENTAGE,
  OWNER_OFFICER_QUESTION_KEYS.OFFICIAL_TITLE,
];

export const OWNERS_OFFICERS_TITLE_RESPONSE_KEY = {
  TITLE_LABEL: 'cfOwnerOfficerTitleDescription',
  TITLE_VALUE: 'cfOwnerOfficerTitle',
};

export const CARD_INFO = {
  GENERAL: {
    title: 'Please note:',
    description:
      'We follow the state based defaults for including/excluding Owner/Officer coverage. You can see what coverage is included or excluded above for each Owner/officer. In order to make any changes please bridge across to the respective carrier portal from the Quotes page.',
    additionalDescription: [
      {
        carrier: Carrier.TRAVELERS,
        description:
          'Travelers requires the details of the Owners and Officers of the business to provide a bindable quote. Not providing the information will result in a Decline.',
      },
    ],
  },
};

export const OWNERS_OFFICERS_ERROR = { FAILED_TO_FETCH_STATE_CLASS_CODES: 'Failed to get state class codes!' };
