import { RadioChangeEvent, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { useEffect, useMemo } from 'react';

import { FEATURE_FLAG } from '../../globalConstants';
import { errorV2 } from '../../ui-core/Notification';
import CFFileDropper from '../../ui-core/V2/cfFileDropper/cfFileDropper';
import { getGrowthbookFeatureValueByKey, isGrowthbookFeatureEnabledByKey } from '../../utils/growthbook';
import { FORM_CONFIG } from '../modals/newQuoteV2/constants';
import { IApplicationAcordConfig } from '../modals/newQuoteV2/interface';
import { useNewQuoteStore } from '../modals/newQuoteV2/store';
import { useNewQuoteFormFieldStore } from '../newQuoteFormField/store';
import {
  ACCORD_FIELDS_STORE_KEYS,
  DEFAULT_MAX_ALLOWED_ACORD_FILE_SIZE_KB,
  FILE_UPLOAD_SUCCESS_CALLBACK_TEXT,
  UPLOAD_ACORD_SUPPORTED_EXT,
} from './constants';
import { IAccordFieldsForNewQuote } from './interface';
import { useAccordFieldsForNewQuoteStore } from './store';
import {
  AccordInfoTagStyled,
  AccordInfoWrapper,
  AcordTextStyled,
  AppetiteErrorStyled,
  CFFormItemStyled,
  CFRadioGroupStyled,
  InboxOutlinedStyled,
} from './styles';
import { extractPolicySpecificRule, getAcordUploadMessageForPolicyType } from './utils';

const AccordFieldsForNewQuote = ({ isAccordUploadDisabled }: IAccordFieldsForNewQuote) => {
  const createApplicationFromAcord = isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.CREATE_APPLICATION_FROM_ACORD);
  const allowedPolicyTypesForAcord: IApplicationAcordConfig[] = getGrowthbookFeatureValueByKey(
    FEATURE_FLAG.APPLICATION_CREATION_ACORD_CONFIG,
  );

  const { newQuoteModalForm: form } = useNewQuoteStore();
  const { selectedIndustryCodeType } = useNewQuoteFormFieldStore();
  const { isAcordUpload, fileList, acordError, updateAccordFieldsByKey } = useAccordFieldsForNewQuoteStore();
  const { acordField, policyTypeField } = FORM_CONFIG(selectedIndustryCodeType);

  useEffect(() => {
    if ((isAcordUpload && fileList.length !== 0) || !isAcordUpload) {
      updateAccordFieldsByKey(ACCORD_FIELDS_STORE_KEYS.ACCORD_ERROR, false);
    }
  }, [isAcordUpload, fileList, updateAccordFieldsByKey]);

  useEffect(() => {
    if (!isAcordUpload) {
      updateAccordFieldsByKey(ACCORD_FIELDS_STORE_KEYS.FILE_LIST, []);
    }
  }, [isAcordUpload, updateAccordFieldsByKey]);

  useEffect(() => {
    form?.setFieldValue(acordField.name, false);
  }, [acordField.name, form]);

  const policySpecificAcordFormRule = useMemo(
    () => extractPolicySpecificRule(allowedPolicyTypesForAcord, form?.getFieldValue(policyTypeField.name)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowedPolicyTypesForAcord, form?.getFieldValue(policyTypeField.name)],
  );

  const allowedAcordFormSize = useMemo(
    () => policySpecificAcordFormRule?.maxFileSizeInKb || DEFAULT_MAX_ALLOWED_ACORD_FILE_SIZE_KB,
    [policySpecificAcordFormRule?.maxFileSizeInKb],
  );

  const onIsAcordUploadChange = (e: RadioChangeEvent) => {
    updateAccordFieldsByKey(ACCORD_FIELDS_STORE_KEYS.ACCORD_UPLOAD, e.target.value);
  };

  const fileUploadCustomRequest = async (options: any) => {
    setTimeout(() => {
      options.onSuccess(FILE_UPLOAD_SUCCESS_CALLBACK_TEXT);
    }, 0);
  };

  const onAcordUploadChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    let newFileList = [...info.fileList];
    let fileSize = 0;
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      if (file.size) {
        fileSize += file?.size;
      }
      return file;
    });
    if (fileSize / 1024 > allowedAcordFormSize) {
      errorV2(
        policySpecificAcordFormRule?.maxFileSizeErrorMessage ||
          `Files size must be smaller than ${allowedAcordFormSize}KB!`,
        3,
      );
    } else {
      updateAccordFieldsByKey(ACCORD_FIELDS_STORE_KEYS.FILE_LIST, newFileList);
    }
  };

  const isAcordDisabled = useMemo(() => {
    return !isAcordUpload || isAccordUploadDisabled;
  }, [isAcordUpload, isAccordUploadDisabled]);

  return (
    <>
      {!createApplicationFromAcord && (
        <AccordInfoWrapper>
          <AccordInfoTagStyled color='yellow'>
            The ACORD form feature is currently disabled. Please check back in a couple of hours.
          </AccordInfoTagStyled>
        </AccordInfoWrapper>
      )}
      {createApplicationFromAcord && (
        <>
          <CFFormItemStyled
            key={acordField.id}
            id={acordField.id}
            name={acordField.name}
            label={acordField.label}
            tooltip={acordField.tooltip}
          >
            <CFRadioGroupStyled
              id='rad_acord_upload'
              options={acordField.options}
              onChange={onIsAcordUploadChange}
              disabled={isAccordUploadDisabled}
            />
          </CFFormItemStyled>
          <CFFileDropper
            id='accord_upload'
            multiple
            maxSizeAllowedKb={allowedAcordFormSize}
            sizeExceedMessage={policySpecificAcordFormRule?.maxFileSizeErrorMessage}
            name='acord_files'
            customRequest={fileUploadCustomRequest}
            onChange={onAcordUploadChange}
            fileList={fileList}
            disabled={isAcordDisabled}
            accept={UPLOAD_ACORD_SUPPORTED_EXT.join(',')}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlinedStyled disabled={isAcordDisabled} />
            </p>
            <AcordTextStyled disabled={isAcordDisabled}>
              {getAcordUploadMessageForPolicyType(
                allowedPolicyTypesForAcord,
                form?.getFieldValue(policyTypeField.name),
              )}
            </AcordTextStyled>
          </CFFileDropper>
          {acordError && <AppetiteErrorStyled>Please upload atleast one ACORD form.</AppetiteErrorStyled>}
        </>
      )}
    </>
  );
};

export default AccordFieldsForNewQuote;
