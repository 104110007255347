import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { IPaymentIframe } from './interface';

export const usePaymentIframeStore = create<IPaymentIframe>()(
  devtools((set) => ({
    confirmIframePaymentLoading: false,
    updatePaymentIframeStoreByKey: async (storeKey, value) => set({ [storeKey]: value }),
    clearIframePaymentStore: () => set({ confirmIframePaymentLoading: false }),
  })),
);
