import { ClaimStatus } from '@coverforce-platform/cf-common-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { useApplicationStore } from '../../pages/v2/application/store';
import { getValidDate } from '../../utils/getValidDate';
import { PAST_POLICY_LOSS_STORE_ACTION } from './constants';
import { IPastPolicyLossDetails, IPastPolicyLossStore } from './interface';
import { createPastPolicyLossApiPayload, validatePolicyLossDetails } from './utils';

export const usePastPolicyLossStore = create<IPastPolicyLossStore>()(
  devtools(
    (set, get) => ({
      policyLossList: [],
      policyLossError: undefined,
      policyLossLoading: false,

      setPolicyLossLoading: (policyLossLoading: boolean) => {
        set({ policyLossLoading }, false, PAST_POLICY_LOSS_STORE_ACTION.POLICY_LOSS_LOADING);
      },

      deletePolicyLoss: (index: number) => {
        const { policyLossList, policyLossError } = get();

        const policyData = [...policyLossList];
        const errors = policyLossError ? [...policyLossError] : [];

        policyData.splice(index, 1);
        errors.splice(index, 1);

        set(
          { policyLossList: policyData, policyLossError: errors },
          false,
          PAST_POLICY_LOSS_STORE_ACTION.DELETE_PAST_POLICY_LOSS,
        );
      },

      addPolicyLossDetails: (policyLossDetails) => {
        const { policyLossList } = get();
        set(
          { policyLossList: [...policyLossList, policyLossDetails] },
          false,
          PAST_POLICY_LOSS_STORE_ACTION.ADD_PAST_POLICY_LOSS,
        );
      },

      updatePolicyLossDetails: (policyLossDetails, index) => {
        const { policyLossList, policyLossError } = get();
        const lossDetails = [...policyLossList];
        const errors = policyLossError ? [...policyLossError] : [];
        lossDetails.splice(index, 1, policyLossDetails);
        errors.splice(index, 1, { isError: false, errorMessage: '' });

        set(
          { policyLossList: lossDetails, policyLossError: errors },
          false,
          PAST_POLICY_LOSS_STORE_ACTION.UPDATE_PAST_POLICY_LOSS,
        );
      },

      validatePolicyLoss: () => {
        const { policyLossList } = get();
        const validatedPolicyLoss = validatePolicyLossDetails(policyLossList || []);
        if (!validatedPolicyLoss.isValid) {
          set(
            { policyLossError: validatedPolicyLoss.errors },
            false,
            PAST_POLICY_LOSS_STORE_ACTION.SET_ERROR_IN_PAST_POLICY_LOSS,
          );
        } else {
          set({ policyLossError: undefined }, false, PAST_POLICY_LOSS_STORE_ACTION.SET_ERROR_IN_PAST_POLICY_LOSS);
        }
        return validatedPolicyLoss.isValid;
      },

      autoFillPastPolicyLossDetails: () => {
        const { applicationData } = useApplicationStore.getState();

        const policyLossList: IPastPolicyLossDetails[] =
          applicationData?.pastPolicyLossDetails?.map((policyLossDetail) => {
            return {
              lossDate: getValidDate(policyLossDetail?.lossDate),
              claimDate: getValidDate(policyLossDetail?.claimDate),
              lossDescription: policyLossDetail?.lossDescription || '',
              lossType: policyLossDetail?.lossType || undefined,
              state: policyLossDetail?.state || undefined,
              claimStatus: (policyLossDetail?.claimStatus === ClaimStatus.OPEN).toString(),
              totalPaidAmount: policyLossDetail?.totalPaidAmount?.toString(),
              totalReservedAmount: policyLossDetail?.totalReservedAmount?.toString(),
              stepsTakenToAvoidFutureClaims: policyLossDetail?.stepsTakenToAvoidFutureClaims?.toString(),
            };
          }) || [];

        set({ policyLossList }, false, PAST_POLICY_LOSS_STORE_ACTION.AUTO_FILL_POLICY_LOSS_DETAILS);
      },

      getPastPolicyLossApiPayload: () => {
        const { policyLossList } = get();
        const { applicationData } = useApplicationStore.getState();

        const policyLossPayload = createPastPolicyLossApiPayload(policyLossList);
        return { ...applicationData, pastPolicyLossDetails: policyLossPayload };
      },

      clearPastPolicyLoss: () => {
        set(
          {
            policyLossList: [],
            policyLossError: undefined,
            policyLossLoading: false,
          },
          false,
          PAST_POLICY_LOSS_STORE_ACTION.CLEAR_POLICY_LOSS_MODAL_STORE,
        );
      },
    }),
    {
      anonymousActionType: PAST_POLICY_LOSS_STORE_ACTION.ANONYMOUS_ACTION,
      name: PAST_POLICY_LOSS_STORE_ACTION.STORE_NAME,
    },
  ),
);
