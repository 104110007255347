import { ILocationInfo } from '@coverforce-platform/cf-common-api-model';
import { Carrier, CfLegalEntityType, USState } from '@coverforce-platform/cf-common-types';

import { useOwnerOfficerDetailsStore } from '../../components/ownerOfficerDetails/store';
import { useApplicationStore } from '../../pages/v2/application/store';
import { optionType } from '../../types';
import { getAddressAsText } from '../../utils/application/application';
import { getValidDate } from '../../utils/getValidDate';
import { cloneDeep } from '../../utils/lodash';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import { getStateByLocationId } from '../ownerOfficerDetails/utils';
import { OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS, OWNER_OFFICER_QUESTION_KEYS } from './constants';
import { useOwnerOfficerDetailsModalStore } from './store';

export const addDynamicInfoInQuestions = (questions: IApplicationQuestion[]) => {
  const cloneQuestions = cloneDeep(questions);
  const { applicationData } = useApplicationStore.getState();
  const locationFieldIndex = cloneQuestions?.findIndex(
    (element) => element?.dataIndex === OWNER_OFFICER_QUESTION_KEYS.LOCATION_ID,
  );

  const percentageIndex = cloneQuestions?.findIndex(
    (element) => element?.dataIndex === OWNER_OFFICER_QUESTION_KEYS.OWNERSHIP_PERCENTAGE,
  );

  let locationFieldOptions: optionType[] = [];

  if (locationFieldIndex !== -1) {
    locationFieldOptions =
      applicationData?.locationDetails?.map((location: ILocationInfo) => ({
        label: getAddressAsText(location),
        value: location?.locationId || '',
      })) || [];
  }
  cloneQuestions[locationFieldIndex] = {
    ...cloneQuestions[locationFieldIndex],
    options: locationFieldOptions,
  };

  if (percentageIndex !== -1 && applicationData?.companyStructure?.legalEntityType === CfLegalEntityType.INDIVIDUAL) {
    cloneQuestions[percentageIndex].disabled = true;
  }
  return cloneQuestions;
};

export const getQuestionIndexByKey = (questionKey: string) => {
  const { questionElements } = useOwnerOfficerDetailsModalStore.getState();
  const questionElementsClone = cloneDeep(questionElements);
  const fieldIndex = questionElementsClone?.findIndex(
    (element: IApplicationQuestion) => element?.dataIndex === questionKey,
  );
  if (fieldIndex !== -1) {
    return fieldIndex;
  }
  return 0;
};

const autoFillFormInit = () => {
  const {
    ownerOfficerDetailsModalForm,
    editedOwnerOfficerDetails,
    questionElements,
    updateOwnerOfficerDetailsModalStoreByKey,
  } = useOwnerOfficerDetailsModalStore.getState();
  const { applicationData } = useApplicationStore.getState();
  const questionElementsClone = cloneDeep(questionElements);

  ownerOfficerDetailsModalForm?.setFieldsValue({
    ...editedOwnerOfficerDetails?.ownerOfficerDetails,
    [OWNER_OFFICER_QUESTION_KEYS.DOB]: undefined,
    [OWNER_OFFICER_QUESTION_KEYS.JOB_CODE_EXTENDED_ID]: undefined,
    [OWNER_OFFICER_QUESTION_KEYS.OFFICIAL_TITLE]: undefined,
  });

  applicationData?.companyStructure?.legalEntityType === CfLegalEntityType.INDIVIDUAL &&
    ownerOfficerDetailsModalForm?.setFields([
      {
        name: [OWNER_OFFICER_QUESTION_KEYS.OWNERSHIP_PERCENTAGE],
        value: '100',
        errors: [],
      },
    ]);

  const classCodeFieldIndex = getQuestionIndexByKey(OWNER_OFFICER_QUESTION_KEYS.JOB_CODE_EXTENDED_ID);
  const locationFieldIndex = getQuestionIndexByKey(OWNER_OFFICER_QUESTION_KEYS.LOCATION_ID);

  questionElementsClone[locationFieldIndex] = {
    ...questionElementsClone[locationFieldIndex],
    loading: true,
    disabled: true,
  };

  questionElementsClone[classCodeFieldIndex] = {
    ...questionElementsClone[classCodeFieldIndex],
    loading: true,
    disabled: true,
  };

  updateOwnerOfficerDetailsModalStoreByKey(
    OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS,
    questionElementsClone,
  );

  autoFillDobForGuard();
};

const autoFillDobForGuard = () => {
  const {
    ownerOfficerDetailsModalForm,
    editedOwnerOfficerDetails,
    questionElements,
    updateOwnerOfficerDetailsModalStoreByKey,
  } = useOwnerOfficerDetailsModalStore.getState();

  const questionElementsClone = cloneDeep(questionElements);
  const { selectedCarriersInfo } = useApplicationStore.getState();

  const state = getStateByLocationId(editedOwnerOfficerDetails?.ownerOfficerDetails?.cfLocationId);
  const selectedCarriers = selectedCarriersInfo?.map((carrierInfo) => carrierInfo?.carrierId);
  const dobFieldIndex = getQuestionIndexByKey(OWNER_OFFICER_QUESTION_KEYS.DOB);

  if (state === USState.NH && selectedCarriers?.includes(Carrier.GUARD)) {
    questionElementsClone[dobFieldIndex].hidden = false;
    questionElementsClone[dobFieldIndex].rules[0].required = true;
    ownerOfficerDetailsModalForm?.setFieldValue(
      OWNER_OFFICER_QUESTION_KEYS.DOB,
      getValidDate(editedOwnerOfficerDetails?.ownerOfficerDetails?.dateOfBirth),
    );
  } else {
    questionElementsClone[dobFieldIndex].hidden = true;
    questionElementsClone[dobFieldIndex].rules[0].required = false;
    ownerOfficerDetailsModalForm?.setFieldValue(OWNER_OFFICER_QUESTION_KEYS.DOB, undefined);
  }

  updateOwnerOfficerDetailsModalStoreByKey(
    OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS,
    questionElementsClone,
  );
};

const autoFillClassCodeDependentFields = async () => {
  const {
    questionElements,
    ownerOfficerDetailsModalForm,
    editedOwnerOfficerDetails,
    updateOwnerOfficerDetailsModalStoreByKey,
  } = useOwnerOfficerDetailsModalStore.getState();

  const { fetchOwnerOfficerClassCodes } = useOwnerOfficerDetailsStore.getState();
  const questionElementsClone = cloneDeep(questionElements);

  let ncciClassCodes: optionType[] = [];
  const classCodeFieldIndex = getQuestionIndexByKey(OWNER_OFFICER_QUESTION_KEYS.JOB_CODE_EXTENDED_ID);
  const locationFieldIndex = getQuestionIndexByKey(OWNER_OFFICER_QUESTION_KEYS.LOCATION_ID);

  if (editedOwnerOfficerDetails?.ownerOfficerDetails?.cfLocationId) {
    ncciClassCodes = await fetchOwnerOfficerClassCodes(
      getStateByLocationId(editedOwnerOfficerDetails?.ownerOfficerDetails?.cfLocationId),
    );

    questionElementsClone[classCodeFieldIndex] = {
      ...questionElementsClone[classCodeFieldIndex],
      options: ncciClassCodes,
    };

    updateOwnerOfficerDetailsModalStoreByKey(
      OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS,
      questionElementsClone,
    );

    const selectedJobCodeExtendedId = editedOwnerOfficerDetails?.ownerOfficerDetails?.jobCodeExtendedId?.trim() || '';

    ownerOfficerDetailsModalForm?.setFieldValue(
      OWNER_OFFICER_QUESTION_KEYS.JOB_CODE_EXTENDED_ID,
      selectedJobCodeExtendedId,
    );
  }

  questionElementsClone[classCodeFieldIndex] = {
    ...questionElementsClone[classCodeFieldIndex],
    loading: false,
    disabled: false,
  };

  questionElementsClone[locationFieldIndex] = {
    ...questionElementsClone[locationFieldIndex],
    loading: false,
    disabled: false,
  };

  updateOwnerOfficerDetailsModalStoreByKey(
    OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS,
    questionElementsClone,
  );
};

const autoFillOwnersOfficersTitle = async () => {
  const {
    questionElements,
    ownerOfficerDetailsModalForm,
    editedOwnerOfficerDetails,
    updateOwnerOfficerDetailsModalStoreByKey,
  } = useOwnerOfficerDetailsModalStore.getState();

  const { fetchOwnerOfficerTitleOptions } = useOwnerOfficerDetailsStore.getState();

  const questionElementsClone = cloneDeep(questionElements);

  let ownersOfficersTitleOptions: optionType[] = [];
  const ownersOfficersTitleIndex = getQuestionIndexByKey(OWNER_OFFICER_QUESTION_KEYS.OFFICIAL_TITLE);

  if (editedOwnerOfficerDetails?.ownerOfficerDetails?.cfLocationId) {
    ownersOfficersTitleOptions = await fetchOwnerOfficerTitleOptions(
      getStateByLocationId(editedOwnerOfficerDetails?.ownerOfficerDetails?.cfLocationId),
    );

    if (ownersOfficersTitleOptions.length > 0) {
      questionElementsClone[ownersOfficersTitleIndex] = {
        ...questionElementsClone[ownersOfficersTitleIndex],
        options: ownersOfficersTitleOptions,
        hidden: false,
      };
      updateOwnerOfficerDetailsModalStoreByKey(
        OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS,
        questionElementsClone,
      );

      ownerOfficerDetailsModalForm?.setFieldValue(
        OWNER_OFFICER_QUESTION_KEYS.OFFICIAL_TITLE,
        editedOwnerOfficerDetails?.ownerOfficerDetails?.officialTitle,
      );
    }
  }

  updateOwnerOfficerDetailsModalStoreByKey(
    OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS,
    questionElementsClone,
  );
};

export const autoFillOwnerOfficerDetailsModalUtil = async () => {
  const { updateOwnerOfficerDetailsModalStoreByKey } = useOwnerOfficerDetailsModalStore.getState();

  updateOwnerOfficerDetailsModalStoreByKey(
    OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_LOADING,
    true,
  );

  autoFillFormInit();
  await autoFillClassCodeDependentFields();
  await autoFillOwnersOfficersTitle();

  updateOwnerOfficerDetailsModalStoreByKey(
    OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_LOADING,
    false,
  );
};

const updateDobFieldConfigForGuard = (value: any, questionElementsClone: IApplicationQuestion[]) => {
  const { ownerOfficerDetailsModalForm, updateOwnerOfficerDetailsModalStoreByKey } =
    useOwnerOfficerDetailsModalStore.getState();

  const { selectedCarriersInfo } = useApplicationStore.getState();
  const state = getStateByLocationId(value);

  const selectedCarriers = selectedCarriersInfo?.map((carrierInfo) => carrierInfo?.carrierId);
  const dobFieldIndex = getQuestionIndexByKey(OWNER_OFFICER_QUESTION_KEYS.DOB);

  if (state === USState.NH && selectedCarriers?.includes(Carrier.GUARD)) {
    questionElementsClone[dobFieldIndex].hidden = false;
    questionElementsClone[dobFieldIndex].rules[0].required = true;
  } else {
    ownerOfficerDetailsModalForm?.setFieldValue(OWNER_OFFICER_QUESTION_KEYS.DOB, undefined);
    questionElementsClone[dobFieldIndex].hidden = true;
    questionElementsClone[dobFieldIndex].rules[0].required = false;
  }

  updateOwnerOfficerDetailsModalStoreByKey(
    OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS,
    questionElementsClone,
  );
};

export const handleOwnerOfficerModalFieldChangeUtil = async (value: any, data: IApplicationQuestion) => {
  const { questionElements, ownerOfficerDetailsModalForm, updateOwnerOfficerDetailsModalStoreByKey } =
    useOwnerOfficerDetailsModalStore.getState();
  const { fetchOwnerOfficerClassCodes } = useOwnerOfficerDetailsStore.getState();

  const { fetchOwnerOfficerTitleOptions } = useOwnerOfficerDetailsStore.getState();

  const questionElementsClone = cloneDeep(questionElements);
  switch (data?.dataIndex) {
    case OWNER_OFFICER_QUESTION_KEYS.LOCATION_ID: {
      const classCodeFieldIndex = questionElementsClone?.findIndex(
        (element: IApplicationQuestion) => element?.dataIndex === OWNER_OFFICER_QUESTION_KEYS.JOB_CODE_EXTENDED_ID,
      );
      const locationFieldIndex = questionElementsClone?.findIndex(
        (element) => element?.dataIndex === OWNER_OFFICER_QUESTION_KEYS.LOCATION_ID,
      );
      const ownersOfficersTitleIndex = questionElementsClone?.findIndex(
        (element) => element?.dataIndex === OWNER_OFFICER_QUESTION_KEYS.OFFICIAL_TITLE,
      );
      const state = getStateByLocationId(value);

      ownerOfficerDetailsModalForm?.setFieldValue(OWNER_OFFICER_QUESTION_KEYS.JOB_CODE_EXTENDED_ID, undefined);
      ownerOfficerDetailsModalForm?.setFieldValue(OWNER_OFFICER_QUESTION_KEYS.OFFICIAL_TITLE, undefined);

      questionElementsClone[locationFieldIndex] = {
        ...questionElementsClone[locationFieldIndex],
        loading: true,
        disabled: true,
      };
      questionElementsClone[classCodeFieldIndex] = {
        ...questionElementsClone[classCodeFieldIndex],
        loading: true,
        disabled: true,
        options: [],
      };

      questionElementsClone[ownersOfficersTitleIndex] = {
        ...questionElementsClone[ownersOfficersTitleIndex],
        loading: true,
        disabled: true,
        options: [],
      };

      updateOwnerOfficerDetailsModalStoreByKey(
        OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS,
        questionElementsClone,
      );

      updateDobFieldConfigForGuard(value, questionElementsClone);

      const classCodesResponse = await fetchOwnerOfficerClassCodes(state);
      const ownerOfficerTitles = await fetchOwnerOfficerTitleOptions(state);

      if (ownerOfficerTitles.length > 0) {
        questionElementsClone[ownersOfficersTitleIndex] = {
          ...questionElementsClone[ownersOfficersTitleIndex],
          options: ownerOfficerTitles,
          hidden: false,
          disabled: false,
          loading: false,
        };
      } else {
        questionElementsClone[ownersOfficersTitleIndex] = {
          ...questionElementsClone[ownersOfficersTitleIndex],
          options: [],
          hidden: true,
          disabled: false,
          loading: false,
        };
      }

      questionElementsClone[classCodeFieldIndex] = {
        ...questionElementsClone[classCodeFieldIndex],
        disabled: false,
        loading: false,
        options: classCodesResponse,
      };
      questionElementsClone[locationFieldIndex] = {
        ...questionElementsClone[locationFieldIndex],
        loading: false,
        disabled: false,
      };

      updateOwnerOfficerDetailsModalStoreByKey(
        OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS,
        questionElementsClone,
      );
      break;
    }

    default:
      break;
  }
};
