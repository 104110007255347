import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { ADDITIONAL_FIELDS_FOR_NEW_QUOTE_STORE_META_DATA } from './constants';
import { IAccordFieldsForNewQuoteStore } from './interface';

export const useAccordFieldsForNewQuoteStore = create<IAccordFieldsForNewQuoteStore>()(
  devtools(
    (set) => ({
      fileList: [],
      acordError: undefined,
      isAcordUpload: false,

      updateAccordFieldsByKey: async (storeKey: keyof IAccordFieldsForNewQuoteStore, value: any) =>
        set(
          () => ({ [storeKey]: value }),
          false,
          ADDITIONAL_FIELDS_FOR_NEW_QUOTE_STORE_META_DATA.UPDATE_ACCORD_FIELDS_BY_KEY,
        ),

      clearAccordFieldsForNewQuote: () => {
        set(
          { fileList: [], acordError: undefined, isAcordUpload: false },
          false,
          ADDITIONAL_FIELDS_FOR_NEW_QUOTE_STORE_META_DATA.CLEAR_ACCORD_FIELDS_FOR_NEW_QUOTE,
        );
      },
    }),

    {
      anonymousActionType: ADDITIONAL_FIELDS_FOR_NEW_QUOTE_STORE_META_DATA.ANONYMOUS_ACTION_NAME,
      name: ADDITIONAL_FIELDS_FOR_NEW_QUOTE_STORE_META_DATA.STORE_NAME,
    },
  ),
);
