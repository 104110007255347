import { Carrier } from '@coverforce-platform/cf-common-types';

import { IUnderWritingQuestionsStore } from './interface';

export const UW_QUESTION_TYPE = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
  TEXT: 'TEXT',
  SINGLESELECTDROPDOWN: 'SINGLESELECTDROPDOWN',
  MULTISELECTDROPDOWN: 'MULTISELECTDROPDOWN',
  NUMBER: 'NUMBER',
  PHONENUMBER: 'PHONENUMBER',
  DECIMALNUMBER: 'DECIMALNUMBER',
  MULTIINPUTTEXT: 'MULTI_INPUT_TEXT',
  ADDRESS: 'ADDRESS',
};

export const VALIDATION_RULES = {
  REQUIRED: 'REQUIRED',
  REGEX: 'REGEX',
  PERMISSIBLE: 'PERMISSIBLE',
  RANGE: 'RANGE',
  LENGTH: 'LENGTH',
};

export const UNDER_WRITING_STORE = {
  NAME: 'Under Writing Store',
  ANONYMOUS_ACTION: 'underwriting',
  LOADING_FETCH_UW_APPLICATION_QUESTION: 'LOADING_FETCH_UW_APPLICATION_QUESTION',
  LOADING_FETCH_UW_LOCATION_QUESTION: 'LOADING_FETCH_UW_LOCATION_QUESTION',
  LOADING_FETCH_ADDITIONAL_INTEREST_QUESTION: 'LOADING_FETCH_ADDITIONAL_INTEREST_QUESTION',
  LOADING_FETCH_UW_STATEMENT: 'LOADING_FETCH_UW_STATEMENT',
  SET_UW_APPLICATION_QUESTIONS: 'SET_UW_APPLICATION_QUESTIONS',
  SET_UW_LOCATION_QUESTIONS: 'SET_UW_LOCATION_QUESTIONS',
  SET_UW_ADDITIONAL_INTEREST_QUESTIONS: 'SET_UW_ADDITIONAL_INTEREST_QUESTIONS',
  SET_ERROR_APPLICATION_UW_QUESTIONS: 'SET_ERROR_APPLICATION_UW_QUESTIONS',
  SET_ERROR_LOCATION_UW_QUESTIONS: 'SET_ERROR_LOCATION_UW_QUESTIONS',
  SET_ERROR_ADDITIONAL_INTEREST_UW_QUESTIONS: 'SET_ERROR_ADDITIONAL_INTEREST_UW_QUESTIONS',
  CLEAR_UNDERWRITING: 'CLEAR_UNDERWRITING',
  FETCH_UNDERWRITING_STATEMENT: 'FETCH_UNDERWRITING_STATEMENT',
  SET_UW_QUESTION_FORM: 'SET_UW_QUESTION_FORM',
  UW_STATEMENT_ERROR: 'UW_STATEMENT_ERROR',
  UPDATE_UW_STORE_BY_KEY: 'UPDATE_UW_STORE_BY_KEY',
};

export const UNDERWRITING_STEP_KEYS = {
  APPLICATION: 'application',
  LOCATIONS: 'locations',
  ADDITIONAL_INTEREST: 'additionalInterest',
  UW_DECLARATION_QUESTION: 'underWritingDeclarationQuestion',
} as const;

export const PLACEHOLDER = {
  SELECT_PLACEHOLDER: 'Please select an option',
};

export const ACCORDION_TITLE = {
  [UNDERWRITING_STEP_KEYS.APPLICATION]: 'Application Questions',
  [UNDERWRITING_STEP_KEYS.LOCATIONS]: 'Location Questions',
  [UNDERWRITING_STEP_KEYS.UW_DECLARATION_QUESTION]: 'Statements and Declarations',
  [UNDERWRITING_STEP_KEYS.ADDITIONAL_INTEREST]: 'Additional Interest Questions',
};

export const UW_ERROR_MESSAGES = {
  COMMON: 'Error in fetching Underwriting Questions',
  GENERAL: 'Error in fetching General Underwriting Questions',
  LOCATION: 'Error in fetching Location Underwriting Questions',
  ADDITIONAL_INTEREST: 'Error in fetching Additional Interest Underwriting Questions',
};

export const UW_STORE_KEYS: { [key: string]: keyof IUnderWritingQuestionsStore } = {
  GENERAL_QUESTION_DATA: 'antdGeneralQuestionData',
  LOCATION_QUESTION_DATA: 'antdLocationQuestionData',
  ADDITIONAL_INTEREST_QUESTION_DATA: 'antdAdditionalInterestQuestionData',
  FORM_VALUES: 'formValues',
  CURRENT_FIELD_NAME: 'currentFieldName',
};

export const GA_CATEGORY = {
  UNDERWRITING_TAB_ACCORDION_HEADER: 'underWritingTab_accordionHeader',
  UNDERWRITING_TAB_DELETE_CARRIER_MODAL: 'underWritingTab_deleteCarrierModal',
  UNDERWRITING_TAB: 'underWritingTab',
};

export const GA_ACTION = {
  ANSWER_NO_TO_ALL_BTN_CLICK: 'answerNoToAllBtn_click',
  DEFAULT_ANSWER_BTN_CLICK: 'defaultAnswerBtn_click',
  UNDERWRITING_TAB_OPEN: 'underWritingTab_open',
};

export const GA_LABEL = {
  ANSWER_NO_TO_ALL: 'Answer no to all button click',
  APPLY_DEFAULT_ANSWER: 'Apply Default answer button click',
  UNDERWRITING_TAB_OPEN: 'UnderwritingTab is opened',
};

export const LOCATION_QUESTIONS_SUPPORTED_CARRIERS_FOR_WC = [
  Carrier.TRAVELERS,
  Carrier.CNA,
  Carrier.ACCIDENTFUND,
  Carrier.COMPWEST,
  Carrier.REPUBLIC_INDEMNITY,
];
