import styled from 'styled-components/macro';

import Accordion from '../../ui-core/V2/accordion/accordion';

export const AccordionStyled = styled(Accordion)`
  .ant-collapse-header {
    div.ant-collapse-expand-icon {
      span.anticon-right {
        font-size: ${(p) => p.theme.typography?.fontSize?.mediumLarge};
      }
    }
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  box-shadow: 0px 5px 8px ${(p) => p.theme.colors.grayscale.blackOpacity};
`;
