import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { errorV2 } from '../../ui-core/Notification';
import { OWNER_OFFICER_DETAILS_MODAL_MESSAGE, OWNER_OFFICER_DETAILS_MODAL_STORE_META_DATA } from './constants';
import { IOwnerDetailsModalStore } from './interface';
import { autoFillOwnerOfficerDetailsModalUtil } from './utils';

export const useOwnerOfficerDetailsModalStore = create<IOwnerDetailsModalStore>()(
  devtools(
    (set, get) => ({
      ownersOfficersTitleOptions: {},
      ownerOfficerDetailsModalForm: undefined,
      questionElements: [],
      editedOwnerOfficerDetails: undefined,
      ownerOfficerModalLoading: false,

      updateOwnerOfficerDetailsModalStoreByKey: async (storeKey: keyof IOwnerDetailsModalStore, value: any) => {
        set(
          () => ({ [storeKey]: value }),
          false,
          OWNER_OFFICER_DETAILS_MODAL_STORE_META_DATA.UPDATE_OWNER_OFFICER_MODAL_STORE_BY_KEY,
        );
      },

      autoFillOwnerOfficerDetailsModal: async () => {
        try {
          await autoFillOwnerOfficerDetailsModalUtil();
        } catch (error: any) {
          errorV2(error?.[0]?.errorMessage || OWNER_OFFICER_DETAILS_MODAL_MESSAGE.FAILED_TO_POPULATE_DATA);
        }
      },

      validateOwnerOfficerDetails: async () => {
        const { ownerOfficerDetailsModalForm } = get();
        try {
          await ownerOfficerDetailsModalForm?.validateFields();
          return true;
        } catch (error) {
          return false;
        }
      },

      clearOwnerOfficerDetailsModal: () => {
        const { ownerOfficerDetailsModalForm } = get();
        ownerOfficerDetailsModalForm?.resetFields();
        set(
          {
            questionElements: [],
            ownerOfficerDetailsModalForm: undefined,
            editedOwnerOfficerDetails: undefined,
            ownerOfficerModalLoading: false,
          },
          false,
          OWNER_OFFICER_DETAILS_MODAL_STORE_META_DATA.CLEAR_OWNER_OFFICER_MODAL_STORE,
        );
      },
    }),

    {
      anonymousActionType: 'OWNER_OFFICER_MODAL_ANONYMOUS_ACTION',
      name: 'Owner Officer Modal Store',
    },
  ),
);
