import { IAppetiteCarrierListContainerStore } from './interface';

export const ACTION_TYPE = {
  CLEAR_APPETITE_CARRIER_LIST_CONTAINER_STORE: 'CLEAR_APPETITE_CARRIER_LIST_CONTAINER_STORE',
  SET_IN_APPETITE_CHECKBOX_GROUP: 'SET_IN_APPETITE_CHECKBOX_GROUP',
  SET_SELECTED_CARRIERS: 'SET_SELECTED_CARRIERS',
  SET_NOT_IN_APPETITE_CHECKBOX_GROUP: 'SET_NOT_IN_APPETITE_CHECKBOX_GROUP',
  ANONYMOUS_ACTION_NAME: 'APPETITE_CARRIER_LIST_CONTAINER_STORE_ANNOYMOUS_ACTION',
  SET_APPETITE_LOADING: 'SET_APPETITE_LOADING',
  STORE_NAME: 'APPETITE_CARRIER_LIST_CONTAINER_STORE',
  SET_APPETITE: 'SET_APPETITE',
  SET_SET_APPETITE_ERROR: 'SET_SET_APPETITE_ERROR',
  UPDATE_APPETITE_CARRIER_LIST_CONTAINER_STORE_BY_KEY: 'UPDATE_APPETITE_CARRIER_LIST_CONTAINER_STORE_BY_KEY',
};

export const APPETITE_CARRIER_LIST_CONTAINER_STORE_META_DATA: {
  [k: string]: keyof IAppetiteCarrierListContainerStore;
} = {
  APPETITE_DETAILS_STORE: 'appetiteDetailsStore',
};

export const ACCORD_CARRIER_LIST_STORE_KEYS: { [k: string]: keyof IAppetiteCarrierListContainerStore } = {
  IN_APPETITE_CHECKBOX_GROUP: 'inAppetiteCheckboxGroup',
  NOT_IN_APPETITE_CHECKBOX_GROUP: 'notInAppetiteCheckboxGroup',
  SELECTED_CARRIERS: 'selectedCarriers',
};
