import { ApplicationFieldPathEnum } from '@coverforce-platform/cf-common-types';

export const QUESTION_KEYS = {
  LOSS_DATE: 'lossDate',
  CLAIM_DATE: 'claimDate',
  LOSS_TYPE: 'lossType',
  CLAIM_STATUS: 'claimStatus',
  TOTAL_PAID_AMOUNT: 'totalPaidAmount',
  TOTAL_RESERVED_AMOUNT: 'totalReservedAmount',
  LOSS_DESCRIPTION: 'lossDescription',
  STATE: 'state',
  STEPS_TAKEN_TO_AVOID_FUTURE_CLAIMS: 'stepsTakenToAvoidFutureClaims',
};

export const PAST_POLICY_LOSS_STORE_ACTION = {
  CLEAR_POLICY_LOSS_MODAL_STORE: 'CLEAR_PAST_POLICY_LOSS_STORE',
  ANONYMOUS_ACTION: 'PAST_POLICY_LOSS_DEFAULT_ACTION',
  STORE_NAME: 'Past Policy Loss',
  SET_POLICY_LOSS_DETAILS: 'SET_POLICY_LOSS_DETAILS',
  AUTO_FILL_POLICY_LOSS_DETAILS: 'AUTO_FILL_POLICY_LOSS_DETAILS',
  SET_ERROR_IN_PAST_POLICY_LOSS: 'SET_ERROR_IN_PAST_POLICY_LOSS',
  DELETE_PAST_POLICY_LOSS: 'DELETE_PAST_POLICY_LOSS',
  ADD_PAST_POLICY_LOSS: 'ADD_PAST_POLICY_LOSS',
  UPDATE_PAST_POLICY_LOSS: 'UPDATE_PAST_POLICY_LOSS',
  POLICY_LOSS_LOADING: 'POLICY_LOSS_LOADING',
};

export const POLICY_KEYS_MAPPING = {
  [QUESTION_KEYS.LOSS_DATE]: 'loss date',
  [QUESTION_KEYS.CLAIM_DATE]: 'claim date',
  [QUESTION_KEYS.LOSS_DESCRIPTION]: 'loss description',
  [QUESTION_KEYS.LOSS_TYPE]: 'loss type',
  [QUESTION_KEYS.STATE]: 'state',
  [QUESTION_KEYS.CLAIM_STATUS]: 'claim status',
  [QUESTION_KEYS.TOTAL_PAID_AMOUNT]: 'total paid amount',
  [QUESTION_KEYS.TOTAL_RESERVED_AMOUNT]: 'total reserved amount',
};

export const GA_CATEGORY = {
  PAST_POLICY_LOSS_TAB_FOOTER: 'pastPolicyLossTab_footer',
  PAST_POLICY_LOSS_TAB_CARD: 'pastPolicyLossTab_card',
  PAST_POLICY_LOSS_TAB_LOSS_DETAIL_MODAL: 'pastPolicyLossTab_lossDetailModal',
};

export const GA_ACTION = {
  ADD_LOSS_DETAIL_BTN_CLICK: 'addLossDetailBtn_click',
  EDIT_LOSS_DETAIL_BTN_CLICK: 'editLossDetailBtn_click',
  DELETE_LOSS_DETAIL_BTN_CLICK: 'deleteLossDetailBtn_click',
  CLOSE_LOSS_DETAIL_MODAL_BTN_CLICK: 'closeLossDetailModalBtn_click',
};

export const GA_LABEL = {
  ADD_LOSS_DETAIL: 'Add loss detail',
  CLOSE_MODAL: 'Close past loss modal',
  EDIT_LOSS_DETAIL: 'Edit loss detail',
  DELETE_LOSS_DETAIL: 'Delete loss detail',
};
export const PAST_POLICY_LOSS_FIELDS_PATH = [
  ApplicationFieldPathEnum.PAST_POLICY_LOSS_CLAIM_DATE,
  ApplicationFieldPathEnum.PAST_POLICY_LOSS_CLAIM_STATUS,
  ApplicationFieldPathEnum.PAST_POLICY_LOSS_DATE,
  ApplicationFieldPathEnum.PAST_POLICY_LOSS_DESCRIPTION,
  ApplicationFieldPathEnum.PAST_POLICY_LOSS_LOCATION_STATE,
  ApplicationFieldPathEnum.PAST_POLICY_LOSS_POLICY_TYPE,
  ApplicationFieldPathEnum.PAST_POLICY_LOSS_TOTAL_PAID_AMT,
  ApplicationFieldPathEnum.PAST_POLICY_LOSS_TOTAL_RESERVED_AMT,
  ApplicationFieldPathEnum.PAST_POLICY_LOSS_TYPE,
  ApplicationFieldPathEnum.PAST_POLICY_LOSS_STEPS_TAKEN_TO_AVOID_FUTURE_CLAIMS,
];
