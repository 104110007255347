import { Form } from 'antd';
import styled from 'styled-components/macro';

const CFFormItemStyled = styled(Form.Item)`
  .ant-form-item-required::before {
    margin-bottom: 6px;
  }
`;

export default CFFormItemStyled;
