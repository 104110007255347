import styled from 'styled-components/macro';

import Anchor from '../../ui-core/V2/anchor/anchor';
import CFDropDown from '../../ui-core/V2/cfDropdown/cfDropdown';
import { NavItemStyleProps, TitleStyleProps } from './interface';

const getNavItemContainerStyledContent = (p: any) => `
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${p.padding || '0px 10px'};
  font-weight: ${p.theme.typography.fontWeight.medium};
  width: ${p.width || 'fit-content'};
  font-size: 15px;
  height: 100%;
  cursor: pointer;
  transition: 0.3s color;

  @media only screen and (min-width: 1021px) and (max-width: 1280px) {
    max-width: fit-content;
    // padding: 0 10px;
    padding: 0 2px;
    font-size: ${p.theme.typography.fontSize.mini};
  }

  color: ${p.theme?.component?.navBar?.textColor} !important;

  @media (max-width: 1020px) {
    height: 50px;
    margin: 5px 0px;
    align-items: flex-start;
  }
`;

export const NavItemContainerAnchorStyled = styled(Anchor)<NavItemStyleProps>`
  ${(p) => `${getNavItemContainerStyledContent(p)}`}
`;

export const NavItemContainerDivStyled = styled.div<NavItemStyleProps>`
  ${(p) => `${getNavItemContainerStyledContent(p)}`}
`;

export const NavItemContainerStyled = styled.div`
  height: 100%;
`;

export const NavItemContainer = styled.div`
  display: flex;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1020px) {
      align-items: flex-start;
    }
  }
`;

export const IconWrapperStyled = styled.div`
  font-size: 21px;
  height: 24px;
`;

export const NavItemWrapperStyled = styled.div<{ isUser?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1020px) {
    padding-left: 5px;
    width: unset;
  }
`;

export const TitleStyled = styled.div<TitleStyleProps>`
  margin-left: 15px;
  opacity: ${(p) => (p.isFocus ? 1 : 0.75)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;

export const BorderBottomStyled = styled.div`
  width: 100%;
  @media (max-width: 1020px) {
    width: 80%;
  }
  height: 6px;
  background-color: ${(p: any) =>
    p?.theme?.component?.navBar?.isNavBarBackgroundLight
      ? p?.theme?.colorScheme?.primary
      : p.theme.colors.grayscale.white};
  border-radius: 38px 38px 0px 0px;
  position: absolute;
  bottom: 0;
`;

export const CFDropDownStyled = styled(CFDropDown)`
  display: flex;
  justify-content: center;
`;
