import { ExportOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';

import Anchor from '../../ui-core/V2/anchor/anchor';

export const IndustryCodeLinkStyled = styled(Anchor)`
  display: flex;
  align-items: center;
  text-decoration: underline;
  width: fit-content;

  :hover,
  :focus {
    text-decoration: underline;
  }
`;

export const IndustryCodeIconStyled = styled(ExportOutlined)`
  margin-left: 4px;
`;
