import { IFormConfig } from '../../../ui-core/V2/cfFormDrawer/interface';
import { Address } from './interface';

export const initialAddressValues: Address = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
};

export const addressLine1Error = 'Please enter address.';

export const locationConfig: IFormConfig[] = [
  {
    dataIndex: 'addressLine2',
    label: 'Address Line 2',
    placeholder: 'APT, Unit, etc.',
  },
  {
    dataIndex: 'city',
    label: 'City',
    placeholder: 'City',
    rules: [
      {
        required: true,
        validator: (_, value) => {
          if (!value?.trim()) {
            return Promise.reject(new Error('Please enter city name.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    dataIndex: 'state',
    label: 'State',
    placeholder: 'State',
    rules: [
      {
        required: true,
        validator: (_, value) => {
          if (!value?.trim()) {
            return Promise.reject(new Error('Please enter state name.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    dataIndex: 'zip',
    label: 'Zip Code',
    placeholder: 'Zip Code',
    rules: [
      {
        required: true,
        validator: (_, value) => {
          if (!value?.trim()) {
            return Promise.reject(new Error('Please enter zip code.'));
          }
          return Promise.resolve();
        },
      },
    ],
  },
];

export const DEBOUNCE_TIME = 300;

export const LocationSearchCountry = 'us';

export const STATUSES = {
  OK: 'OK',
};

export const LOCATIONV2_ACTIONS = {
  SET_SHOW_LOCATION_ERROR: 'setShowLocationInputError',
  SET_ADDRESS_DATA: 'setAddressData',
  CLEAR_LOCATION: 'clearLocationInput',
  ANONYMOUS_ACTION_TYPE: 'LocationInputV2',
  STORE_NAME: 'Location Input V2 store',
};
