import { ArrowRightOutlined } from '@ant-design/icons';
import { ICarrierInfo, INaicsCodeInfo } from '@coverforce-platform/cf-common-api-model';
import { Carrier, IndustryCodeCategory, PolicyType } from '@coverforce-platform/cf-common-types';
import { TableColumnProps, Tooltip } from 'antd';

import { useApplicationStore } from '../../pages/v2/application/store';
import { gte, isEmpty } from '../../utils/lodash';
import {
  BLANK_CELLS,
  ENABLED_INDUSTRY_CARRIERS_BY_POLICY,
  SECONDARY_CODE_IDENTIFIER,
  SECONDARY_CODE_KEY,
} from './constants';
import { CarrierIndustrySelection, IIndustryCode, INaicsSicMapping } from './interface';
import { useIndustrySelectionStore } from './store';
import { BlankCell, QuestionCircleOutlinedStyled, SpanStyled } from './styles';

export const parseToNaicsSicMapping = (naicsCodeInfos: INaicsCodeInfo[]): INaicsSicMapping[] => {
  if (!Array.isArray(naicsCodeInfos)) {
    return [];
  }
  const naicsSicMapping: INaicsSicMapping[] = [];

  naicsCodeInfos?.forEach((naicsCodeInfo) => {
    const naicsInfo = {
      NAICS_CODE: naicsCodeInfo.naicsCode,
      NAICS_CODE_DESCRIPTION: naicsCodeInfo.descriptionText,
      NAICS_EXTENDED_ID: naicsCodeInfo.naicsExtendedId,
    };
    naicsCodeInfo.industryCodeMappingInfo?.forEach((industryCodeMappingInfo) => {
      naicsSicMapping.push({
        ...naicsInfo,
        [industryCodeMappingInfo.codeType]: industryCodeMappingInfo.codeId,
        [`${industryCodeMappingInfo.codeType}_DESCRIPTION`]: industryCodeMappingInfo.codeDescription,
      } as INaicsSicMapping);
    });
  });

  return naicsSicMapping;
};

export const getUpdatedCarrierIndustrySelections = ({
  carrierIndustrySelections,
  industryOptions,
  supportedFilteredCarriers,
  type,
}: {
  carrierIndustrySelections: CarrierIndustrySelection[];
  industryOptions: { [k: string]: { [key: string]: IIndustryCode[] } };
  supportedFilteredCarriers: Carrier[];
  type: IndustryCodeCategory;
}) => {
  return supportedFilteredCarriers.map((carrier) => {
    const carrierIndustrySelection = carrierIndustrySelections.find(
      (industrySelection) => industrySelection.carrier === carrier,
    );
    const industryOptionsForSelectedCarrier = industryOptions?.[type]?.[carrier];
    const selectedIndustryCode = industryOptionsForSelectedCarrier?.find(
      (industryOption) =>
        industryOption.code === carrierIndustrySelection?.code &&
        industryOption.description === carrierIndustrySelection?.description,
    );
    return { ...carrierIndustrySelection, ...selectedIndustryCode, carrier };
  });
};

const renderIndustryCode = (value: string) => {
  const splitValues = value?.split(',');
  return (
    <Tooltip placement='bottom' title={value}>
      {splitValues?.slice(0, 5)?.join(',')}
      {splitValues?.length > 5 && '...'}
    </Tooltip>
  );
};

export const getIndustrySelectionTableColumns = (showBlankCell: boolean): Array<TableColumnProps<IIndustryCode>> => [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    sorter: (a, b) => {
      return gte(Number(a.code?.replace('_', '')), Number(b.code?.replace('_', ''))) ? 1 : -1;
    },
    render: (value, record) => {
      if (BLANK_CELLS.includes(value)) {
        return showBlankCell && BLANK_CELLS.includes(record.code) ? <BlankCell /> : '--';
      }
      return value;
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    sorter: (a, b) => {
      return gte(a.description, b.description) ? 1 : -1;
    },
    render: (value, record) => {
      if (BLANK_CELLS.includes(value)) {
        return showBlankCell && BLANK_CELLS.includes(record.code) ? (
          <BlankCell minWidth='125px'>
            Please Select <ArrowRightOutlined />
          </BlankCell>
        ) : (
          '--'
        );
      }
      return value;
    },
  },
  {
    title: 'SIC',
    dataIndex: 'sic',
    key: 'sic',
    sorter: (a, b) => {
      return gte(a.sic, b.sic) ? 1 : -1;
    },
    render: (value, record) => {
      if (BLANK_CELLS.includes(value)) {
        return showBlankCell && BLANK_CELLS.includes(record.code) ? <BlankCell /> : '--';
      }
      return renderIndustryCode(value);
    },
  },
  {
    title: 'NAICS',
    dataIndex: 'naics',
    key: 'naics',
    sorter: (a, b) => {
      return gte(a.naics, b.naics) ? 1 : -1;
    },
    render: (value, record) => {
      if (BLANK_CELLS.includes(value)) {
        return showBlankCell && BLANK_CELLS.includes(record.code) ? <BlankCell /> : '--';
      }
      return renderIndustryCode(value);
    },
  },
];

export const getTooltipForAdditionalCode = (carrierShortName: string) => {
  return `${carrierShortName} mandates the selection of an additional class code for the selected industry. Kindly choose the class code from the available codes.`;
};

export const getIndustrySelectionWithCarrierShortName = (carriers: ICarrierInfo[]) => {
  return [
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      sorter: (a: any, b: any) => {
        return gte(a.carrierId, b.carrierId) ? 1 : -1;
      },
      render: (value: string) => {
        const carrierShortName = carriers.find(
          (carrier: ICarrierInfo) => carrier.carrierId === getCarrierId(value),
        )?.carrierShortName;
        if (value.includes(SECONDARY_CODE_IDENTIFIER)) {
          return (
            <>
              Additional Class Code{' '}
              {carrierShortName && (
                <Tooltip placement='top' title={getTooltipForAdditionalCode(carrierShortName)}>
                  <SpanStyled>
                    <QuestionCircleOutlinedStyled />
                  </SpanStyled>
                </Tooltip>
              )}
            </>
          );
        }
        return carrierShortName;
      },
      width: '205px',
    },
    ...getIndustrySelectionTableColumns(true),
  ];
};

export const getFilteredIndustrySelectionOptions = ({
  industryOptions,
  industrySearchText,
}: {
  industryOptions: IIndustryCode[];
  industrySearchText: string;
}) => {
  // list of search terms separated by space
  const searchList: string[] = industrySearchText.split(' ').filter((item) => item.trim().length > 0);

  return industryOptions
    ?.map((option) => {
      const searchString = `${option.code}
            ${option.description}
            ${option.sic}
            ${option.naics}}`.toLowerCase();

      // the number of search terms that match, the higher the rank is shown first
      const rank: number = searchList.filter((searchItem) => searchString.includes(searchItem.toLowerCase())).length;
      return { ...option, rank };
    })
    .filter((option) => option.rank > 0)
    .sort((a, b) => b.rank - a.rank);
};

export const getUpdatedIndustrySelections = ({
  updatedCarriers,
  carrierIndustrySelections,
}: {
  updatedCarriers: ICarrierInfo[];
  carrierIndustrySelections: CarrierIndustrySelection[];
}) => {
  const updatedCarrierIds: string[] = updatedCarriers?.map((carrier) => carrier.carrierId);
  const removedCarrier = carrierIndustrySelections?.filter(
    (industrySelection) => industrySelection.carrier && !updatedCarrierIds?.includes(industrySelection.carrier),
  );
  const removedCarrierIndex = carrierIndustrySelections.findIndex(
    (industrySelection) => industrySelection.carrier === removedCarrier[0].carrier,
  );
  const industrySelections = [...carrierIndustrySelections];
  industrySelections.splice(removedCarrierIndex, 1);
  return industrySelections;
};

export const addIndustrySelectionCode = ({
  industryCode,
  carrier,
  carrierIndustrySelections,
}: {
  industryCode: IIndustryCode;
  carrier: Carrier;
  carrierIndustrySelections: CarrierIndustrySelection[];
}) => {
  return carrierIndustrySelections?.map((carrierIndustrySelection) => {
    if (carrierIndustrySelection.carrier === carrier) {
      return { ...carrierIndustrySelection, ...industryCode, carrier };
    } else if (carrierIndustrySelection.carrier === getCarrierId(carrier)) {
      return { ...carrierIndustrySelection, children: [{ ...industryCode, carrier }] };
    }
    return carrierIndustrySelection;
  });
};

export const getCarrierId = (carrier: string) => carrier?.split('#')?.[0] || '';

export const getCarrierName = (carrier: string) => {
  const { selectedCarriersInfo } = useApplicationStore.getState();
  const splitCarrierName = carrier?.split('#');
  const carrierInfo = selectedCarriersInfo?.find((item) => item?.carrierId === splitCarrierName?.[0]);
  if (!carrierInfo) {
    return '';
  }
  return `${carrierInfo.carrierShortName} ${splitCarrierName?.[1] || ''}`.trim();
};

export const getIndustrySelectionsForAutoFill = () => {
  const { applicationData, policyType = '', selectedCarriersInfo } = useApplicationStore.getState();
  const { industryOptions } = useIndustrySelectionStore.getState();
  if (!applicationData) {
    return [];
  }

  const supportedFilteredCarriers = selectedCarriersInfo
    ?.map((carrier) => carrier.carrierId)
    ?.filter((carrier) => ENABLED_INDUSTRY_CARRIERS_BY_POLICY(policyType as PolicyType).includes(carrier));

  const carrierIndustryCodes = applicationData.basicBusinessDetails?.carrierIndustryCodes;

  return supportedFilteredCarriers?.map((carrier) => {
    const industryCode = carrierIndustryCodes?.find((code) => code.carrierId === carrier);
    const primaryIndustryOptionsForSelectedCarrier = industryOptions?.[IndustryCodeCategory.PRIMARY]?.[carrier];
    const selectedIndustryCode = primaryIndustryOptionsForSelectedCarrier?.find(
      (industryOption) =>
        industryCode &&
        industryOption.code === industryCode.industryCode &&
        (industryOption.description || '') === (industryCode.industryCodeDescription || ''),
    );

    if (selectedIndustryCode) {
      // Check secondary code is available or not
      let propsForSecondaryClassCode = {};
      if (industryCode?.additionalIndustryCodes) {
        const secondaryIndustryOptionsForSelectedCarrier = industryOptions?.[IndustryCodeCategory.SECONDARY]?.[carrier];
        const selectedSecondaryIndustryCode = secondaryIndustryOptionsForSelectedCarrier?.find(
          (industryOption) =>
            industryCode &&
            industryOption.code === industryCode?.additionalIndustryCodes?.[0]?.industryCode &&
            (industryOption.description || '') ===
              (industryCode?.additionalIndustryCodes?.[0]?.industryCodeDescription || ''),
        );
        if (selectedSecondaryIndustryCode) {
          propsForSecondaryClassCode = {
            key: SECONDARY_CODE_KEY,
            children: [
              {
                ...selectedSecondaryIndustryCode,
                carrier: `${carrier}${SECONDARY_CODE_IDENTIFIER}`,
                code: industryCode?.additionalIndustryCodes?.[0]?.industryCode,
                description: industryCode?.additionalIndustryCodes?.[0]?.industryCodeDescription,
              },
            ],
          };
        }
      }
      return {
        ...selectedIndustryCode,
        ...propsForSecondaryClassCode,
        carrier,
        code: industryCode?.industryCode,
        description: industryCode?.industryCodeDescription,
      };
    } else {
      return {
        carrier,
        code: undefined,
        description: undefined,
      };
    }
  });
};

export const isValidCarrierIndustrySelectionsDetails = () => {
  const { carrierIndustrySelections } = useIndustrySelectionStore.getState();
  return carrierIndustrySelections.some(
    (carrierIndustrySelection) =>
      // checking main code is available or not
      isEmpty(carrierIndustrySelection.code) ||
      // if children is available that means, it has secondary class codes, so if length is gt 0 then check it has code or not, if children not found or length 0 then ignore this condition
      (carrierIndustrySelection?.children && carrierIndustrySelection?.children.length > 0
        ? carrierIndustrySelection.children.some((carrierIndustry) => isEmpty(carrierIndustry.code))
        : false),
  );
};

export const autoFillIndustrySelection = () => {
  const { applicationData } = useApplicationStore.getState();
  const { industrySelectionForm, naicsCodes, setIndustrySearchText, updateCarrierIndustrySelections } =
    useIndustrySelectionStore.getState();
  if (applicationData?.basicBusinessDetails) {
    const naicsCodeInfo = naicsCodes.find(
      (naicsCode) => applicationData.basicBusinessDetails?.naicsExtendedId === naicsCode.value?.split('-')?.[1]?.trim(),
    );
    const naicsCode = { naicsCode: naicsCodeInfo && naicsCodeInfo.value };
    industrySelectionForm?.setFieldsValue(naicsCode);
    setIndustrySearchText(naicsCodeInfo?.code || '');

    const industrySelections = getIndustrySelectionsForAutoFill();
    updateCarrierIndustrySelections(industrySelections);
  }
};
