import styled from 'styled-components/macro';

import CFModal from '../../../ui-core/V2/cfModal/cfModal';

export const CFModalStyled = styled(CFModal)`
  .ant-modal-content {
    max-height: 85vh !important;
  }
  height: 500px;
`;

export const PremiumBreakUpFieldWrapperStyled = styled.div`
  margin: 5px 0;
`;

export const PremiumBreakUpListWrapperStyled = styled.ul`
  margin: 5px 0;
`;

export const PremiumBreakUpLabelStyled = styled.span`
  font-weight: ${(p) => p.theme.typography.fontWeight.mediumBold};
`;

export const PremiumBreakUpPayableAmountStyled = styled.h3`
  margin-bottom: 10px;
  font-weight: ${(p) => p.theme.typography.fontWeight.mediumBold};
`;

export const PremiumBreakUpPayableAmountValueStyled = styled.span`
  font-weight: ${(p) => p.theme.typography.fontWeight.regular};
`;

export const PremiumBreakUpWrapperStyled = styled.div``;

export const AdditionalChargesListItemStyled = styled.li``;
