import { Select } from 'antd';
import styled from 'styled-components/macro';

export const StyledCFSelect = styled(Select).attrs(() => '')`
  .ant-select-selector {
    box-shadow: none;
    // border-color: ${(p) => p.theme.colors.grayscale.grayBorder} !important;

    // :hover {
    //   border-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primaryHover : 'default')} !important;
    // }

    :focus {
      border-color: ${(p) => p.theme?.colorScheme?.primaryHover} !important;
      box-shadow: 0 0 0 2px ${(p) => p.theme.colors.common.inputBoxShadow} !important;
    }
  }

  // &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  //   border-color: ${(p) => p.theme.colors.grayscale.grayBorder} !important;
  // }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: ${(p) => p?.theme?.colorScheme?.primary};
  }
`;
