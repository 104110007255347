import { INDUSTRY_CODE_LINK } from '../../constants/industryConstants';
import { ANCHOR_TARGET } from '../../ui-core/V2/anchor/constants';
import { IndustryCodeIconStyled, IndustryCodeLinkStyled } from './styles';

export const filterOptionNaicsCodes = (input: string, option: any) => {
  const { label, tags } = option || {};
  // If string includes input
  const labelFilter: boolean = label?.toLowerCase()?.includes(input?.toLowerCase());
  // if tag is present for an input
  const tagMatch = tags?.find((tag: string) => tag?.toLowerCase()?.includes(input?.toLowerCase()));

  return labelFilter || tagMatch;
};

export const getIndustryCodeLink = ({ naicsCode, sicCode }: { naicsCode?: string; sicCode?: string }) => {
  const industryCode = naicsCode || sicCode;
  const industryCodeLink = naicsCode
    ? `${INDUSTRY_CODE_LINK.NAICS}${industryCode}`
    : `${INDUSTRY_CODE_LINK.SIC}${industryCode}`;

  return (
    <IndustryCodeLinkStyled id='link_industry_code' href={industryCodeLink} target={ANCHOR_TARGET.BLANK}>
      More info on {industryCode}
      <IndustryCodeIconStyled />
    </IndustryCodeLinkStyled>
  );
};
