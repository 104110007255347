import { QuestionCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import styled from 'styled-components/macro';

import CFButton from '../../ui-core/V2/cfButton/cfButton';
import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';
import CFModal from '../../ui-core/V2/cfModal/cfModal';
import CFTable from '../../ui-core/V2/cfTable/cfTable';

export const CardStyled = styled(Card)`
  margin: 30px 0;
  box-shadow: ${(p) => p.theme.custom.boxShadow.black};
`;

export const BlankCell = styled.div<{ minWidth?: string }>`
  background: ${(p) => p.theme.custom.blankCell.bg};
  color: ${(p) => p.theme.custom.blankCell.main};
  height: 25px;
  width: 100%;
  min-width: ${(p) => p.minWidth};
  border-radius: 6px;
  font-size: 13px;
  padding: 0px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CFIndustrySelectionModal = styled(CFModal)`
  .ant-table-wrapper {
    padding: 0px 16px 0px 16px;
  }
  .ant-modal-header {
    padding: 16px 24px 0px 24px;
    border-bottom: 0px;
  }
  .ant-modal-body {
    padding: 0px;
  }
`;

export const FormItemWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.colors.primary.borderLight};
  margin-bottom: 20px;
`;

export const IndustrySelectionWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  flex: 1;
`;

export const CFFormItemStyled = styled(CFFormItem)`
  flex: 1;
  border-bottom: none !important;
  .ant-form-item-label {
    display: flex;
    label {
      margin-right: 0px !important;
    }
  }
`;

export const IndustryLinkWrapperStyled = styled.div`
  margin: -20px 0px 20px 17%;
`;

export const CFButtonStyled = styled(CFButton)`
  margin-left: 20px;
`;

export const EmptyMessageStyled = styled.div`
  margin-bottom: 20px;
`;

export const CFTableStyled = styled(CFTable)`
  td.ant-table-cell {
    span.ant-table-row-indent {
      display: none;
    }
  }
  td:has(button.ant-table-row-expand-icon-expanded) {
    border: none;
  }
  td:has(.ant-table-row-expand-icon-expanded) ~ td {
    border: none;
  }
  .ant-table-row-expand-icon {
    display: none;
  }
  tr.ant-table-row-level-1 {
    td.ant-table-cell {
      #btn_delete_carrier {
        display: none;
      }
    }
  }
`;

export const SpanStyled = styled.span`
  cursor: help;
`;

export const QuestionCircleOutlinedStyled = styled(QuestionCircleOutlined)`
  color: ${(p) => p.theme.colors.grayscale.blackOpacityLight};
`;
