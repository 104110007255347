export const APPLICATIONS_STORE_META_DATA = {
  STORE_NAME: 'Applications Store',
  ANONYMOUS_ACTION_NAME: 'APPLICATIONS_STORE_ACTION',
  APPLICATIONS_SET_FILTERS: 'APPLICATIONS_SET_FILTERS',
  APPLICATIONS_LOADING_START: 'APPLICATIONS_LOADING_START',
  APPLICATIONS_LOADED: 'APPLICATIONS_LOADED',
  APPLICATIONS_ERROR: 'APPLICATIONS_ERROR',
  APPLICATIONS_LOADING_STOP: 'APPLICATIONS_LOADING_STOP',
  PUBLIC_API_CLIENTS_LOADING_START: 'PUBLIC_API_CLIENTS_LOADING_START',
  SET_PUBLIC_API_CLIENTS_BY_AGENCY: 'SET_PUBLIC_API_CLIENTS_BY_AGENCY',
  SET_PUBLIC_API_CLIENTS_BY_AGENCY_NETWORK: 'SET_PUBLIC_API_CLIENTS_BY_AGENCY_NETWORK',
  PUBLIC_API_CLIENTS_LOADING_STOP: 'PUBLIC_API_CLIENTS_LOADING_STOP',
  CLEAR_APPLICATIONS: 'CLEAR_APPLICATIONS',
  CLEAR_POLICY_TYPES: 'CLEAR_POLICY_TYPES',
};

export const TableActions = {
  PAGINATE: 'paginate',
  FILTER: 'filter',
  SORT: 'sort',
};

export const APPLICATION_TABLE_KEYS = {
  ACCOUNT_NAME: 'accountName',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  AGENCY_NAME: 'agencyName',
  EFFECTIVE_DATE: 'insuranceEffectiveDate',
  NETWORK_RELATIONSHIP_MANAGER_NAME: 'networkRelationshipManagerName',
} as const;

export const DISABLED_EDIT_COLUMNS = ['accountName'];

export const GA_CATEGORY = {
  APPLICATIONS_TABLE: 'applications_table',
  CLONE_APPLICATION_MODAL: 'applications_cloneApplicationModal',
  MARK_AS_BOUND_APPLICATION_MODAL: 'applications_markAsBoundApplicationModal',
  REASSIGN_APPLICATION_MODAL: 'applications_reAssignApplicationModal',
};

export const GA_ACTION = {
  EDIT_COLUMN_BTN_CLICK: 'editColumnBtn_click',
  VIEW_APPLICATION_BTN_CLICK: 'viewApplicationBtn_click',
  VIEW_APPLICATION_BY_ROW_CLICK: 'viewApplicationByRow_click',
  CLONE_APPLICATION_BTN_CLICK: 'cloneApplicationBtn_click',
  RE_ASSIGN_APPLICATION_BTN_CLICK: 'reAssignApplicationBtn_click',
  MARK_AS_BOUND_BTN_CLICK: 'markAsBoundBtn_click',
  SORT_BY_ACCOUNT_NAME: 'sortByAccountNameBtn_click',
  SORT_BY_CREATED_DATE: 'sortByCreatedDateBtn_click',
  SORT_BY_MODIFIED_DATE: 'sortByModifiedDateBtn_click',
  SORT_BY_EFFECTIVE_DATE: 'sortByEffectiveDateBtn_click',
  FILTER_BY_POLICY: 'filterByPolicyBtn_click',
  FILTER_BY_CHANNEL: 'filterByChannelBtn_click',
  FILTER_BY_STATUS: 'filterByStatusBtn_click',
  CLOSE_CLONE_APP_MODAL: 'closeCloneApplicationModalBtn_click',
  CLOSE_MARK_AS_BOUND_MODAL: 'closeMarkAsBoundAppModalBtn_click',
  CLOSE_RE_ASSIGN_APPLICATION_MODAL: 'closeReAssignApplicationModalBtn_click',
};

export const GA_LABEL = {
  EDIT_COLUMN: 'Edit column button click for hiding and showing columns',
  VIEW_APPLICATION_BTN: 'View application using menu item',
  VIEW_APPLICATION_BY_ROW: 'View application by row click',
  CLONE_APPLICATION: 'Clone application btn click using menu item',
  MARK_AS_BOUND_APPLICATION: 'Mark as bound application using menu item',
  RE_ASSIGN_APPLICATION: 'Reassign application using menu item',
  SORT_ACCOUNT_NAME: 'Sort data according to account name',
  SORT_CREATED_DATE: 'Sort data according to created date',
  SORT_MODIFIED_DATE: 'Sort data according to modified date',
  SORT_EFFECTIVE_DATE: 'Sort data according to effective date',
  FILTER_POLICY: 'Filter data according to policy',
  FILTER_CHANNEL: 'Filter data according to channel',
  FILTER_STATUS: 'Filter data according to status',
  CLONE_MODAL_CLOSE: 'Clone clone application modal',
  CLONE_MODAL_SUCCESS: 'Application clone successfully',
  MARK_AS_BOUND_MODAL_SUCCESS: 'Mark as bound application successfully threw modal',
  MARK_AS_BOUND_MODAL_CLOSE: 'Mark as bound application modal closed',
  RE_ASSIGN_APPLICATION_MODAL_CLOSE: 'Re-Assign application modal close',
  RE_ASSIGN_APPLICATION_MODAL_SUCCESS: 'Reassign application successfully threw modal',
};
export const CHANNEL_FILTER_CUSTOM_ALL_STORE_ENTRY = {
  TEXT: 'Online Stores (All)',
  VALUE: 'online_store_all',
};

export const APPLICATION_TABLE_FILTER_KEYS = ['policyType', 'agencyId', 'channel', 'applicationStatus', 'sortBy'];
