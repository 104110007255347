import { FEATURE_FLAG, SESSION_STORAGE_KEYS } from '../globalConstants';
import { getFromSessionStorage } from './getFromSessionStorage';
import { getGrowthbookFeatureValueByKey } from './growthbook';
import parseQueryString from './parseQueryString';

export const getSSOClientRedirectUrl = () => {
  const { search } = location;
  const queryParams = parseQueryString(search);
  const { clientApp = '', isLogout = false } = queryParams || {};

  const storageSSOClientLogoutUrl = getFromSessionStorage(SESSION_STORAGE_KEYS.SSO_LOGOUT_URL);
  const storageSSOClientRedirectUrl = getFromSessionStorage(SESSION_STORAGE_KEYS.SSO_REDIRECT_URL);
  const growthbookSSOClientRedirectUrlConfig = getGrowthbookFeatureValueByKey(FEATURE_FLAG.SSO_CLIENT_REDIRECT_URL);
  const growthbookSSOClientRedirectUrl = (growthbookSSOClientRedirectUrlConfig || {})[
    ((clientApp as string) || '').toLowerCase()
  ];
  if (isLogout) {
    return storageSSOClientLogoutUrl ? storageSSOClientLogoutUrl : growthbookSSOClientRedirectUrl;
  } else {
    return storageSSOClientRedirectUrl ? storageSSOClientRedirectUrl : growthbookSSOClientRedirectUrl;
  }
};
