import styled from 'styled-components/macro';

export const FooterWrapperStyled = styled.div`
  background: transparent;
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
`;

export const FooterTextStyled = styled.span`
  font-size: ${(p) => p.theme.typography.fontSize.mini};
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
  margin-bottom: 10px;
  color: ${(p) => p.theme.colors.primary.footerTextColor}; ;
`;
