import { InboxOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import styled from 'styled-components/macro';

import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';
import { CFRadioGroup } from '../../ui-core/V2/cfRadio/cfRadio';

export const AccordInfoTagStyled = styled(Tag)`
  white-space: normal;
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  // This is common for all the theme
  color: #957c01;
  width: 100%;
  padding: 8px 10px;
`;

export const AccordInfoWrapper = styled.div`
  margin-top: 10px;
`;

export const AcordTextStyled = styled.p<{
  disabled: boolean;
}>`
  color: ${(p) => (p.disabled ? p.theme.colors.grayscale.blackOpacity : p.theme.custom.application.blackOpacity)};
`;

export const AppetiteErrorStyled = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  color: ${(p) => p.theme.colors.primary.requiredText};
  font-weight: ${(p) => p.theme.typography.fontWeight.mediumBold};
  margin-bottom: 20px;
`;

export const CFFormItemStyled = styled(CFFormItem)`
  .ant-form-item-label {
    white-space: pre-line;

    > label {
      display: flex;
      align-items: flex-end;
      height: auto;
    }
  }

  .ant-form-item-control {
    display: flex;
    justify-content: flex-end;
  }

  .ant-form-item-control-input {
    min-height: auto;
  }

  .ant-form-item-tooltip {
    padding-bottom: 3px;
  }
`;

export const CFRadioGroupStyled = styled(CFRadioGroup)``;

export const InboxOutlinedStyled = styled(InboxOutlined).withConfig({
  shouldForwardProp: (props) => !['disabled'].includes(props),
})<{
  disabled: boolean;
}>`
  opacity: ${(p) => (p.disabled ? 0.25 : 1)};
  > svg {
    color: ${(p) => p?.theme?.colorScheme?.primary};
  }
`;
