import { Input } from 'antd';
import styled from 'styled-components/macro';

const { TextArea } = Input;

export const StyledCFTextArea = styled(TextArea)`
  box-shadow: none;
  // border-color: ${(p) => p.theme.colors.grayscale.grayBorder} !important;

  // :hover {
  //   border-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primaryHover : 'default')} !important;
  // }
`;
