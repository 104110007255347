import styled from 'styled-components/macro';

import CFButton from '../../../ui-core/V2/cfButton/cfButton';
import CFSelect from '../../../ui-core/V2/cfSelect/cfSelect';
import CFTag from '../../../ui-core/V2/cfTag/cfTag';

export const CardStyled = styled.div`
  padding: 20px;
  background-color: ${(p) => p.theme.colors.grayscale.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.colors.primary.border};
  box-shadow: ${(p) => p.theme.custom.boxShadow.profile};
`;

export const LabelStyled = styled.div`
  flex: 1;
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
`;

export const ValueStyled = styled.div`
  display: flex;
  flex: 2;
`;

export const ButtonStyled = styled(CFButton)`
  margin-left: 10px;
`;

export const SelectContainerStyled = styled.div`
  width: 100%;
`;

export const SelectStyled = styled(CFSelect)<{ isError?: boolean }>`
  width: 100%;

  .ant-select-selector {
    border-color: ${(p) => p?.isError && p.theme.colors.primary.requiredText} !important;

    :hover {
      border-color: ${(p) => p?.isError && p.theme.colors.primary.requiredText} !important;
      box-shadow: ${(p) => p?.isError && 'none'} !important;
    }
  }
`;

export const ErrorRetryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ErrorStyled = styled.div`
  color: ${(p) => p.theme.colors.primary.requiredText};
`;

export const TagStyled = styled(CFTag)`
  margin: 5px 5px 5px 0;
  padding: 4px 8px;
`;
