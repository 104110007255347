import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ANCHOR_TARGET } from './constants';
import { IAnchorProps } from './interface';
import { AnchorStyled } from './styles';

const Anchor = (props: IAnchorProps) => {
  const navigate = useNavigate();
  const {
    id,
    text = '',
    data,
    children,
    onClick,
    href = '#',
    className = '',
    target = ANCHOR_TARGET.SELF,
    stopPropagation = false,
    preventDefault = true,
    rel = 'noopener noreferrer',
    disabled = false,
    ...rest
  } = props || {};

  const onAnchorClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (target === ANCHOR_TARGET.SELF) {
        if (stopPropagation) {
          event.stopPropagation();
        }
        if (preventDefault) {
          event.preventDefault();
        }
        if (!onClick) {
          navigate(href);
        }
      }
      if (onClick) {
        onClick(event, data);
      }
    },
    [data, href, navigate, onClick, preventDefault, stopPropagation, target],
  );

  return (
    <AnchorStyled
      id={id}
      rel={rel}
      href={href}
      target={target}
      onClick={onAnchorClick}
      className={className}
      disabled={disabled}
      {...rest}
    >
      {text}
      {children}
    </AnchorStyled>
  );
};

export default Anchor;
