import { ILocationInfo } from '@coverforce-platform/cf-common-api-model';

import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';

export interface ILocationDetailsStore {
  locations: ILocationInfo[];
  locationDetailsForm?: IFormInstance;
  isLocationDetailLoading?: boolean;
  setIsLocationDetailLoading: (isLocationDetailLoading: boolean) => void;
  setLocationDetailsForm: (locationDetailsForm: IFormInstance) => void;
  addLocationDetails: () => void;
  updateLocationDetails: (locationIndex: number, updatedLocation: ILocationInfo) => void;
  deleteLocationDetails: (locationIndex: number) => void;
  autoFillLocationDetails: () => void;
  validateLocationDetails: () => Promise<boolean>;
  getLocationDetailsApiPayload: () => any;
  getLocationApplicationApiPayload: () => any;
  clearLocationDetails: () => void;
}

// eslint-disable-next-line no-shadow
export enum LocationUpdate {
  PAST_POLICY_EFFECTIVE_DATE = 'PAST_POLICY_EFFECTIVE_DATE',
}
