export const USER_MENU_NAVIGATION_PATHS = {
  PROFILE: '/profile',
  NOTIFICATION_SETTINGS: '/notification-settings',
  LOGOUT: '/logout',
};

export const USER_ROLE_DISPLAY_NAME_MAPPING: any = {
  AGENT: 'Agent',
  AGENCY_ADMIN: 'Admin',
  AGENCY_NETWORK_USER: 'Network Admin',
};

export const USER_MENU_SECTIONS = {
  SELECTED_USER_PROFILE: 'SELECTED_USER_PROFILE',
  OTHER_PROFILES: 'OTHER_PROFILES',
  USER: 'USER',
};
