import { Divider } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';
import ApplicationQuestion from '../applicationQuestion/applicationQuestion';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import { useDownloadAcordStore } from '../modals/downloadAcord/store';
import { RADIO_BUTTON_VALUES, UNDERWRITING_ACCORD_QUESTIONS, UNDERWRITING_ACCORD_SECTION_KEYS } from './constants';
import { AlertStyled, CFFormStyled, CFRowQuestionWrapperStyled } from './styles';
import { handleUnderWritingAccordFieldChange } from './utils';

const UnderwritingAccordSection = () => {
  const { downloadAcordForm, selectedAcordForms } = useDownloadAcordStore();
  const [disclaimerMessage, setDisclaimerMessage] = useState('');

  const handleFieldChange = (event: any, data: any) => {
    handleUnderWritingAccordFieldChange({ value: event?.target?.value, data, setDisclaimerMessage });
  };

  const questions = useMemo(() => {
    return UNDERWRITING_ACCORD_QUESTIONS.map((question: IApplicationQuestion) => {
      if (question.dataIndex === UNDERWRITING_ACCORD_SECTION_KEYS.ATTACH_TO) {
        question.options = [
          question.options![0],
          ...selectedAcordForms.map((accord) => ({ label: accord, value: accord })),
          question.options![question.options!.length - 1],
        ];
        return question;
      }
      return question;
    });
  }, [selectedAcordForms]);

  useEffect(() => {
    return () => {
      downloadAcordForm?.resetFields();
    };
  }, [downloadAcordForm]);

  return (
    <>
      <Divider dashed />
      <CFFormStyled
        id='form_underwriting_accord'
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 8 }}
        form={downloadAcordForm}
        labelAlign='left'
      >
        <CFRowQuestionWrapperStyled
          id='underwriting-accord-section-question-wrapper'
          justify='space-between'
          align='bottom'
        >
          {questions &&
            questions?.map((question: IApplicationQuestion) => (
              <ApplicationQuestion
                question={question}
                key={`question-${question.dataIndex}`}
                allQuestions={questions}
                form={downloadAcordForm}
                onChange={handleFieldChange}
              />
            ))}
        </CFRowQuestionWrapperStyled>
        <CFFormItem id={'selected_accord_item_alert_info'} shouldUpdate noStyle>
          {() =>
            disclaimerMessage &&
            downloadAcordForm?.getFieldValue(UNDERWRITING_ACCORD_SECTION_KEYS.ATTACH_TO)?.length > 0 &&
            downloadAcordForm?.getFieldValue(UNDERWRITING_ACCORD_SECTION_KEYS.IS_ADD_UNDERWRITING_SUPPLEMENT) ===
              RADIO_BUTTON_VALUES.TRUE && <AlertStyled message={disclaimerMessage} type='warning' />
          }
        </CFFormItem>
      </CFFormStyled>
    </>
  );
};

export default UnderwritingAccordSection;
