import {
  IAgencyNetworkMetadata,
  IAgentPortalThemeConfig,
  IFeatureConfig,
} from '@coverforce-platform/cf-common-api-model';
import { AgentPortalUserType, CarrierListType, CFPortalViewType } from '@coverforce-platform/cf-common-types';
import { getToken } from 'firebase/messaging';

import { FEATURE_FLAG } from '../../globalConstants';
import { FIREBASE_VAPID_PUBLIC_KEY } from '../../helpers/EnvironmentVariables';
import { ITheme } from '../../types';
import { BaseTheme } from '../../ui-core/themes/BaseTheme';
import { CFTheme } from '../../ui-core/themes/CFTheme';
import { clearLocalStorage } from '../../utils/clearLocalStorage';
import { firebaseMessaging, initialiseFirebase } from '../../utils/firebase';
import { getAllCarrierIds } from '../../utils/getAllCarrierIds';
import { getSSOClientRedirectUrl } from '../../utils/getSSOClientRedirectUrl';
import { getGrowthbookFeatureValueByKey, isGrowthbookFeatureEnabledByKey } from '../../utils/growthbook';
import { getChannelData, getKnockUser, identifyKnockUser, updateChannelData } from '../../utils/knock';
import { NOTIFICATION_PERMISSIONS, WEB_PUSH_NOTIFICATIONS } from './constants';
import { useConfigurationStore } from './store';

export const handleSSOLogout = () => {
  const ssoClientRedirectUrl = getSSOClientRedirectUrl();
  clearLocalStorage();
  window.location.href = ssoClientRedirectUrl;
};

export const getWebAppTheme = async () => {
  // Get GrowthBook Theme
  // Get themeName from Agency Network Metadata
  // If Growthbook returns theme then return growth book theme
  // else return Agency network metadata theme
  // default case return cf_theme

  const growthBookTheme = getGrowthbookFeatureValueByKey(FEATURE_FLAG.WEBAPP_THEME);

  const { getThemeFromAPI, agencyNetworkMetadata } = useConfigurationStore.getState();

  const computedThemeName = growthBookTheme ? growthBookTheme : agencyNetworkMetadata?.themeName;

  const webAppThemeAPI = await getThemeFromAPI(computedThemeName ?? CFTheme.themeName);

  if (webAppThemeAPI?.themeName) {
    return mapApiAttrToWebAppTheme(webAppThemeAPI, agencyNetworkMetadata);
  } else {
    return CFTheme;
  }
};

export const portalViewTypeUtil = (portalView: CFPortalViewType) => {
  const { userType } = useConfigurationStore.getState();

  switch (portalView) {
    case CFPortalViewType.ALL_VIEWS:
      return true;

    case CFPortalViewType.AGENCY_VIEW:
      return userType === AgentPortalUserType.AGENCY;

    case CFPortalViewType.AGENCY_NETWORK_VIEW:
      return userType === AgentPortalUserType.AGENCY_NETWORK;

    case CFPortalViewType.NONE:
      return false;

    default:
      return false;
  }
};

export const quoteActionConfigByUserTypeUtil = (userType: AgentPortalUserType, actionNameConfig?: IFeatureConfig) => {
  if (userType === AgentPortalUserType.AGENCY) {
    return actionNameConfig?.disabledCarriersAtAgencyLevel?.carrierListType === CarrierListType.ALL
      ? getAllCarrierIds()
      : actionNameConfig?.disabledCarriersAtAgencyLevel?.carrierList;
  }

  if (userType === AgentPortalUserType.AGENCY_NETWORK) {
    return actionNameConfig?.disabledCarriersAtAgencyNetworkLevel?.carrierListType === CarrierListType.ALL
      ? getAllCarrierIds()
      : actionNameConfig?.disabledCarriersAtAgencyNetworkLevel?.carrierList;
  }

  return undefined;
};

const mapApiAttrToWebAppTheme = (
  webAppThemeAPI: IAgentPortalThemeConfig,
  agencyNetworkMetadata?: IAgencyNetworkMetadata,
) => {
  const WebAppAPITheme: ITheme = {
    ...BaseTheme,

    themeName: webAppThemeAPI?.themeName,
    brandLogo: webAppThemeAPI?.brandLogoConfig?.brandLogoURL ?? '/logo/cf-logo.svg',

    colorScheme: {
      primary: webAppThemeAPI?.portalThemeUIUXConfig?.primaryColor,
      primaryHover: webAppThemeAPI?.portalThemeUIUXConfig?.isPrimaryHoverLight
        ? webAppThemeAPI?.portalThemeUIUXConfig?.primaryColor
        : webAppThemeAPI?.portalThemeUIUXConfig?.primaryHoverColor,
      isPrimaryHoverLight: webAppThemeAPI?.portalThemeUIUXConfig?.isPrimaryHoverLight,
      primaryHoverLight: webAppThemeAPI?.portalThemeUIUXConfig?.primaryHoverColor,
    },

    links: {
      demo: agencyNetworkMetadata?.demoSessionURL,
    },

    component: {
      navBar: {
        backgroundColor: webAppThemeAPI?.portalThemeUIUXConfig?.navBarThemeConfig?.backgroundColor,
        textColor: webAppThemeAPI?.portalThemeUIUXConfig?.navBarThemeConfig?.textColor,
        isNavBarBackgroundLight: webAppThemeAPI?.portalThemeUIUXConfig?.navBarThemeConfig?.isNavBarBackgroundLight,
      },
    },

    config: {
      showDashboardWhatsNew: portalViewTypeUtil(webAppThemeAPI?.portalThemeHomepageConfig?.showWhatsNewCardViewType),
      showDashboardApiStatus: portalViewTypeUtil(
        webAppThemeAPI?.portalThemeHomepageConfig?.showCarrierApiStatusCardViewType,
      ),
      disableCarrierRankings: true, // This is true for all, in future while clean up this can be removed as well.
      disabledCarrierRankingsMenu: !agencyNetworkMetadata?.agencyNetworkFeatureConfig?.carrierRankingEnabled,
      disabledAddUser: !agencyNetworkMetadata?.agencyNetworkFeatureConfig?.addUserEnabled,
      isSSOTheme: agencyNetworkMetadata?.isSSOAgencyNetwork,
      disableQuoteType: !portalViewTypeUtil(webAppThemeAPI?.showQuoteIndicationViewType),
      disabledReassignApplication: {
        [AgentPortalUserType.AGENCY]: true,
        [AgentPortalUserType.AGENCY_NETWORK]:
          !agencyNetworkMetadata?.agencyNetworkFeatureConfig?.reAssignApplicationEnabled,
      },
      disabledBridgingCarriers: {
        [AgentPortalUserType.AGENCY]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.carrierBridgingActionConfig,
          ) ?? [],
        [AgentPortalUserType.AGENCY_NETWORK]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY_NETWORK,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.carrierBridgingActionConfig,
          ) ?? [],
      },
      disabledBindCarriers: {
        [AgentPortalUserType.AGENCY]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.bindNowActionConfig,
          ) ?? [],
        [AgentPortalUserType.AGENCY_NETWORK]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY_NETWORK,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.bindNowActionConfig,
          ) ?? [],
      },
      disabledReferCarriers: {
        [AgentPortalUserType.AGENCY]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.referToUWActionConfig,
          ) ?? [],
        [AgentPortalUserType.AGENCY_NETWORK]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY_NETWORK,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.referToUWActionConfig,
          ) ?? [],
      },
      disabledMarkAsBoundCarriers: {
        [AgentPortalUserType.AGENCY]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.markAsBoundActionConfig,
          ) ?? [],
        [AgentPortalUserType.AGENCY_NETWORK]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY_NETWORK,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.markAsBoundActionConfig,
          ) ?? [],
      },
      disabledQuoteDownloadCarriers: {
        [AgentPortalUserType.AGENCY]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.quoteProposalDownloadActionConfig,
          ) ?? [],
        [AgentPortalUserType.AGENCY_NETWORK]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY_NETWORK,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.quoteProposalDownloadActionConfig,
          ) ?? [],
      },
      disabledRequestToBindCarriers: {
        [AgentPortalUserType.AGENCY]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.requestToBindActionConfig,
          ) ?? getAllCarrierIds(),
        [AgentPortalUserType.AGENCY_NETWORK]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY_NETWORK,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.requestToBindActionConfig,
          ) ?? getAllCarrierIds(),
      },
      disabledAddModifiersCarriers: {
        [AgentPortalUserType.AGENCY]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.addModifiersActionConfig,
          ) ?? getAllCarrierIds(),
        [AgentPortalUserType.AGENCY_NETWORK]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY_NETWORK,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.addModifiersActionConfig,
          ) ?? getAllCarrierIds(),
      },
      disabledGetAppointmentCarriers: {
        [AgentPortalUserType.AGENCY]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.getCarrierAppointmentActionConfig,
          ) ?? [],
        [AgentPortalUserType.AGENCY_NETWORK]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY_NETWORK,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.getCarrierAppointmentActionConfig,
          ) ?? [],
      },
      disabledAddBrokerCodeCarriers: {
        [AgentPortalUserType.AGENCY]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.addBrokerCodeActionConfig,
          ) ?? [],
        [AgentPortalUserType.AGENCY_NETWORK]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY_NETWORK,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.addBrokerCodeActionConfig,
          ) ?? [],
      },
      disabledBlockedMarketIndicatorCarriers: {
        [AgentPortalUserType.AGENCY]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.blockedMarketIndicatorConfig,
          ) ?? [],
        [AgentPortalUserType.AGENCY_NETWORK]:
          quoteActionConfigByUserTypeUtil(
            AgentPortalUserType.AGENCY_NETWORK,
            agencyNetworkMetadata?.agencyNetworkFeatureConfig?.blockedMarketIndicatorConfig,
          ) ?? [],
      },
    },
  };

  return WebAppAPITheme;
};

const registerServiceWorker = async () => {
  try {
    return await navigator?.serviceWorker.register(WEB_PUSH_NOTIFICATIONS.FIREBASE_SW_PATH);
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.log('Unable to register service worker.', err);
  }
};

const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === NOTIFICATION_PERMISSIONS.GRANTED) {
      // Generate Device Token for notification
      try {
        const token = await getToken(firebaseMessaging, {
          vapidKey: FIREBASE_VAPID_PUBLIC_KEY,
        });
        const existingChannelData = await getChannelData();
        const channelDataTokens = existingChannelData?.data?.tokens || [];
        if (!channelDataTokens.includes(token)) {
          // DO-NOT-REMOVE:- Future enhancement if we want to show notification on multiple browsers
          // channelDataTokens.push(token);
          // await updateChannelData({ tokens: channelDataTokens });
          await updateChannelData({ tokens: [token] });
        }
      } catch (tokenErr: any) {
        // eslint-disable-next-line no-console
        console.log('Error in generating notification token', tokenErr);
      }
    } else if (permission === NOTIFICATION_PERMISSIONS.DENIED) {
      // eslint-disable-next-line no-console
      console.log('Permission denied for displaying web-push notification');
    }
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.log('Error occurred while requesting notifications permission');
  }
};

export const handleNotifications = async () => {
  const isWebPushEnabled = isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.KNOCK_WEB_PUSH_NOTIFICATIONS);
  if (isWebPushEnabled) {
    initialiseFirebase();
    await registerServiceWorker();
    setTimeout(() => requestNotificationPermission(), WEB_PUSH_NOTIFICATIONS.PERMISSION_REQUEST_DELAY_MS);
  }
};

export const initialiseKnockUser = async () => {
  const knockUser = await getKnockUser();
  if (!knockUser) {
    await identifyKnockUser();
  }
};

export const formatAgencyNetworkToValidPayload = (
  agencyNetworkMetadataPayload: IAgencyNetworkMetadata,
): IAgencyNetworkMetadata => {
  if (agencyNetworkMetadataPayload?.supportContacts && agencyNetworkMetadataPayload?.supportContacts?.length > 0) {
    agencyNetworkMetadataPayload.supportContacts.forEach((item) => {
      if (item?.email === '') {
        item.email = undefined;
      }
      if (item?.phone === '') {
        item.phone = undefined;
      }
    });
  }
  return agencyNetworkMetadataPayload;
};
