import { Carrier } from '@coverforce-platform/cf-common-types';

import CFImage from '../../../ui-core/V2/cfImage/cfImage';
import { usePaymentStore } from '../../payment/store';
import { useQuotesStore } from '../../quotes/store';
import { usePaymentIframeStore } from './store';
import { HiscoxPaymentIframeStyled, PaymentIframeInfoTextStyled, PaymentIframeInfoWrapperStyled } from './styles';

export const CarrierSpecificPaymentIframe = () => {
  const { confirmIframePaymentLoading } = usePaymentIframeStore();
  const { paymentIframeURL } = usePaymentStore.getState();
  const { selectedQuote } = useQuotesStore.getState();

  switch (selectedQuote?.carrierId) {
    case Carrier.HISCOX:
      return (
        <>
          {!confirmIframePaymentLoading && (
            <HiscoxPaymentIframeStyled src={paymentIframeURL} title='Embeded Payment Form' />
          )}
          {confirmIframePaymentLoading && (
            <PaymentIframeInfoWrapperStyled>
              <CFImage id='img_confirm_payment_loader' src='/images/fancy_loader.gif' height={100} />
              <PaymentIframeInfoTextStyled> Please wait while we verify your payment.</PaymentIframeInfoTextStyled>
            </PaymentIframeInfoWrapperStyled>
          )}
        </>
      );

    default:
      return null;
  }
};
