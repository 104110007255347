import { useConfigurationStore } from '../components/configuration/store';
import { KNOCK_FCM_CHANNEL_ID } from '../helpers/EnvironmentVariables';
import { useProfileV2Store } from '../pages/v2/profile/store';

export const getKnockUser = async () => {
  const { knockClient } = useConfigurationStore.getState();

  if (knockClient) {
    try {
      const knockUser = await knockClient.user.get();
      return knockUser;
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log('Error in getKnockUser', err);
    }
  }
};

export const identifyKnockUser = async () => {
  const { knockClient } = useConfigurationStore.getState();
  const { userData, selectedUserProfile } = useProfileV2Store.getState();
  if (knockClient) {
    try {
      await knockClient.user.identify({
        id: selectedUserProfile?.agentProfileId,
        name: `${userData?.firstName} ${userData?.lastName}`,
        email: userData?.email,
      });
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log('Error in identifyKnockUser', err);
    }
  }
};

export const getChannelData = async () => {
  const { knockClient } = useConfigurationStore.getState();
  if (knockClient) {
    try {
      const channelData = await knockClient.user.getChannelData({ channelId: KNOCK_FCM_CHANNEL_ID });
      return channelData;
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log('Error in getChannelData', err);
    }
  }
};

export const updateChannelData = async (channelData: Record<string, any>) => {
  const { knockClient } = useConfigurationStore.getState();
  if (knockClient) {
    try {
      await knockClient.user.setChannelData({
        channelId: KNOCK_FCM_CHANNEL_ID,
        channelData,
      });
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log('Error in updateChannelData', err);
    }
  }
};
