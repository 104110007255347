import { InboxOutlined } from '@ant-design/icons';
import { ISendNotificationsInputForUsers } from '@coverforce-platform/cf-common-api-model';
import { NotificationEvent } from '@coverforce-platform/cf-common-types';
import { Form, message } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { useState } from 'react';

import messages from '../../../constants/messages';
import { sendNotification } from '../../../externalServices/V2/notifications';
import { UploadStatus } from '../../../globalConstants';
import { useProfileV2Store } from '../../../pages/v2/profile/store';
import { errorV2, success } from '../../../ui-core/Notification';
import CFFileDropper from '../../../ui-core/V2/cfFileDropper/cfFileDropper';
import CFForm from '../../../ui-core/V2/cfForm/cfForm';
import CFFormItem from '../../../ui-core/V2/cfFormItem/cfFormItem';
import CFInput from '../../../ui-core/V2/cfInput/cfInput';
import CFModal from '../../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import CFTextArea from '../../../ui-core/V2/cfTextArea/cfTextArea';
import getBase64FromFile from '../../../utils/getBase64FromFile';
import { contactUsFormRules } from './constants';

const ContactUsModal = () => {
  const [form] = Form.useForm();

  const { selectedUserProfile } = useProfileV2Store();
  const { clearCfModal } = useCFModalStore();

  const [isSending, setIsSending] = useState(false);
  const [fileObj, setFileObject] = useState<any | undefined>(undefined);

  const fileUploadCustomRequest = async (options: any) => {
    setTimeout(() => {
      options.onSuccess('ok');
    }, 0);
  };

  const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const { status } = info.file;
    if (status !== UploadStatus.UPLOADING) {
      if (info.file.originFileObj) {
        getBase64FromFile(info.file.originFileObj).then((data) =>
          setFileObject({ data, name: info.file.originFileObj?.name }),
        );
      }
    }
    if (status === UploadStatus.DONE) {
      message.success(messages.FILE_UPLOAD_SUCCESS(info.file.name || ''));
    } else if (status === UploadStatus.ERROR) {
      message.error(messages.FILE_UPLOAD_FAILED(info.file.name || ''));
    }
  };

  const sendRequestEmail = async () => {
    const validatedValues = await form.validateFields();
    const emailPayload: ISendNotificationsInputForUsers = {
      notificationEvent: NotificationEvent.SUPPORT_REQUESTED,
      eventData: {
        subject: validatedValues.question,
        description: validatedValues.tellUsMore,
      },
      attachmentList: fileObj ? [{ presignedDownloadURL: fileObj.data, filename: fileObj.name }] : [],
      agentProfileId: selectedUserProfile?.agentProfileId,
    };
    try {
      setIsSending(true);
      await sendNotification(emailPayload);
      success(messages.MSG_SENT_SUCCESS);
    } catch (error) {
      errorV2(messages.ERROR);
    } finally {
      clearCfModal();
      setIsSending(false);
    }
  };

  return (
    <CFModal
      title='Submit a request'
      okText='Submit'
      onOk={sendRequestEmail}
      confirmLoading={isSending}
      okButtonProps={{ id: 'btn_contact_us_ok', loading: isSending }}
      cancelButtonProps={{ id: 'btn_contact_us_cancel', disabled: isSending }}
      disableCloseIcon={isSending}
      maskClosable={!isSending}
    >
      <CFForm id='form_contact_us' form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <CFFormItem
          id='item_form_contact_us_subject'
          name='question'
          label='Subject'
          rules={contactUsFormRules.question}
        >
          <CFInput id='inp_subject' disabled={isSending} />
        </CFFormItem>
        <CFFormItem
          id='item_form_contact_us_desc'
          name='tellUsMore'
          label='Description'
          rules={contactUsFormRules.tellUsMore}
        >
          <CFTextArea id='inp_desc' rows={4} disabled={isSending} />
        </CFFormItem>
        <CFFormItem id='item_form_contact_us_attachment' name='files' label='Attachments (optional)'>
          <CFFileDropper
            id='add_or_drop_files'
            multiple={false}
            maxCount={1}
            maxSizeAllowedKb={50}
            name='file'
            customRequest={fileUploadCustomRequest}
            onChange={onChange}
            disabled={isSending}
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined className='ant-inbox-icon' />
            </p>
            <p className='ant-upload-text'>Add file or drop files here</p>
          </CFFileDropper>
        </CFFormItem>
      </CFForm>
    </CFModal>
  );
};

export default ContactUsModal;
