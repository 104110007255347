import { Carrier } from '@coverforce-platform/cf-common-types';

export const PREFER_CARRIER_PARTNER: { [k: string]: Carrier[] } = {
  iua_theme: [Carrier.GAIG],
};

export const APPETITE_CARRIER_ERROR_MESSAGE = {
  NO_CARRIER_AVAILABLE:
    'No carriers are available for this policy type. Please ensure you have added the broker codes for all carriers.',
  NO_CARRIER_SELECTED: 'Please select a carrier to proceed.',
};

export const COALITION_VS_SURPLUS_LINK =
  'https://help.coalitioninc.com/hc/en-us/articles/7665550624283-Are-there-any-differences-between-Coalition-s-admitted-and-surplus-lines-products';

export const CARRIER_TOOLTIP = {
  ACCIDENT_FUND: 'Accident Fund and CompWest cannot be selected together in the same application',
  GAIG: 'Great American is a preferred carrier partner. You cannot deselect it when in appetite',
  AMTRUST:
    'AmTrust does not provide an indicative price for BOP quotes. You need to bridge over to complete the application and get a quote.',
};
