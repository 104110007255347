import { IInvoiceDetails, IPaymentPlan } from '@coverforce-platform/cf-common-api-model';
import { Divider } from 'antd';
import { isEmpty } from 'lodash';

import { formatPrice } from '../../../helpers/Utils';
import Disclaimer from '../../discaimer/disclaimer';
import {
  AdditionalChargesListItemStyled,
  PremiumBreakUpFieldWrapperStyled,
  PremiumBreakUpLabelStyled,
  PremiumBreakUpListWrapperStyled,
  PremiumBreakUpPayableAmountStyled,
  PremiumBreakUpPayableAmountValueStyled,
  PremiumBreakUpWrapperStyled,
} from './styles';

export const RenderPaymentPlanFeeBreakUpView = (selectedPaymentPlan: IPaymentPlan) => {
  const { invoiceDetails } = selectedPaymentPlan;

  const isDownPaymentDetails = invoiceDetails?.find((item) => item.isDownPayment);
  const recurringPaymentDetails = invoiceDetails?.filter((item) => !item.isDownPayment);

  return (
    <>
      <>{isDownPaymentDetails && SinglePaymentPlanFeeBreakUpView(isDownPaymentDetails)}</>
      {recurringPaymentDetails?.length > 0 && (
        <>
          <Divider />
          <Disclaimer color='blue'>
            There will be {recurringPaymentDetails?.length} future installment(s) of $
            {formatPrice(recurringPaymentDetails?.[0]?.premiumAmount?.toString())}
          </Disclaimer>
        </>
      )}
    </>
  );
};

export const SinglePaymentPlanFeeBreakUpView = (invoiceDetails: IInvoiceDetails) => {
  const { premiumAmount, taxAmount, additionalCharges, totalAmount } = invoiceDetails;

  // Filter additional charges
  const filteredAdditionalCharges = Object.entries(additionalCharges ?? {}).filter(([, value]) => value !== 0);

  return (
    <PremiumBreakUpWrapperStyled>
      {Number(premiumAmount || 0) !== 0 && (
        <PremiumBreakUpFieldWrapperStyled>
          <PremiumBreakUpLabelStyled>Down Payment Amount:</PremiumBreakUpLabelStyled> $
          {formatPrice(premiumAmount?.toString())}
        </PremiumBreakUpFieldWrapperStyled>
      )}
      {Number(taxAmount || 0) !== 0 && (
        <PremiumBreakUpFieldWrapperStyled>
          <PremiumBreakUpLabelStyled>Tax Amount:</PremiumBreakUpLabelStyled> ${formatPrice(taxAmount?.toString())}
        </PremiumBreakUpFieldWrapperStyled>
      )}

      {
        <PremiumBreakUpFieldWrapperStyled>
          <PremiumBreakUpLabelStyled>Additional Charges:</PremiumBreakUpLabelStyled>
          {isEmpty(filteredAdditionalCharges) && <span> N/A</span>}
          {!isEmpty(filteredAdditionalCharges) && (
            <PremiumBreakUpListWrapperStyled>
              {Object.entries(additionalCharges ?? {}).map(([chargeType, amount]) => {
                return (
                  <AdditionalChargesListItemStyled key={chargeType}>
                    {chargeType}: ${formatPrice(amount?.toString())}
                  </AdditionalChargesListItemStyled>
                );
              })}
            </PremiumBreakUpListWrapperStyled>
          )}
        </PremiumBreakUpFieldWrapperStyled>
      }

      {Number(totalAmount || 0) !== 0 && (
        <PremiumBreakUpPayableAmountStyled>
          <PremiumBreakUpLabelStyled>Payable Amount: </PremiumBreakUpLabelStyled>
          <PremiumBreakUpPayableAmountValueStyled>
            ${formatPrice(totalAmount.toString())}
          </PremiumBreakUpPayableAmountValueStyled>
        </PremiumBreakUpPayableAmountStyled>
      )}
    </PremiumBreakUpWrapperStyled>
  );
};
