import { DependencyType } from '@coverforce-platform/cf-common-types';
import dayjs from 'dayjs';

import { FALSY_VALUES, USState } from '../../globalConstants';
import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { IPolicyLossModalQuestion } from './interface';

export const POLICY_LOSS_QUESTIONS: IPolicyLossModalQuestion[] = [
  {
    type: ANTD_QUESTION_TYPE.DATE,
    name: 'Loss Date',
    dataIndex: 'lossDate',
    rules: [{ required: true, message: 'Please enter Loss Date.' }],
    format: 'MM-DD-YYYY',
    disabledDate: (date: dayjs.Dayjs) => date && date > dayjs().local(),
    containerId: 'root',
  },
  {
    type: ANTD_QUESTION_TYPE.DATE,
    name: 'Claim Date',
    dataIndex: 'claimDate',
    rules: [{ required: true, message: 'Please enter Claim Date.' }],
    format: 'MM-DD-YYYY',
    disabledDate: (date: dayjs.Dayjs) => date && date > dayjs().local(),
    containerId: 'root',
  },
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'Loss Type',
    placeholder: 'Choose Loss Type',
    dataIndex: 'lossType',
    options: [],
    rules: [{ required: true, message: 'Please select Loss Type.' }],
    getPopupContainer: () => document.body,
  },

  {
    type: ANTD_QUESTION_TYPE.RADIO,
    name: 'Claim Status',
    dataIndex: 'claimStatus',
    dependentQuestions: [
      {
        dependencyType: DependencyType.CONDITIONAL,
        condition: 'true',
        childQuestionIds: ['totalReservedAmount'],
      },
    ],
    options: [
      { label: 'Open', value: 'true' },
      { label: 'Closed', value: 'false' },
    ],
    rules: [
      {
        required: true,
        message: 'Please select Claim Status.',
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.MONEY,
    name: 'Total Paid Amount',
    dataIndex: 'totalPaidAmount',
    rules: [
      {
        required: true,
        validator: (_: any, value: string) => {
          if (Number(value) <= 0 || FALSY_VALUES.includes(value?.trim())) {
            return Promise.reject('Please enter Total Paid Amount.');
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.MONEY,
    name: 'Total Reserved Amount',
    dataIndex: 'totalReservedAmount',
    hasParent: true,
    rules: [
      {
        required: true,
        validator: (_: any, value: string) => {
          if (Number(value) <= 0 || FALSY_VALUES.includes(value?.trim())) {
            return Promise.reject('Please enter Total Reserved Amount.');
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    type: ANTD_QUESTION_TYPE.STRING,
    name: 'Steps Taken To Avoid Future Claims',
    dataIndex: 'stepsTakenToAvoidFutureClaims',
    maxLength: 2000,
  },
];

export const LOSS_DESCRIPTION_QUESTION = {
  type: ANTD_QUESTION_TYPE.TEXTAREA,
  name: 'Loss Description',
  dataIndex: 'lossDescription',
  showCount: true,
  maxLength: 200,
  rows: 4,
  rules: [
    {
      required: true,
      validator: (_: any, value: any) => {
        if (!value?.trim()) {
          return Promise.reject(new Error('Please enter Loss Description.'));
        }
        return Promise.resolve();
      },
    },
  ],
};

export const STATE_QUESTION = {
  type: ANTD_QUESTION_TYPE.DROPDOWN,
  name: 'State',
  dataIndex: 'state',
  options: (() => {
    return Object.keys(USState).map((key) => ({
      label: USState[key],
      value: key,
    }));
  })(),
  rules: [{ required: true, message: 'Please select State.' }],
};

export const POLICY_LOSS_MODAL_STORE_ACTION = {
  CLEAR_POLICY_LOSS_MODAL_STORE: 'CLEAR_POLICY_LOSS_MODAL_STORE',
  SET_POLICY_LOSS_MODAL_FORM: 'SET_POLICY_LOSS_MODAL_FORM',
  ANONYMOUS_ACTION: 'POLICY_LOSS_DEFAULT_ACTION',
  STORE_NAME: 'Policy Loss Modal',
  SET_POLICY_LOSS_MODAL_QUESTION: 'SET_POLICY_LOSS_MODAL_QUESTION',
  SET_EDITED_POLICY_LOSS_DETAILS: 'SET_EDITED_POLICY_LOSS_DETAILS',
};
