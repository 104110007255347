import { Alert } from 'antd';
import styled from 'styled-components';

import CFForm from '../../ui-core/V2/cfForm/cfForm';
import CFRow from '../../ui-core/V2/cfRow/cfRow';

export const AlertStyled = styled(Alert)`
  margin: 10px 0px;
`;

export const CFRowQuestionWrapperStyled = styled(CFRow)`
  .ant-row-space-between {
    width: 100%;
  }
  .ant-radio-group {
    background-color: ${(p) => p.theme.colors.grayscale.lightGrayBg};
  }
  & > div {
    margin: 0;
    padding: 0;
  }
`;

export const CFFormStyled = styled(CFForm)`
  margin-top: 30px;
  width: 100%;
`;
