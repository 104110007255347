import { Spin } from 'antd';

import { ICFLoader } from './interface';
import { StyledCFLoader } from './styles';

const CFLoader = (props: ICFLoader) => {
  const { size, marginTop, marginBottom, children, dataTestId, ...rest } = props;

  return (
    <StyledCFLoader marginTop={marginTop} marginBottom={marginBottom} data-testid={dataTestId ?? 'loader'} {...rest}>
      {children ?? <Spin size={size} />}
    </StyledCFLoader>
  );
};

export default CFLoader;
