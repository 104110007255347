import { DatePicker } from 'antd';
import styled from 'styled-components/macro';

export const CFRangePickerStyled = styled(DatePicker.RangePicker)`
  box-shadow: none;
  // border-color: ${(p) => p.theme.colors.grayscale.grayBorder} !important;

  // :hover {
  //   border-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primaryHover : 'default')} !important;
  // }

  // :focus {
  //   border-color: ${(p) => p.theme?.colorScheme?.primaryHover} !important;
  //   box-shadow: 0 0 0 2px ${(p) => p.theme.colors.common.inputBoxShadow} !important;
  // }

  // .ant-picker-range-focused {
  //   border-color: ${(p) => p.theme?.colorScheme?.primaryHover} !important;
  //   box-shadow: 0 0 0 2px ${(p) => p.theme.colors.common.inputBoxShadow} !important;
  // }

  & ~ div {
    .ant-picker-range-cell-selected {
      .ant-picker-range-cell-inner {
        background: ${(p) => p?.theme?.colorScheme?.primary};
      }
    }
    .ant-picker-range-cell-today {
      .ant-picker-range-cell-inner {
        &::before {
          border-color: ${(p) => p?.theme?.colorScheme?.primary};
        }
      }
    }
    .ant-picker-range-header-view {
      button:hover {
        color: ${(p) => p?.theme?.colorScheme?.primary};
      }
    }
    .ant-picker-range-footer {
      .ant-picker-range-today-btn {
        color: ${(p) => p?.theme?.colorScheme?.primary};
      }
    }
  }
`;
