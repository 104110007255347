import { useEffect } from 'react';

import { useApplicationStore } from '../../pages/v2/application/store';
import CFForm, { useForm } from '../../ui-core/V2/cfForm/cfForm';
import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';
import CFModal from '../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { getNcciStateClassCodeByExtendedId } from '../../utils/getNcciStateClassCodeByExtendedId';
import ApplicationQuestion from '../applicationQuestion/applicationQuestion';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import { useOwnerOfficerDetailsStore } from '../ownerOfficerDetails/store';
import { getStateByLocationId } from '../ownerOfficerDetails/utils';
import { OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS, OWNER_OFFICER_QUESTIONS } from './constants';
import { useOwnerOfficerDetailsModalStore } from './store';
import { addDynamicInfoInQuestions, handleOwnerOfficerModalFieldChangeUtil } from './utils';

const OwnerOfficerDetailsModal = () => {
  const [form] = useForm();
  const { clearCfModal } = useCFModalStore();
  const { isReadOnlyApplication } = useApplicationStore();

  const {
    ownerOfficerDetailsModalForm,
    editedOwnerOfficerDetails,
    questionElements,
    ownerOfficerModalLoading,
    autoFillOwnerOfficerDetailsModal,
    updateOwnerOfficerDetailsModalStoreByKey,
    validateOwnerOfficerDetails,
    clearOwnerOfficerDetailsModal,
  } = useOwnerOfficerDetailsModalStore();

  const { addOwnerOfficerDetails, updateOwnerOfficerDetails, ownersOfficersClassCodes } = useOwnerOfficerDetailsStore();

  useEffect(() => {
    updateOwnerOfficerDetailsModalStoreByKey(
      OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_FORM,
      form,
    );
    updateOwnerOfficerDetailsModalStoreByKey(
      OWNER_OFFICER_DETAILS_MODAL_STORE_KEYS.OWNER_OFFICER_DETAILS_MODAL_QUESTION_ELEMENTS,
      addDynamicInfoInQuestions(OWNER_OFFICER_QUESTIONS),
    );
    autoFillOwnerOfficerDetailsModal();
    return () => {
      clearOwnerOfficerDetailsModal();
      clearCfModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitOwnerOfficerDetails = async () => {
    const isValid = await validateOwnerOfficerDetails();
    if (isValid) {
      const formValues = ownerOfficerDetailsModalForm?.getFieldsValue();
      const ownerOfficerDetails = {
        ...formValues,
        jobCodeExtendedId: formValues?.jobCodeExtendedId,
        ncciStateClassCode: getNcciStateClassCodeByExtendedId(
          ownersOfficersClassCodes?.[getStateByLocationId(formValues?.cfLocationId)]?.data,
          formValues?.jobCodeExtendedId,
        ),
      };
      if (editedOwnerOfficerDetails) {
        updateOwnerOfficerDetails(ownerOfficerDetails, editedOwnerOfficerDetails?.index);
      } else {
        addOwnerOfficerDetails(ownerOfficerDetails);
      }
      clearOwnerOfficerDetailsModal();
      clearCfModal();
    }
  };

  return (
    <CFModal
      title='Enter Owner/Officer Details'
      okText={`${editedOwnerOfficerDetails ? 'Update' : 'Add'} Owner/Officer Details`}
      onOk={handleSubmitOwnerOfficerDetails}
      okButtonProps={{
        id: 'btn_ok_owner_officer_details',
        loading: ownerOfficerModalLoading,
        disabled: ownerOfficerModalLoading || isReadOnlyApplication,
      }}
      cancelButtonProps={{
        id: 'btn_cancel_owner_officer_details',
        disabled: ownerOfficerModalLoading || isReadOnlyApplication,
      }}
      disableCloseIcon={ownerOfficerModalLoading}
      maskClosable={!ownerOfficerModalLoading}
      width={600}
    >
      <CFForm id='form_owner_officer_tab' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form}>
        {questionElements?.map((question: IApplicationQuestion) => {
          if (question.hidden) {
            return <></>;
          }
          return (
            <CFFormItem
              shouldUpdate
              noStyle
              id={`form_item_id_question_${question.dataIndex}`}
              key={`form-item-key-question-${question.dataIndex}`}
            >
              {() => {
                return (
                  <ApplicationQuestion
                    question={question}
                    key={`question-${question.dataIndex}`}
                    allQuestions={questionElements}
                    form={form}
                    onChange={handleOwnerOfficerModalFieldChangeUtil}
                    disabled={question.disabled || isReadOnlyApplication}
                  />
                );
              }}
            </CFFormItem>
          );
        })}
      </CFForm>
    </CFModal>
  );
};

export default OwnerOfficerDetailsModal;
