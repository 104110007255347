import { QuestionCircleOutlined } from '@ant-design/icons';
import { Carrier, PolicyType } from '@coverforce-platform/cf-common-types';
import { Tooltip } from 'antd';

import { IAntdOptions } from '../../globalInterface';
import { ANCHOR_TARGET } from '../../ui-core/V2/anchor/constants';
import { APPETITE_TYPE } from '../modals/newQuote/constants';
import { AnchorStyled, CFCheckboxStyled } from './styles';
import { getPreferredCarriers } from './utils';

const getTooltipForCarrier = ({
  carrier,
  selectedPolicy,
  appetiteType,
}: {
  carrier: Carrier;
  selectedPolicy?: PolicyType;
  appetiteType: string;
}) => {
  let tooltipTitle: React.ReactNode = '';
  switch (carrier) {
    case Carrier.ACCIDENTFUND:
    case Carrier.COMPWEST:
      tooltipTitle = 'Accident Fund and CompWest cannot be selected together in the same application';
      break;
    case Carrier.COALITION:
    case Carrier.COALITION_SURPLUS:
      tooltipTitle = (
        <AnchorStyled
          id={`link_${carrier}`}
          target={ANCHOR_TARGET.BLANK}
          href='https://help.coalitioninc.com/hc/en-us/articles/7665550624283-Are-there-any-differences-between-Coalition-s-admitted-and-surplus-lines-products'
        >
          Admitted vs Surplus
        </AnchorStyled>
      );
      break;
    case Carrier.GAIG:
      if (
        selectedPolicy === PolicyType.WC &&
        appetiteType === APPETITE_TYPE.IN_APPETITE &&
        getPreferredCarriers(selectedPolicy).includes(Carrier.GAIG)
      ) {
        tooltipTitle = 'Great American is a preferred carrier partner. You cannot deselect it when in appetite';
      }
      break;
    case Carrier.AMTRUST:
      if (selectedPolicy === PolicyType.BOP) {
        tooltipTitle =
          'AmTrust does not provide an indicative price for BOP quotes. You need to bridge over to complete the application and get a quote.';
      }
      break;

    default:
      break;
  }
  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <QuestionCircleOutlined />
    </Tooltip>
  ) : (
    ''
  );
};

export const getCarrierOptions = ({
  options,
  disabledCarriers = [],
  selectedPolicy,
  appetiteType,
}: {
  options: IAntdOptions[];
  disabledCarriers?: Carrier[];
  selectedPolicy?: PolicyType;
  appetiteType: string;
}) => {
  return options.map((option) => (
    <CFCheckboxStyled
      key={option.value}
      id={option.value}
      value={option.value}
      disabled={disabledCarriers.indexOf(option.value as Carrier) >= 0}
      style={{ margin: 0 }}
    >
      {option.label} {getTooltipForCarrier({ carrier: option.value as Carrier, selectedPolicy, appetiteType })}
    </CFCheckboxStyled>
  ));
};
