import styled from 'styled-components/macro';

export const AppetiteErrorTitleStyled = styled.div`
  display: flex;
  justify-content: center;
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  padding-bottom: 8px;
  border-bottom: 1px solid ${(p) => p.theme.colors.primary.borderLight};
`;

export const AppetiteInfoWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 0 20px;
`;

export const AppetiteWrapperStyled = styled.div`
  padding-top: 10px;
  box-shadow: ${(p) => p.theme.custom.boxShadow.dashBoard};
  padding-bottom: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
