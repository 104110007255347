import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { getQuestionStatus } from '../../utils/getQuestionStatus';
import ApplicationQuestion from '../applicationQuestion/applicationQuestion';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import StatusWrapper from '../statusWrapper/statusWrapper';
import { getQuestionInfo } from '../underwriting/utils';

const CfQuestionWithStatus = ({
  question,
  form,
  questions,
  prefixDataIndex = [],
  disabled = false,
  onChange,
}: {
  question: IApplicationQuestion;
  form?: IFormInstance;
  questions: IApplicationQuestion[];
  prefixDataIndex?: string[];
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, data?: any) => void;
}) => {
  if (question.hasParent && !question.isChildRender) {
    return <></>;
  }
  // As part of questions at FE, we introduced a tooltip prop inside question which gets passed and displayed on UI
  // In case of UW questions, the tooltipInfo object contains the necessary information hence this check is needed
  if (!question.tooltip && question?.tooltipInfo?.description) {
    question.tooltip = question.tooltipInfo.description;
  }
  return (
    <StatusWrapper
      key={question.dataIndex}
      status={getQuestionStatus({
        fullKey: prefixDataIndex.concat([question.dataIndex || '']).join('.'),
        rules: question.rules || [],
        form,
        isAddressTypeQuestion: getQuestionInfo(question.dataIndex, questions).isAddressType,
      })}
    >
      <ApplicationQuestion
        question={question}
        form={form}
        allQuestions={questions}
        showStatus
        prefixDataIndex={prefixDataIndex}
        disabled={disabled}
        onChange={onChange}
      />
    </StatusWrapper>
  );
};

export default CfQuestionWithStatus;
