import { MIME_TYPES } from '../../globalConstants';
import { IAccordFieldsForNewQuoteStore } from './interface';

export const ADDITIONAL_FIELDS_FOR_NEW_QUOTE_STORE_META_DATA = {
  SET_FILE_LIST: 'SET_FILE_LIST',
  SET_IS_ACORD_UPLOAD: 'SET_IS_ACORD_UPLOAD',
  CLEAR_ACCORD_FIELDS_FOR_NEW_QUOTE: 'CLEAR_ACCORD_FIELDS_FOR_NEW_QUOTE',
  SET_ACORD_ERROR: 'SET_ACORD_ERROR',
  ANONYMOUS_ACTION_NAME: 'ADDITIONAL_FIELDS_FOR_NEW_QUOTE_ANONYMOUS_ACTION',
  UPDATE_ACCORD_FIELDS_BY_KEY: 'UPDATE_ACCORD_FIELDS_BY_KEY',
  STORE_NAME: 'ADDITIONAL_FIELDS_FOR_NEW_QUOTE_STORE',
};

export const DEFAULT_MAX_ALLOWED_ACORD_FILE_SIZE_KB = 15360; // 15MB, server only accept file till 15MB.

export const UPLOAD_ACORD_SUPPORTED_EXT = [MIME_TYPES.JPEG, MIME_TYPES.PDF, MIME_TYPES.PNG];

export const ACCORD_FIELDS_STORE_KEYS: { [k: string]: keyof IAccordFieldsForNewQuoteStore } = {
  ACCORD_ERROR: 'acordError',
  ACCORD_UPLOAD: 'isAcordUpload',
  FILE_LIST: 'fileList',
};

export const FILE_UPLOAD_SUCCESS_CALLBACK_TEXT = 'ok';
