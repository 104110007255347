import createInstance from '../common/createInstance';
import { defaultRequestInterceptor, defaultResponseInterceptor } from '../common/interceptor';
import methods from '../common/methods';
import { NOTIFICATIONS_SERVICE, NOTIFICATIONS_SERVICE_PARAMS } from './constants';

const axiosNotificationsService = createInstance(NOTIFICATIONS_SERVICE_PARAMS);

defaultRequestInterceptor({ axiosInstance: axiosNotificationsService, serviceName: NOTIFICATIONS_SERVICE });
defaultResponseInterceptor({ axiosInstance: axiosNotificationsService, serviceName: NOTIFICATIONS_SERVICE });

export default methods(axiosNotificationsService);
