import {
  IGetNotificationPreferencesInput,
  IGetNotificationPreferencesOutput,
  ISendNotificationsInputForUsers,
  ISetNotificationPreferencesInput,
  ISetNotificationPreferencesOutput,
} from '@coverforce-platform/cf-common-api-model';
import { AxiosResponse } from 'axios';

import axiosNotificationsService from '../../axios/notificationsService/instance';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { NOTIFICATIONS_SERVICE_ENDPOINTS } from '../helpers/ApiRoutes';

const getUserPreferencesByProfileId = async (
  params: IGetNotificationPreferencesInput,
): Promise<IGetNotificationPreferencesOutput> => {
  const getUserPreferencesByProfileIdApiUrl = createQueryUrl({
    url: NOTIFICATIONS_SERVICE_ENDPOINTS.GET_PREFERENCES(params),
  });

  try {
    const response: AxiosResponse = await axiosNotificationsService.get(getUserPreferencesByProfileIdApiUrl);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const setUserPreferencesByProfileId = async (
  params: ISetNotificationPreferencesInput,
): Promise<ISetNotificationPreferencesOutput> => {
  const setUserPreferencesByProfileIdApiUrl = createQueryUrl({
    url: NOTIFICATIONS_SERVICE_ENDPOINTS.UPDATE_PREFERENCES,
  });

  try {
    const response: AxiosResponse = await axiosNotificationsService.put(setUserPreferencesByProfileIdApiUrl, params);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const sendNotification = async (payload: ISendNotificationsInputForUsers) => {
  const sendNotificationUrl = createQueryUrl({
    url: NOTIFICATIONS_SERVICE_ENDPOINTS.SEND_NOTIFICATION,
  });

  try {
    const response: AxiosResponse = await axiosNotificationsService.post(sendNotificationUrl, payload);
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export { getUserPreferencesByProfileId, setUserPreferencesByProfileId, sendNotification };
