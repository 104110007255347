import { useEffect } from 'react';

import { EVENT_LISTENERS } from '../../../globalConstants';
import CFModal from '../../../ui-core/V2/cfModal/cfModal';
import Disclaimer from '../../discaimer/disclaimer';
import { CarrierSpecificPaymentIframe } from './componentUtils';
import { usePaymentIframeStore } from './store';
import { iframeEventListener } from './utils';

const PaymentIframe = () => {
  const { clearIframePaymentStore } = usePaymentIframeStore();
  useEffect(() => {
    window.addEventListener(EVENT_LISTENERS.MESSAGE, iframeEventListener);

    return () => {
      window.removeEventListener(EVENT_LISTENERS.MESSAGE, iframeEventListener);
      clearIframePaymentStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CFModal footer={null} title='Enter Payment Details' closable={false} maskClosable={false}>
      <Disclaimer color='red'>
        <b> Do not refresh or navigate away from the payment page until the payment is completed.</b>
      </Disclaimer>
      <CarrierSpecificPaymentIframe />
    </CFModal>
  );
};

export default PaymentIframe;
