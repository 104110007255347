import { ReportDeliveryFrequency } from '@coverforce-platform/cf-common-api-model';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';

import { capitalizeText, getEmailRegex } from '../../helpers/Utils';
import { ReportDurationInfo } from '../../pages/v2/reportCenter/constants';
import { useReportCenterStore } from '../../pages/v2/reportCenter/store';
import { tagRender } from '../../pages/v2/requestToBindSettings/componentUtils';
import { DROPDOWN_MODE } from '../../ui-core/V2/cfSelect/constants';
import CFTable from '../../ui-core/V2/cfTable/cfTable';
import { formatToAntdOptions } from '../../utils/formatToAntdOptions';
import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { SCHEDULE_REPORT_QUESTION_KEYS } from './constants';
import { IScheduleReportQuestion } from './interface';
import { useScheduleReportStore } from './store';
import { ReportTypeColumnInfoHeaderStyled, ReportTypeColumnInfoTitleStyled } from './styles';

export const SCHEDULE_REPORT_FORM_CONFIG = (): IScheduleReportQuestion[] => {
  const { reportTypeInfo } = useReportCenterStore.getState();
  return [
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Report Type',
      dataIndex: SCHEDULE_REPORT_QUESTION_KEYS.REPORT_TYPE,
      placeholder: 'Select a Report Type',
      options:
        reportTypeInfo?.map((reportType) => ({
          label: (
            <Tooltip title={reportType?.toolTipText} placement='right'>
              {reportType.displayValue}
            </Tooltip>
          ) as unknown as string,
          value: reportType.value,
        })) ?? [],
      rules: [{ required: true, message: 'Please select Report Type.' }],
      filterOption: (input: string, option: any) => {
        return (option?.label?.props?.children as unknown as string)?.toLowerCase().includes(input?.toLowerCase());
      },
    },
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Recipient Email(s)',
      dataIndex: SCHEDULE_REPORT_QUESTION_KEYS.TO_EMAIL_LIST,
      placeholder: 'Please enter the Recipient Email(s)',
      options: [],
      tagRender: ({ label, value }) => {
        const { scheduleReportLoading } = useScheduleReportStore?.getState() ?? {};
        return tagRender({
          label,
          value,
          closable: !scheduleReportLoading,
          onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>, removedEmail: string) => {
            const { scheduleReportForm } = useScheduleReportStore?.getState() ?? {};

            const currentEmailList =
              scheduleReportForm?.getFieldValue(SCHEDULE_REPORT_QUESTION_KEYS.TO_EMAIL_LIST) ?? [];
            const filterEmailList = currentEmailList?.filter((email: string) => email !== removedEmail) ?? [];

            scheduleReportForm?.setFieldValue(SCHEDULE_REPORT_QUESTION_KEYS.TO_EMAIL_LIST, filterEmailList);
            scheduleReportForm?.validateFields([SCHEDULE_REPORT_QUESTION_KEYS.TO_EMAIL_LIST]);
          },
        });
      },
      rules: [
        { required: true, message: `Please enter valid Email(s).` },
        () => ({
          validator(_: any, value: any) {
            let isError = false;

            value?.forEach((email: string) => {
              if (!getEmailRegex().test(email?.trim())) {
                isError = true;
              }
            });

            if (isError) {
              return Promise.reject(`Please enter valid Email(s).`);
            }
            return Promise.resolve();
          },
        }),
      ],
      mode: DROPDOWN_MODE.TAGS,
    },
    {
      type: ANTD_QUESTION_TYPE.STRING,
      name: 'Schedule Name',
      placeholder: 'Please enter Schedule Name',
      dataIndex: SCHEDULE_REPORT_QUESTION_KEYS.SCHEDULE_NAME,
      rules: [
        {
          required: true,
          message: `Please enter Schedule Name.`,
        },
      ],
    },
    {
      type: ANTD_QUESTION_TYPE.DATE_RANGE,
      name: 'Start and End (Optional) Date',
      dataIndex: SCHEDULE_REPORT_QUESTION_KEYS.TIME_FRAME_DATE_RANGE,
      format: 'MM-DD-YYYY',
      disabledDate: (date: dayjs.Dayjs) => date && date <= dayjs().local().subtract(0, 'day'),
      containerId: 'root',
      rules: [
        { required: true, message: 'Please enter Report time frame Start Date.' },
        () => ({
          validator(_: any, value: any) {
            if (dayjs(value[0]).isSame(value[1], 'day')) {
              return Promise.reject(new Error('Start and End date cannot be same.'));
            }
            return Promise.resolve();
          },
        }),
      ],
      allowEmpty: [false, true],
      tooltip:
        'The end date is optional. If it is not provided, the report will continue to be sent until the schedule is deleted or updated with an end date',
      style: { width: '75%' },
    },
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Frequency of Delivery',
      placeholder: 'Select a option',
      dataIndex: SCHEDULE_REPORT_QUESTION_KEYS.FREQUENCY_OF_DELIVERY,
      options: formatToAntdOptions({
        list: Object.values(ReportDeliveryFrequency),
        labelCallback: (label) => capitalizeText({ text: label }),
      }),
      rules: [{ required: true, message: 'Please enter Frequency of Report Deliveries.' }],
      style: { width: '75%' },
    },
    {
      type: ANTD_QUESTION_TYPE.TIME_PICKER,
      name: 'Preferred Time of Delivery',
      placeholder: 'Select a Time',
      dataIndex: SCHEDULE_REPORT_QUESTION_KEYS.PREFERRED_TIME_OF_DELIVERY,
      rules: [{ required: true, message: 'Please enter Preferred Time of Delivery.' }],
      style: { width: '75%' },
      format: 'hh:mm A',
    },
    {
      type: ANTD_QUESTION_TYPE.DROPDOWN,
      name: 'Report Duration',
      placeholder: 'Select a Report Duration',
      dataIndex: SCHEDULE_REPORT_QUESTION_KEYS.REPORT_DURATION_FOR_DELIVERY,
      options:
        ReportDurationInfo?.map((reportDuration) => ({
          label: (
            <Tooltip title={reportDuration?.toolTipText} placement='right'>
              {reportDuration.displayValue}
            </Tooltip>
          ) as unknown as string,
          value: reportDuration.value,
        })) ?? [],
      rules: [{ required: true, message: 'Please enter Report Duration.' }],
      filterOption: (input: string, option: any) => {
        return (option?.label?.props?.children as unknown as string)?.toLowerCase().includes(input?.toLowerCase());
      },
      style: { width: '75%' },
    },
  ];
};

export const getReportTypeColumnInfo = (reportType: string) => {
  const { reportTypeInfo } = useReportCenterStore.getState();
  const selectedReportType = reportTypeInfo?.find((entry) => entry.value === reportType);
  const data = selectedReportType?.columnsInfo ?? [];

  // Create rows of 3 items each
  const rows = [];
  for (let i = 0; i < data.length; i += 3) {
    rows.push(data.slice(i, i + 3));
  }

  // Define columns without header/title
  const columns = [
    { title: '', dataIndex: 'col0', key: 'col0' },
    { title: '', dataIndex: 'col1', key: 'col1' },
    { title: '', dataIndex: 'col2', key: 'col2' },
  ];

  // Generate the data source for the table
  const dataSource = rows.map((row, rowIndex) => {
    const rowData: { [key: string]: React.ReactNode } = {};
    row.forEach((item, colIndex) => {
      rowData[`col${colIndex}`] = (
        <Tooltip title={item.description}>
          <div>{item.title}</div>
        </Tooltip>
      );
    });
    return { key: rowIndex, ...rowData };
  });

  return (
    <>
      <ReportTypeColumnInfoHeaderStyled>
        <ReportTypeColumnInfoTitleStyled> {selectedReportType?.displayValue}:&nbsp;</ReportTypeColumnInfoTitleStyled>
        <span>{selectedReportType?.toolTipText}. </span>
        <span>Below is a list of distinct columns in the report.</span>
      </ReportTypeColumnInfoHeaderStyled>
      <CFTable
        id='report_type_column_info'
        showHeader={false}
        columns={columns}
        dataSource={dataSource}
        bordered
        size='small'
        pagination={false}
      ></CFTable>
    </>
  );
};
