import { Divider } from 'antd';
import styled from 'styled-components/macro';

import CFModal from '../../../ui-core/V2/cfModal/cfModal';

export const ModifiersWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export const ModifiersQuestionsWrapperStyled = styled.div`
  width: 100%;
  border-left: 1px solid ${(p) => p.theme?.colors?.grayscale?.grayBorder};
  padding: 24px;
  max-height: 50vh;
  overflow-y: auto;
  .ant-form-item-label > label {
    display: block;
  }
`;

export const CFModalStyled = styled(CFModal)`
  .ant-modal-content {
    max-height: 70vh !important;
    overflow-y: auto;
  }
  .ant-modal-body {
    padding: 0;
    overflow-y: auto;
  }
`;

export const ModifierStateGroupTitleStyled = styled.div`
  font-size: ${(p) => p?.theme?.typography?.fontSize?.extendedMedium};
  font-weight: ${(p) => p?.theme?.typography?.fontWeight?.mediumBold};
`;

export const ModifierStateGroupStyled = styled.div``;

export const DividerStyled = styled(Divider)`
  margin-top: 20px;
`;

export const ModifierStateGroupTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
