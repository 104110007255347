import { PolicyType } from '@coverforce-platform/cf-common-types';

import { ACORD_MESSAGES } from '../modals/newQuoteV2/constants';
import { IApplicationAcordConfig } from '../modals/newQuoteV2/interface';

export const extractPolicySpecificRule = (
  allowedPolicyTypesForAcord: IApplicationAcordConfig[],
  policyType: PolicyType,
): IApplicationAcordConfig | undefined => {
  const acordDetailByPolicyType = allowedPolicyTypesForAcord?.find(
    (policyTypeAcordDetails: IApplicationAcordConfig) => policyTypeAcordDetails.policyType === policyType,
  );
  return acordDetailByPolicyType;
};

export const getAcordUploadMessageForPolicyType = (
  allowedPolicyTypesForAcord: IApplicationAcordConfig[],
  policyType: PolicyType,
): string => {
  const acordDetailByPolicyType = allowedPolicyTypesForAcord?.find(
    (policyTypeAcordDetails: IApplicationAcordConfig) => policyTypeAcordDetails.policyType === policyType,
  );
  /** it will pick default message it, there is no config for selected policy type or no uploadAcordMessage */
  return acordDetailByPolicyType
    ? acordDetailByPolicyType.uploadAcordMessage || ACORD_MESSAGES.DEFAULT_ACORD_UPLOAD_MESSAGE
    : ACORD_MESSAGES.DEFAULT_ACORD_UPLOAD_MESSAGE;
};
