import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';

import CFButton from '../../ui-core/V2/cfButton/cfButton';
import CFParagraph from '../../ui-core/V2/cfParagraph/cfParagraph';

export const HeaderStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // padding: 12px 16px 12px 24px;
  border-radius: 6px;
`;

export const HeaderRightSectionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
`;

export const HeaderSectionStyled = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 50%;
`;

export const HeaderButtonStyled = styled(CFButton)`
  margin-left: 8px;
`;

export const CheckCircleOutlinedStyled = styled(CheckCircleOutlined)`
  color: ${(p) => p?.theme?.colors?.common?.successColor};
  font-size: ${(p) => p?.theme?.typography?.fontSize?.extendedMediumLarge};
  // margin-right: 30px;
`;

export const MinusCircleOutlinedStyled = styled(MinusCircleOutlined)`
  color: ${(p) => p?.theme?.colors?.grayscale?.blackOpacityLight};
  font-size: ${(p) => p?.theme?.typography?.fontSize?.extendedMediumLarge};
  // margin-right: 30px;
`;

export const AccordionTitleStyled = styled(CFParagraph)`
  width: 100%;
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
  font-size: ${(p) => p.theme.typography.fontSize.medium};
  color: ${(p) => p?.theme?.colors?.grayscale?.black};
`;
