import { Carrier, QuotePaymentStatus } from '@coverforce-platform/cf-common-types';

import { APPLICATION_STORE_KEYS } from '../../../constants/applicationV2Constants';
import { confirmPayment } from '../../../externalServices/V2/payment';
import { useApplicationStore } from '../../../pages/v2/application/store';
import { getCurrentStepFromApplication } from '../../../pages/v2/application/utils';
import { IFRAME_POST_MESSAGE } from '../../../pages/v2/paymentIframeRedirect/constants';
import { errorV2 } from '../../../ui-core/Notification';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import { usePaymentStore } from '../../payment/store';
import { useQuotesStore } from '../../quotes/store';
import { IFRAME_PAYMENT_MESSAGES, PAYMENT_IFRAME_STORE_KEYS } from './constants';
import { usePaymentIframeStore } from './store';

export const iframeEventListener = async (event: any) => {
  const { selectedQuote } = useQuotesStore.getState();
  switch (selectedQuote?.carrierId) {
    case Carrier.HISCOX:
      await hiscoxIframeEventListener(event);
      break;
    // Add more cases here for other carriers iframe integration
    default:
      break;
  }
};

const hiscoxIframeEventListener = async (event: any) => {
  const { updatePaymentIframeStoreByKey } = usePaymentIframeStore.getState();
  const { setIsBounding } = usePaymentStore.getState();
  const { applicationData, fetchApplication, updateApplicationByKey } = useApplicationStore.getState();
  const { clearCfModal } = useCFModalStore.getState();
  const { selectedQuote } = useQuotesStore.getState();

  const eventData = event?.data;
  if (eventData.type === IFRAME_POST_MESSAGE) {
    try {
      const queryParamsObject = {} as {
        [key: string]: string;
      };
      const queryParams = new URLSearchParams(eventData?.payload?.split('?')[1]);
      queryParams?.forEach((value, key) => {
        queryParamsObject[key] = value;
      });

      updatePaymentIframeStoreByKey(PAYMENT_IFRAME_STORE_KEYS.CONFIRM_IFRAME_PAYMENT_LOADING, true);
      const confirmPaymentResponse = await confirmPayment({
        cfQuoteId: selectedQuote?.cfQuoteId!,
        applicationId: applicationData?.applicationId!,
        txnReferenceDetails: Object.keys(queryParamsObject)?.length > 0 ? queryParamsObject : undefined,
      });

      clearCfModal();
      if (confirmPaymentResponse?.quotePaymentStatus !== QuotePaymentStatus.FAILED) {
        const applicationDataResponse = await fetchApplication(applicationData?.applicationId!);
        updateApplicationByKey(
          APPLICATION_STORE_KEYS.CURRENT_STEP,
          getCurrentStepFromApplication(applicationDataResponse),
        );
      } else {
        errorV2(confirmPaymentResponse?.failures?.[0]?.reason || IFRAME_PAYMENT_MESSAGES.TRANSACTION_ERROR, 5);
      }
    } catch (error) {
      errorV2(IFRAME_PAYMENT_MESSAGES.CONFIRM_PAYMENT_ERROR);
    } finally {
      updatePaymentIframeStoreByKey(PAYMENT_IFRAME_STORE_KEYS.CONFIRM_IFRAME_PAYMENT_LOADING, false);
      setIsBounding(false);
      clearCfModal();
    }
  }
};
