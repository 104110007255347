import styled from 'styled-components/macro';

export const LayoutWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background-color: ${(p) => p.theme.colors.common.pageBgColor};
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const NavbarWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => p.theme?.component?.navBar?.backgroundColor};
  min-height: 64px;
  position: relative;
  box-shadow: ${(p) => p.theme.custom.boxShadow.navBar};

  @media (max-width: 1020px) {
    padding: 0px 0px 0px 20px;
    width: 100%;
    overflow-x: hidden;
  }
`;

// TODO: check horizontal scroll
export const ElementWrapperStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
