import { Checkbox } from 'antd';
import styled from 'styled-components/macro';

export const StyledCFCheckbox = styled(Checkbox)`
  /* .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-checked::after {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-disabled > .ant-checkbox-inner {
    background-color: ${(p) => p.theme.colors.grayscale.grayBg};
    border-color: ${(p) => p.theme.colors.grayscale.grayBorder};
  } */
`;

export const StyledCFCheckboxGroup = styled(Checkbox.Group)`
  /* .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-checked::after {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-checkbox-disabled > .ant-checkbox-inner {
    background-color: ${(p) => p.theme.colors.grayscale.grayBg};
    border-color: ${(p) => p.theme.colors.grayscale.grayBorder};
  } */
`;
