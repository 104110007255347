import { useDownloadAcordStore } from '../modals/downloadAcord/store';
import { ATTACH_UWQ_VALUE, UNDERWRITING_ACCORD_SECTION_KEYS, UNDERWRITING_ACCORD_SECTION_NOTE } from './constants';

export const handleUnderWritingAccordFieldChange = ({
  value,
  data,
  setDisclaimerMessage,
}: {
  value: string;
  data: any;
  setDisclaimerMessage: Function;
}) => {
  const { downloadAcordForm } = useDownloadAcordStore.getState();
  switch (data?.dataIndex) {
    case UNDERWRITING_ACCORD_SECTION_KEYS.IS_ADD_UNDERWRITING_SUPPLEMENT:
      downloadAcordForm?.setFieldValue(UNDERWRITING_ACCORD_SECTION_KEYS.IS_ADD_UNDERWRITING_SUPPLEMENT, value);
      break;

    case UNDERWRITING_ACCORD_SECTION_KEYS.ATTACH_TO: {
      const values = downloadAcordForm?.getFieldValue(UNDERWRITING_ACCORD_SECTION_KEYS.ATTACH_TO) || [];
      if (values.length > 0) {
        const userAddedValue = values[values.length - 1];
        switch (userAddedValue) {
          case ATTACH_UWQ_VALUE.ALL:
            downloadAcordForm?.setFieldValue(UNDERWRITING_ACCORD_SECTION_KEYS.ATTACH_TO, [ATTACH_UWQ_VALUE.ALL]);
            setDisclaimerMessage(UNDERWRITING_ACCORD_SECTION_NOTE.ALL);
            break;
          case ATTACH_UWQ_VALUE.NONE:
            downloadAcordForm?.setFieldValue(UNDERWRITING_ACCORD_SECTION_KEYS.ATTACH_TO, [ATTACH_UWQ_VALUE.NONE]);
            setDisclaimerMessage(UNDERWRITING_ACCORD_SECTION_NOTE.NONE);
            break;
          default:
            downloadAcordForm?.setFieldValue(
              UNDERWRITING_ACCORD_SECTION_KEYS.ATTACH_TO,
              values.filter((val: string) => ![ATTACH_UWQ_VALUE.ALL, ATTACH_UWQ_VALUE.NONE].includes(val)),
            );
            setDisclaimerMessage('');
            break;
        }
      }
      break;
    }

    default:
      break;
  }
};
