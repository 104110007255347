import styled from 'styled-components/macro';

const DivStyled = styled.div`
  display: flex;
  margin: 20px 0px;
`;

const StatusChildrenWrapperStyled = styled.div`
  width: calc(100% - 30px);
`;

export { DivStyled, StatusChildrenWrapperStyled };
