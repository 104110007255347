import styled from 'styled-components/macro';

import CFModalV2 from '../../ui-core/V2/cfModal/cfModal';

export const DeclinedReasonsModalStyled = styled(CFModalV2)``;

export const DeclinedReasonsStyled = styled.ol`
  padding-inline-start: 24px;
  padding-right: 12px;
`;

export const DeclinedReasonItemStyled = styled.li`
  :not(:last-child) {
    padding-bottom: 12px;
  }
`;
