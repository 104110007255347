import { Radio } from 'antd';
import styled from 'styled-components/macro';

const { Group } = Radio;
const { Button } = Radio;

export const StyledCFRadio = styled(Radio)``;

export const StyledCFRadioGroup = styled(Group)`
  .ant-radio-button-wrapper:hover {
    color: ${(p) => (!p.disabled ? p?.theme?.colorScheme?.primary : 'default')} !important;
    // border: ${(p) => (!p.disabled ? `1px solid ${p?.theme?.colorScheme?.primary}` : 'default')} !important;
  }

  .ant-radio-button-wrapper-checked {
    color: ${(p) => (!p.disabled ? p.theme.colors.grayscale.white : 'default')} !important;
    border-color: ${(p) => (!p.disabled ? p?.theme?.colorScheme?.primary : 'default')} !important;
    background-color: ${(p) => (!p.disabled ? p?.theme?.colorScheme?.primary : 'default')} !important;

    :hover {
      color: ${(p) => (!p.disabled ? p.theme.colors.grayscale.white : 'default')} !important;
      border-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primaryHover : 'default')} !important;
      background-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primaryHover : 'default')} !important;
    }

    ::before {
      background-color: ${(p) => (!p.disabled ? p?.theme?.colorScheme?.primary : 'default')} !important;
    }
  }

  .ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-disabled .ant-radio:hover::after {
    visibility: hidden;
  }

  .ant-radio-wrapper .ant-radio-disabled.ant-radio-checked .ant-radio-inner::after {
    background-color: ${(p) => p.theme.colors.grayscale.blackOpacity};
  }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: initial;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.5);
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
  }
`;

export const StyledCFRadioButton = styled(Button)`
  border-left: 1px solid ${(p) => p.theme.colors.grayscale.grayBorder};
`;
