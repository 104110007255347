import { Upload } from 'antd';
import styled from 'styled-components/macro';

const { Dragger } = Upload;

export const StyledCFFileDropper = styled(Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 4px;
  }

  &.ant-upload.ant-upload-drag:hover,
  &.ant-upload.ant-upload-drag:focus {
    border-color: ${(p) => p?.theme?.colorScheme?.primary};
    box-shadow: none;
  }
  .ant-inbox-icon {
    color: ${(p) => p?.theme?.colorScheme?.primary} !important;
  }
`;
