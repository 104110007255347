import { CloseCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';

export const NotificationItemWrapperStyled = styled.div
  .attrs((props: any) => props)
  .withConfig({
    shouldForwardProp: (props: any) => !['notificationItem'].includes(props),
  })`
  display: flex;
  padding: 4px 8px;
  border-bottom: 1px solid #e4e8ee;
  background-color: ${(p) => (p.notificationItem?.read_at ? '' : p.theme.colors.grayscale.grayBg)};

  :hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.navBar.dropDownItemSelectedBgColor};
  }
`;

export const NotificationItemContentStyled = styled.div`
  width: 90%;
`;

export const NotificationUnreadWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  width: 20px;
`;

export const NotificationUnreadIndicatorStyled = styled.div
  .attrs((props: any) => props)
  .withConfig({
    shouldForwardProp: (props: any) => !['notificationItem'].includes(props),
  })`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 6px;
  padding: 4px;
  background-color: ${(p) => (p.notificationItem?.read_at ? '' : `${p?.theme?.colorScheme?.primary}`)};
  border: ${(p) => (p.notificationItem?.read_at ? '' : `1px solid ${p?.theme?.colorScheme?.primary}`)};
`;

export const NotificationItemStyled = styled.span``;

export const NotificationItemTimestampStyled = styled.span`
  color: ${(p) => p?.theme?.colors?.grayscale?.darkGray};
`;

export const ArchiveNotificationWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ArchiveNotificationStyled = styled(CloseCircleOutlined)`
  :hover {
    color: ${(p) => p?.theme?.colors?.grayscale?.darkGray};
  }
`;
