import { Input } from 'antd';
import styled from 'styled-components/macro';

export const StyledCFInput = styled(Input)`
  box-shadow: none;
  // border-color: ${(p) => p.theme.colors.grayscale.grayBorder} !important;

  // :hover {
  //   border-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primaryHover : 'default')} !important;
  // }

  // :focus {
  //   border-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primaryHover : 'default')} !important;
  //   box-shadow: 0 0 0 2px ${(p) => (!p.disabled ? p.theme.colors.common.inputBoxShadow : 'default')} !important;
  // }
`;

export const StyledCFPassword = styled(Input.Password)`
  box-shadow: none;
  border-color: ${(p) => p.theme.colors.grayscale.grayBorder} !important;

  :hover {
    border-color: ${(p) => p.theme?.colorScheme?.primaryHover} !important;
  }

  :focus {
    border-color: ${(p) => p.theme?.colorScheme?.primaryHover} !important;
    box-shadow: 0 0 0 2px ${(p) => p.theme.colors.common.inputBoxShadow} !important;
  }
`;
