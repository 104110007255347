import { Avatar } from 'antd';
import styled from 'styled-components/macro';

import { CFMenuItem } from '../../../ui-core/V2/cfMenu/cfMenu';
import { CFNavMenuStyled } from '../../navBarV2/styles';
import { USER_MENU_SECTIONS } from './constants';

export const UserProfileWrapperStyled = styled.div<{
  selected?: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${(p) => (p.selected ? p.theme?.colorScheme?.primary : 'inherit')};
  border-radius: 5px;
  padding: ${(p) => (p.selected ? '5px 12px' : 0)};
  font-weight: ${(p) => (p.selected ? p.theme?.typography?.fontWeight?.mediumBold : 'inherit')};
  box-shadow: ${(p) => (p.selected ? ` 0px 5px 4px ${p.theme.colors.primary.blackOpacity}` : 'inherit')};
  color: ${(p) => (p.selected ? p.theme?.colors?.grayscale?.white : 'inherit')};
  font-weight: ${(p) => (p.selected ? p.theme?.typography?.fontWeight?.mediumBold : 'inherit')};
`;

export const UserProfileDetailWrapperStyled = styled.div<{
  selected?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UserProfileTopSectionStyled = styled.div``;

export const UserProfileBottomSectionStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserProfileBottomSectionItemStyled = styled.div<{ isNetworkUser?: boolean }>`
  font-size: ${(p) => (!p.isNetworkUser ? p.theme.typography.fontSize.mini : 'inherit')};
`;

export const UserProfileBottomSectionItemRoleStyled = styled.div`
  font-size: ${(p) => p.theme.typography.fontSize.mini};
  font-weight: ${(p) => p.theme.typography.fontWeight.mediumBold};
  padding-left: 5px;
`;

export const UserProfileTopSectionItemStyled = styled.div``;

export const UserProfileMenuItemStyled = styled(CFMenuItem)``;

export const UserMenuWrapperStyled = styled.div`
  background-color: ${(p) => p.theme.colors.common.pageBgColor};
  border-radius: 8px;
`;

export const CFNavUserMenuStyled = styled(CFNavMenuStyled)
  .attrs((props: { sectionType?: string }) => props)
  .withConfig({ shouldForwardProp: (props) => !['sectionType'].includes(props) })`
  max-height: 250px;
  overflow-y: auto;
  border-radius: ${(p) => {
    // Inteded override of border radius with important
    switch (p.sectionType) {
      case USER_MENU_SECTIONS.SELECTED_USER_PROFILE:
        return '8px 8px 0 0 !important';
      case USER_MENU_SECTIONS.OTHER_PROFILES:
        return '0 0 0 0 !important';
      case USER_MENU_SECTIONS.USER:
        return '0 0 8px 8px !important';
      default:
        return '8px !important';
    }
  }};
`;

export const SelectedUserProfileWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme?.colorScheme?.primary};
  border-radius: 5px;
  padding: 5px 25px;
  font-weight: ${(p) => p.theme?.typography?.fontWeight?.mediumBold};
  color: ${(p) => p.theme?.colors?.grayscale?.white};
  font-size: ${(p) => p.theme?.typography?.fontSize?.medium};
  line-height: 20px;
  margin-bottom: 10px;
`;

export const SelectedProfileAvatarStyled = styled(Avatar)`
  background-color: ${(p) => p.theme?.colorScheme?.primary};
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid;
`;

export const AvailableProfilesAvatarStyled = styled(Avatar)`
  background-color: ${(p) => p.theme.colors.primary.requiredText};
  margin-right: 10px;
`;

export const AvailableProfileTitleStyled = styled.div`
  padding-left: 27px;
  color: black;
  line-height: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: ${(p) => p.theme?.typography?.fontSize?.extendedMedium};
`;
