import { useForm, useWatch } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import { useEffect } from 'react';

import { DATE_FORMAT } from '../../globalConstants';
import { useReportCenterStore } from '../../pages/v2/reportCenter/store';
import { errorV2, success } from '../../ui-core/Notification';
import CFModal from '../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import { getReportTypeColumnInfo, SCHEDULE_REPORT_FORM_CONFIG } from './componentUtils';
import { SCHEDULE_REPORT_MESSAGES, SCHEDULE_REPORT_QUESTION_KEYS, SCHEDULE_REPORT_STORE_KEYS } from './constants';
import { IScheduleReportQuestion } from './interface';
import { useScheduleReportStore } from './store';
import { ReportTypeColumnInfoStyled, ScheduleReportFormStyled, ScheduleReportQuestionStyled } from './styles';
import { getLocalFromUtc, getScheduleReportTitle, handleScheduleReportFieldChange } from './utils';

export const ScheduleReport = () => {
  const [form] = useForm();

  const { clearCfModal } = useCFModalStore();

  const {
    scheduleReportElements,
    scheduleReportForm,
    scheduleReportLoading,
    isEditReportSchedule,
    disableScheduleReportButton,
    reportSchedule,
    createReportSchedule,
    updateReportSchedule,
    updateScheduleReportStoreByKey,
    clearScheduleReportStore,
    autoFillScheduleReportForm,
  } = useScheduleReportStore();

  const { fetchReportSchedules } = useReportCenterStore();

  useEffect(() => {
    updateScheduleReportStoreByKey(SCHEDULE_REPORT_STORE_KEYS.SCHEDULE_REPORT_FORM, form);
    updateScheduleReportStoreByKey(SCHEDULE_REPORT_STORE_KEYS.SCHEDULE_REPORT_ELEMENTS, SCHEDULE_REPORT_FORM_CONFIG());
    autoFillScheduleReportForm();

    if (
      isEditReportSchedule &&
      dayjs(
        getLocalFromUtc({
          utcDateString: reportSchedule?.schedule?.scheduleStartDate,
          utcTimeString: reportSchedule?.schedule?.preferredTimeOfDelivery,
        })?.localDateString,
      )?.format(DATE_FORMAT.YYYYMMDD) < dayjs()?.add(1, 'day').format(DATE_FORMAT.YYYYMMDD)
    ) {
      updateScheduleReportStoreByKey(SCHEDULE_REPORT_STORE_KEYS.DISABLE_SCHEDULE_REPORT_BUTTON, true);
    }

    return () => {
      clearScheduleReportStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportType = useWatch([SCHEDULE_REPORT_QUESTION_KEYS.REPORT_TYPE], scheduleReportForm);

  const handleOkScheduleReport = async () => {
    const isValid = await scheduleReportForm?.validateFields();
    try {
      if (isValid) {
        updateScheduleReportStoreByKey(SCHEDULE_REPORT_STORE_KEYS.SCHEDULE_REPORT_LOADING, true);
        if (isEditReportSchedule) {
          await updateReportSchedule();
          success(SCHEDULE_REPORT_MESSAGES.UPDATE_SCHEDULE_REPORT_SUCCESS, 6);
        } else {
          await createReportSchedule();
          success(SCHEDULE_REPORT_MESSAGES.SCHEDULE_REPORT_SUCCESS, 6);
        }
        await fetchReportSchedules();
        clearCfModal();
      }
    } catch (error: any) {
      if (isEditReportSchedule) {
        errorV2(error?.[0]?.errorMessage || SCHEDULE_REPORT_MESSAGES.UPDATE_SCHEDULE_REPORT_ERROR);
      } else {
        errorV2(error?.[0]?.errorMessage || SCHEDULE_REPORT_MESSAGES.SCHEDULE_REPORT_ERROR);
      }
    } finally {
      updateScheduleReportStoreByKey(SCHEDULE_REPORT_STORE_KEYS.SCHEDULE_REPORT_LOADING, false);
    }
  };

  return (
    <CFModal
      title={getScheduleReportTitle()}
      width={700}
      onOk={handleOkScheduleReport}
      okText={isEditReportSchedule ? 'Update' : 'Schedule'}
      disableCloseIcon={scheduleReportLoading}
      maskClosable={!scheduleReportLoading}
      cancelButtonProps={{ id: 'btn_schedule_report_cancel', disabled: scheduleReportLoading }}
      okButtonProps={{
        id: 'btn_schedule_ok',
        loading: scheduleReportLoading,
        disabled: scheduleReportLoading || disableScheduleReportButton,
      }}
    >
      {scheduleReportForm && (
        <ScheduleReportFormStyled
          id='form_schedule_report'
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          form={scheduleReportForm}
          disabled={scheduleReportLoading}
        >
          {scheduleReportElements &&
            scheduleReportElements.map((question: IScheduleReportQuestion) => {
              const handleChangeProp = handleScheduleReportFieldChange(question);
              return (
                <>
                  <ScheduleReportQuestionStyled
                    question={question}
                    key={`question-${question.dataIndex}`}
                    allQuestions={scheduleReportElements}
                    form={form}
                    {...handleChangeProp}
                  />
                  {
                    <>
                      {question.dataIndex === SCHEDULE_REPORT_QUESTION_KEYS.REPORT_TYPE && reportType && (
                        <ReportTypeColumnInfoStyled>{getReportTypeColumnInfo(reportType)}</ReportTypeColumnInfoStyled>
                      )}
                    </>
                  }
                </>
              );
            })}
        </ScheduleReportFormStyled>
      )}
    </CFModal>
  );
};
