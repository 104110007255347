import { IPaymentIframe } from './interface';

export const IFRAME_PAYMENT_MESSAGES = {
  CONFIRM_PAYMENT_ERROR: 'Something went wrong while confirming the payment.',
  TRANSACTION_ERROR: 'The transaction has failed or declined. Please try again later.',
};

export const PAYMENT_IFRAME_STORE_KEYS: { [key: string]: keyof IPaymentIframe } = {
  CONFIRM_IFRAME_PAYMENT_LOADING: 'confirmIframePaymentLoading',
};
