export const GA_ACTION = {
  SWITCH_CODE_TO_SIC: 'switchToSicBtn_click',
  SWITCH_CODE_TO_NAICS: 'switchToNaicsBtn_click',
};

export const GA_LABEL = {
  SWITCH_CODE_TO_SIC: 'Switch to SIC toggle button clicked',
  SWITCH_CODE_TO_NAICS: 'Switch to NAICS toggle button clicked',
};

export const ACTION_TYPE = {
  NAME: 'New Quote Form Field Store',
  ANONYMOUS_ACTION: 'NEW_QUOTE_STORE',
  SET_NEW_QUOTE_CARRIERS: 'newQuote/carriersStore',
  SET_NEW_QUOTE_CARRIERS_ERROR: 'newQuote/carriersStore/error',
  SET_NEW_QUOTE_POLICY_TYPE: 'newQuote/policyTypeStore',
  SET_NEW_QUOTE_POLICY_TYPE_ERROR: 'newQuote/policyTypeStore/error',
  SET_NEW_QUOTE_SUPPORTED_CARRIERS: 'newQuote/supportedCarriersStore',
  SET_NEW_QUOTE_SUPPORTED_CARRIERS_ERROR: 'newQuote/supportedCarriersStore/error',
  SET_NEW_QUOTE_ACCOUNTS: 'newQuote/allAccountsStore',
  SET_NEW_QUOTE_ACCOUNTS_ERROR: 'newQuote/allAccountsStore/error',
  SET_NEW_QUOTE_NAICS_CODE: 'newQuote/naicsCodes',
  SET_NEW_QUOTE_NAICS_CODE_ERROR: 'newQuote/naicsCodes/error',
  SET_NEW_QUOTE_SIC_CODE: 'newQuote/sicCodes',
  SET_NEW_QUOTE_SIC_CODE_ERROR: 'newQuote/sicCodes/error',
  SET_NEW_QUOTE_APPETITE: 'newQuote/checkAppetite',
  SET_NEW_QUOTE_APPETITE_ERROR: 'newQuote/checkAppetite/error',
  SET_NEW_QUOTE_BROKER_CONFIGS: 'newQuote/brokerConfigs',
  SET_ACCOUNT_ID: 'newQuote/setAccountId',
  SET_NEW_QUOTE_BROKER_CONFIGS_ERROR: 'newQuote/brokerConfigs/error',
  FETCH_APPLICATION_BY_ID_LOADING: 'newQuote/fetchApplicationById/loading',
  FETCH_APPLICATION_BY_ID: 'newQuote/fetchApplicationById',
  FETCH_APPLICATION_BY_ID_ERROR: 'newQuote/fetchApplicationById/error',
  CREATE_NEW_APPLICATION_FROM_ACCORD_ERROR: 'newQuote/createNewApplicationFromAcord/error',
  CREATE_NEW_APPLICATION_FROM_ACCORD_LOADING: 'newQuote/createNewApplicationFromAcord/loading',
  CREATE_NEW_APPLICATION_FROM_ACCORD: 'newQuote/createNewApplicationFromAcord',
  CLONE_APPLICATION_ERROR: 'newQuote/createNewApplicationFromAcord/error',
  CLONE_APPLICATION_LOADING: 'newQuote/createNewApplicationFromAcord/loading',
  CLONE_APPLICATION: 'newQuote/createNewApplicationFromAcord',
  SET_PRESET_ACCOUNT_ID: 'newQuote/presetAccountId',
  SET_SUPPORTED_CARRIERS_LOADING: 'newQuote/supportedCarriersStore/loading',
  SET_INDUSTRY_CODE_TYPE: 'newQuote/supportedCarriersStore/setIndustryCodeType',
  UPDATE_NEW_QUOTE_STORE_BY_KEY: 'newQuote/updateNewQuoteStoreByKey',
  CLEAR_NEW_QUOTE_STORE: 'newQuote/clearNewQuoteStore',
};
