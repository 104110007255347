import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import Anchor from '../../../ui-core/V2/anchor/anchor';
import { CFMenuItem } from '../../../ui-core/V2/cfMenu/cfMenu';
import { useConfigurationStore } from '../../configuration/store';
import { CFNavMenuStyled } from '../../navBarV2/styles';
import { getSettingsMenu } from './utils';

const SettingsMenu = () => {
  const { userTheme } = useConfigurationStore();
  const location = useLocation();

  return (
    <CFNavMenuStyled
      id='nav_menu_settings'
      selectedKeys={[location.pathname]}
      backgroundColor={userTheme.colors.grayscale.white}
    >
      {getSettingsMenu().map((item) => {
        return (
          <Fragment key={item.id}>
            {item.visible && (
              <CFMenuItem icon={<item.icon />} key={item.to}>
                <Anchor id={item.label} href={item.to}>
                  {item.label}
                </Anchor>
              </CFMenuItem>
            )}
          </Fragment>
        );
      })}
    </CFNavMenuStyled>
  );
};

export default SettingsMenu;
