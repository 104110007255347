import { Carrier, PolicyType } from '@coverforce-platform/cf-common-types';

import { difference } from '../../utils/lodash';
import { IAppetiteField } from './interface';

const filterEitherOneSelectCarrier = ({
  addedCarrier,
  options,
  newSelectedCarriers,
  otherGroupCarriers,
}: {
  addedCarrier?: string;
  options: Carrier[];
  newSelectedCarriers: string[];
  otherGroupCarriers: string[];
}) => {
  if (addedCarrier && options.includes(addedCarrier as Carrier)) {
    return {
      currentGroupCarriers: [
        ...newSelectedCarriers.filter((carrier) => !options.includes(carrier as Carrier)),
        addedCarrier,
      ],
      otherGroupCarriers: [...otherGroupCarriers.filter((carrier) => !options.includes(carrier as Carrier))],
    };
  }
  return { currentGroupCarriers: newSelectedCarriers, otherGroupCarriers };
};

export const getCheckBoxValues = ({
  oldSelectedCarriers,
  newSelectedCarriers,
  policyType,
  otherGroupCarriers,
}: {
  oldSelectedCarriers: string[];
  newSelectedCarriers: string[];
  policyType?: PolicyType;
  otherGroupCarriers: string[];
}) => {
  if (policyType === PolicyType.WC) {
    const addedCarriers = difference(newSelectedCarriers, oldSelectedCarriers);
    return filterEitherOneSelectCarrier({
      addedCarrier: addedCarriers?.[0],
      newSelectedCarriers,
      options: [Carrier.ACCIDENTFUND, Carrier.COMPWEST],
      otherGroupCarriers,
    });
  }
  return { otherGroupCarriers, currentGroupCarriers: newSelectedCarriers };
};

const getInAppetiteDefaultValue = (inAppetiteCarriers: IAppetiteField[]) => {
  const selectedCarrierArr = inAppetiteCarriers.map((item) => {
    return item?.value;
  });
  return selectedCarrierArr;
};

export const getDefaultCarrierInAppetite = ({
  policyType,
  inAppetiteCarriers,
}: {
  policyType?: PolicyType;
  inAppetiteCarriers: IAppetiteField[];
}) => {
  const carriers = getInAppetiteDefaultValue(inAppetiteCarriers);
  if (policyType === PolicyType.WC && carriers.includes(Carrier.ACCIDENTFUND) && carriers.includes(Carrier.COMPWEST)) {
    return carriers.filter((carrier) => carrier !== Carrier.ACCIDENTFUND);
  }
  return carriers;
};
