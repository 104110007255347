import {
  IGetAccountsInput,
  IGetAccountsOutput,
  IGetAllPolicyTypesOutput,
} from '@coverforce-platform/cf-common-api-model';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { INDUSTRY_CODES } from '../../constants/appetite';
import { getAccounts as requestGetAccounts } from '../../externalServices/V2/accountsV2';
import { getAllNaicsCodes, getAllSicCodes } from '../../externalServices/V2/applicationV2';
import { getAllPolicyTypes as requestGetPolicyTypes } from '../../externalServices/V2/carrierRankingV2';
import { useCarrierConfigStore } from '../../pages/v2/carrierConfig/store';
import { parseIndustryCodesToLabelValue } from '../../utils/parseIndustryCodesToLabelValue';
import { ACTION_TYPE } from './constants';
import { INewQuoteFormFieldStore } from './interface';
// Action name
const {
  SET_NEW_QUOTE_POLICY_TYPE,
  SET_NEW_QUOTE_POLICY_TYPE_ERROR,
  SET_NEW_QUOTE_ACCOUNTS,
  SET_NEW_QUOTE_ACCOUNTS_ERROR,
  CLEAR_NEW_QUOTE_STORE,
  SET_NEW_QUOTE_NAICS_CODE,
  SET_NEW_QUOTE_NAICS_CODE_ERROR,
  SET_INDUSTRY_CODE_TYPE,
  SET_NEW_QUOTE_SIC_CODE,
  SET_NEW_QUOTE_SIC_CODE_ERROR,
} = ACTION_TYPE;

const initPolicyTypeStore = {
  policyTypesData: [],
  policyTypesLoading: true,
  policyTypesError: null,
};

const initAllAccountsStore = {
  allAccountsData: [],
  allAccountsLoading: true,
  allAccountsError: null,
};

const initNaicsCodesStore = {
  naicsCodesData: [],
  naicsCodesLoading: true,
  naicsCodesError: null,
};
const initSicCodesStore = {
  sicCodesData: [],
  sicCodesLoading: true,
  sicCodesError: null,
};

export const useNewQuoteFormFieldStore = create<INewQuoteFormFieldStore>()(
  devtools(
    (set, get) => ({
      policyTypeStore: {
        ...initPolicyTypeStore,
      },

      allAccountsStore: { ...initAllAccountsStore },
      naicsCodesStore: { ...initNaicsCodesStore },
      sicCodesStore: { ...initSicCodesStore },
      accountId: undefined,

      selectedIndustryCodeType: INDUSTRY_CODES.NAICS.TYPE,

      setAccountId: (accountId) => {
        set({ accountId }, false, ACTION_TYPE.SET_ACCOUNT_ID);
      },

      setSelectedIndustryCodeType: (industryCodeType) => {
        set({ selectedIndustryCodeType: industryCodeType }, false, SET_INDUSTRY_CODE_TYPE);
      },

      fetchPolicyType: async () => {
        try {
          const allowedPoliciesForAgency = useCarrierConfigStore.getState().allowedPoliciesForAgency;
          const response: IGetAllPolicyTypesOutput = await requestGetPolicyTypes();

          const allowedPolicyTypes = allowedPoliciesForAgency?.map((item) => item.policyTypeId);

          const filteredPolicyTypes = response?.policyTypes?.filter((policyType) => {
            if (allowedPolicyTypes?.length > 0) {
              return allowedPolicyTypes?.includes(policyType.policyTypeId) ? true : false;
            }
            return true;
          });

          set(
            {
              policyTypeStore: {
                policyTypesData: filteredPolicyTypes,
                policyTypesError: null,
                policyTypesLoading: false,
              },
            },
            false,
            SET_NEW_QUOTE_POLICY_TYPE,
          );
        } catch (error: any) {
          set(
            { policyTypeStore: { policyTypesData: [], policyTypesError: error, policyTypesLoading: false } },
            false,
            SET_NEW_QUOTE_POLICY_TYPE_ERROR,
          );
        }
      },

      fetchAllAccounts: async (params: IGetAccountsInput) => {
        try {
          const response: IGetAccountsOutput = await requestGetAccounts({
            agencyId: params?.agencyId,
          });
          const fetchedAccounts = response?.accounts.map((account) => ({
            accountId: account?.accountId,
            accountName: account?.accountName,
            accountPrimaryState: account?.primaryAddress?.state,
          }));
          set(
            {
              allAccountsStore: {
                allAccountsData: fetchedAccounts,
                allAccountsError: null,
                allAccountsLoading: false,
              },
            },
            false,
            SET_NEW_QUOTE_ACCOUNTS,
          );
        } catch (error: any) {
          set(
            { allAccountsStore: { allAccountsData: [], allAccountsError: error, allAccountsLoading: false } },
            false,
            SET_NEW_QUOTE_ACCOUNTS_ERROR,
          );
        }
      },

      fetchNaicsCodes: async () => {
        const { naicsCodesStore } = get();
        if (naicsCodesStore.naicsCodesData.length > 0) {
          return;
        }
        try {
          const response = await getAllNaicsCodes();
          set(
            {
              naicsCodesStore: {
                naicsCodesData: parseIndustryCodesToLabelValue({
                  naicsCodeInfos: response?.naicsCodeInfos,
                  isValueNaicsExtendedId: true,
                }),
                naicsCodesLoading: false,
                naicsCodesError: null,
              },
            },
            false,
            SET_NEW_QUOTE_NAICS_CODE,
          );
        } catch (error: any) {
          set(
            {
              naicsCodesStore: {
                naicsCodesData: [],
                naicsCodesLoading: false,
                naicsCodesError: error,
              },
            },
            false,
            SET_NEW_QUOTE_NAICS_CODE_ERROR,
          );
        }
      },

      fetchSicCodes: async () => {
        const { sicCodesStore } = get();
        if (sicCodesStore.sicCodesData.length > 0) {
          return;
        }
        try {
          const response = await getAllSicCodes();
          set(
            {
              sicCodesStore: {
                sicCodesData: parseIndustryCodesToLabelValue({ sicCodeInfos: response.sicCodeInfos }),
                sicCodesLoading: false,
                sicCodesError: null,
              },
            },
            false,
            SET_NEW_QUOTE_SIC_CODE,
          );
        } catch (error: any) {
          set(
            {
              sicCodesStore: {
                sicCodesData: [],
                sicCodesLoading: false,
                sicCodesError: error,
              },
            },
            false,
            SET_NEW_QUOTE_SIC_CODE_ERROR,
          );
        }
      },

      updateNewQuoteFormFieldByKey: async (storeKey: keyof INewQuoteFormFieldStore, value: any) =>
        set(() => ({ [storeKey]: value }), false, ACTION_TYPE.UPDATE_NEW_QUOTE_STORE_BY_KEY),

      clearNewQuoteFormFieldStore: () => {
        set(
          {
            policyTypeStore: {
              ...initPolicyTypeStore,
            },
            allAccountsStore: { ...initAllAccountsStore },
            naicsCodesStore: { ...initNaicsCodesStore },
            sicCodesStore: { ...initSicCodesStore },
            accountId: undefined,
            selectedIndustryCodeType: INDUSTRY_CODES.NAICS.TYPE,
          },
          false,
          CLEAR_NEW_QUOTE_STORE,
        );
      },
    }),
    { name: ACTION_TYPE.NAME, anonymousActionType: ACTION_TYPE.ANONYMOUS_ACTION },
  ),
);
