import { useMemo } from 'react';

import Input from '../../ui-core/V2/cfInput/cfInput';
import DependentQuestion from '../dependentQuestion/dependentQuestion';
import { QUESTION_ELEMENTS } from './constants';
import { IApplicationQuestionItem } from './interface';
import { ApplicationQuestionFormItemStyled, TooltipStyled } from './styles';

const ApplicationQuestion = (props: IApplicationQuestionItem) => {
  const {
    question,
    onChange,
    onMultiModeChange,
    form,
    showStatus,
    prefixDataIndex = [],
    disabled = false,
    ...rest
  } = props;
  const {
    dataIndex,
    name,
    rules,
    type,
    tooltip,
    isChildRender,
    hasParent,
    hidden = false,
    ...restApplicationQuestionProperty
  } = question;

  const Element = useMemo(() => QUESTION_ELEMENTS[type?.toLowerCase()] || Input, [type]);

  return (
    <>
      {!(hasParent && !isChildRender) && (
        <ApplicationQuestionFormItemStyled
          key={`form-updater-item-${dataIndex}`}
          noStyle
          shouldUpdate
          id={`form_item_updated_${dataIndex}`}
        >
          {({ getFieldValue }: { getFieldValue: Function }) => (
            <>
              <ApplicationQuestionFormItemStyled
                {...rest}
                key={`form-item-${dataIndex}`}
                label={name}
                name={prefixDataIndex.concat([dataIndex || ''])}
                rules={rules}
                id={`form_item_${dataIndex}`}
                tooltip={
                  tooltip && (
                    <TooltipStyled
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: `${tooltip}`,
                      }}
                    />
                  )
                }
                hidden={hidden}
              >
                <Element
                  {...restApplicationQuestionProperty}
                  dataStoreKey={prefixDataIndex.concat([dataIndex || ''])}
                  disabled={disabled}
                  data={props.question}
                  onMultiModeChange={onMultiModeChange}
                  onChange={onChange}
                  form={form}
                />
              </ApplicationQuestionFormItemStyled>
              <DependentQuestion
                key={`form-updater-item-${dataIndex}`}
                question={props.question}
                getFieldValue={getFieldValue}
                allQuestions={props.allQuestions}
                form={form}
                showStatus={showStatus}
                prefixDataIndex={prefixDataIndex}
                disabled={disabled}
                hidden={hidden}
                onChange={onChange}
              />
            </>
          )}
        </ApplicationQuestionFormItemStyled>
      )}
    </>
  );
};

export default ApplicationQuestion;
