import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { INDUSTRY_CODES } from '../../constants/appetite';
import { USState } from '../../globalConstants';
import { useAccountsStore } from '../../pages/v2/accounts/store';
import { useProfileV2Store } from '../../pages/v2/profile/store';
import CFFormItem from '../../ui-core/V2/cfFormItem/cfFormItem';
import { useCFModalStore } from '../../ui-core/V2/cfModal/store';
import CFSelect from '../../ui-core/V2/cfSelect/cfSelect';
import { pushEvent } from '../../utils/googleAnalytics';
import { filterOptionNaicsCodes, getIndustryCodeLink } from '../../utils/industrySelection/industry';
import { sortBy } from '../../utils/sortBy';
import { ACCORD_FIELDS_STORE_KEYS } from '../accordFieldsForNewQuote/constants';
import { useAccordFieldsForNewQuoteStore } from '../accordFieldsForNewQuote/store';
import { ACCORD_CARRIER_LIST_STORE_KEYS } from '../appetiteCarrierListContainer/constants';
import { useAppetiteCarrierListContainerStore } from '../appetiteCarrierListContainer/store';
import { FORM_CONFIG } from '../modals/newQuoteV2/constants';
import { useNewQuoteStore } from '../modals/newQuoteV2/store';
import { getAppetiteDetails, getGACategory, getStateFromAccountId } from '../modals/newQuoteV2/utils';
import { GA_ACTION, GA_LABEL } from './constants';
import { useNewQuoteFormFieldStore } from './store';
import { IndustryCodeLinkWrapperStyled, SubInfoStyled, SubInfoWrapperStyled } from './styles';

const NewQuoteFormFieldFormField = () => {
  const {
    presetAccountId,
    fetchCarrierRankingByPolicyTypeId,
    clonedApplicationId,
    newQuoteModalForm: form,
    clonedApplication,
  } = useNewQuoteStore();

  const {
    policyTypeStore,
    setSelectedIndustryCodeType,
    allAccountsStore,
    naicsCodesStore,
    sicCodesStore,
    fetchNaicsCodes,
    fetchSicCodes,
    setAccountId,
    selectedIndustryCodeType,
  } = useNewQuoteFormFieldStore();
  const { updateAppetiteCarrierListContainerByKey } = useAppetiteCarrierListContainerStore();
  const { accountsField, policyTypeField, stateField, industryCodeField, acordField } =
    FORM_CONFIG(selectedIndustryCodeType);
  const { allAccountsData, allAccountsLoading } = allAccountsStore;
  const { clearCfModal } = useCFModalStore();
  const { openNewAccountDrawer } = useAccountsStore();
  const { userData } = useProfileV2Store();
  const { updateAccordFieldsByKey } = useAccordFieldsForNewQuoteStore();
  const { policyTypesData, policyTypesLoading } = policyTypeStore;

  // miscellaneous
  const navigate = useNavigate();

  const { industryCodesLoading, industryCodesData } = useMemo(() => {
    return {
      industryCodesLoading:
        selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE
          ? naicsCodesStore.naicsCodesLoading
          : sicCodesStore.sicCodesLoading,
      industryCodesData:
        selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE
          ? naicsCodesStore.naicsCodesData
          : sicCodesStore.sicCodesData,
    };
  }, [
    naicsCodesStore.naicsCodesData,
    naicsCodesStore.naicsCodesLoading,
    selectedIndustryCodeType,
    sicCodesStore.sicCodesData,
    sicCodesStore.sicCodesLoading,
  ]);

  const handleAccountChange = (selectedAccountId: string) => {
    const stateInfo = getStateFromAccountId(allAccountsData, selectedAccountId);
    form?.setFieldValue(stateField.name, stateInfo?.code);
    if (stateInfo?.code) {
      form?.validateFields([stateField.name]);
    }
    setAccountId(selectedAccountId);
    getAppetiteDetails();
  };

  const handleAddNewAccount = useCallback(() => {
    clearCfModal();
    navigate('/accounts');
    openNewAccountDrawer();
  }, [navigate, clearCfModal, openNewAccountDrawer]);

  const handleChangePolicyType = useCallback(
    async (value) => {
      form?.setFieldValue(acordField.name, false);
      await fetchCarrierRankingByPolicyTypeId({
        agentId: userData!.agentId,
        policyTypeId: value,
      });
      updateAccordFieldsByKey(ACCORD_FIELDS_STORE_KEYS.ACCORD_UPLOAD, false);
      updateAppetiteCarrierListContainerByKey(ACCORD_CARRIER_LIST_STORE_KEYS.IN_APPETITE_CHECKBOX_GROUP, []);

      updateAppetiteCarrierListContainerByKey(ACCORD_CARRIER_LIST_STORE_KEYS.NOT_IN_APPETITE_CHECKBOX_GROUP, []);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, fetchCarrierRankingByPolicyTypeId],
  );

  const handleIndustryCodeChange = () => {
    updateAppetiteCarrierListContainerByKey(ACCORD_CARRIER_LIST_STORE_KEYS.IN_APPETITE_CHECKBOX_GROUP, []);
    updateAppetiteCarrierListContainerByKey(ACCORD_CARRIER_LIST_STORE_KEYS.NOT_IN_APPETITE_CHECKBOX_GROUP, []);
    getAppetiteDetails();
  };

  const handleChangeIndustryType = useCallback(() => {
    setSelectedIndustryCodeType(
      selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE ? INDUSTRY_CODES.SIC.TYPE : INDUSTRY_CODES.NAICS.TYPE,
    );
    switch (selectedIndustryCodeType) {
      case INDUSTRY_CODES.NAICS.TYPE:
        form?.setFieldValue(INDUSTRY_CODES.NAICS.KEY, undefined);
        fetchSicCodes();
        pushEvent({
          category: getGACategory(),
          action: GA_ACTION.SWITCH_CODE_TO_SIC,
          label: GA_LABEL.SWITCH_CODE_TO_SIC,
        });
        break;
      case INDUSTRY_CODES.SIC.TYPE:
        form?.setFieldValue(INDUSTRY_CODES.SIC.KEY, undefined);
        fetchNaicsCodes();
        pushEvent({
          category: getGACategory(),
          action: GA_ACTION.SWITCH_CODE_TO_NAICS,
          label: GA_LABEL.SWITCH_CODE_TO_NAICS,
        });
        break;

      default:
        break;
    }
  }, [fetchNaicsCodes, fetchSicCodes, form, selectedIndustryCodeType, setSelectedIndustryCodeType]);

  const handleStateChange = () => {
    updateAppetiteCarrierListContainerByKey(ACCORD_CARRIER_LIST_STORE_KEYS.IN_APPETITE_CHECKBOX_GROUP, []);
    updateAppetiteCarrierListContainerByKey(ACCORD_CARRIER_LIST_STORE_KEYS.NOT_IN_APPETITE_CHECKBOX_GROUP, []);
    getAppetiteDetails();
  };

  const getIndustryCodesInfo = () => {
    const formAnswers = form?.getFieldsValue();
    const { naicsCode, sicCode } = formAnswers || {};
    return { naicsCode, sicCode, isIndustryCodeSelected: naicsCode || sicCode };
  };

  const getIndustryCodeLinkNewQuote = () => {
    const { naicsCode, sicCode } = getIndustryCodesInfo();
    return getIndustryCodeLink({
      naicsCode: naicsCode?.split('-')[0]?.trim(),
      sicCode: sicCode?.split('-')[0]?.trim(),
    });
  };

  const isDisabledModificationsOfStateAndNaics = useMemo(
    () =>
      Boolean(
        clonedApplication?.data?.basicBusinessDetails?.naicsExtendedId &&
          clonedApplication?.data?.locationDetails?.[0]?.address?.state,
      ),
    [clonedApplication?.data?.basicBusinessDetails?.naicsExtendedId, clonedApplication?.data?.locationDetails],
  );

  return (
    <>
      <CFFormItem
        id={accountsField.id}
        name={accountsField.name}
        label={accountsField.label}
        rules={accountsField.rules}
      >
        <CFSelect
          id='sel_for_account'
          placeholder={accountsField.placeholder}
          key={`sel_for_account_${allAccountsLoading}`}
          loading={allAccountsLoading}
          onChange={handleAccountChange}
          options={allAccountsData?.map((item) => ({ label: item?.accountName, value: item?.accountId }))}
          defaultValue={!allAccountsLoading ? presetAccountId : undefined}
          disabled={Boolean(clonedApplicationId)}
        />
      </CFFormItem>
      {!clonedApplicationId && (
        <SubInfoWrapperStyled>
          <SubInfoStyled id='new_account' onClick={handleAddNewAccount}>
            Add new Account
          </SubInfoStyled>
        </SubInfoWrapperStyled>
      )}
      <CFFormItem
        id={policyTypeField.id}
        name={policyTypeField.name}
        label={policyTypeField.label}
        rules={policyTypeField.rules}
      >
        <CFSelect
          id='sel_policy_type'
          placeholder={policyTypeField.placeholder}
          onChange={handleChangePolicyType}
          loading={policyTypesLoading}
          options={sortBy({
            arr: policyTypesData?.map((item) => ({
              label: item?.policyTypeDisplayName,
              value: item?.policyTypeId,
            })),
            sortKey: 'label',
          })}
        />
      </CFFormItem>
      <>
        <CFFormItem
          id={industryCodeField.id}
          label={industryCodeField.label}
          key={industryCodeField.id}
          rules={industryCodeField.rules}
          name={industryCodeField.name}
        >
          <CFSelect
            id='sel_industryCodes'
            placeholder={industryCodeField.placeholder}
            filterOption={filterOptionNaicsCodes}
            onChange={handleIndustryCodeChange}
            loading={industryCodesLoading}
            getPopupContainer={() => document.getElementById(industryCodeField.id) || document.body}
            options={industryCodesData}
            disabled={isDisabledModificationsOfStateAndNaics}
          />
        </CFFormItem>
        <SubInfoWrapperStyled
          style={{ justifyContent: getIndustryCodesInfo()?.isIndustryCodeSelected ? 'space-between' : 'flex-end' }}
        >
          {getIndustryCodesInfo()?.isIndustryCodeSelected && (
            <IndustryCodeLinkWrapperStyled>{getIndustryCodeLinkNewQuote()}</IndustryCodeLinkWrapperStyled>
          )}
          {!isDisabledModificationsOfStateAndNaics && (
            <SubInfoStyled id='industry_code_type' onClick={handleChangeIndustryType}>
              Switch to{' '}
              {selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE
                ? INDUSTRY_CODES.SIC.TYPE
                : INDUSTRY_CODES.NAICS.TYPE}
            </SubInfoStyled>
          )}
        </SubInfoWrapperStyled>
        <CFFormItem
          id={stateField.id}
          label={stateField.label}
          key={stateField.id}
          rules={stateField.rules}
          name={stateField.name}
        >
          <CFSelect
            id='sel_state'
            placeholder={stateField.placeholder}
            onChange={handleStateChange}
            getPopupContainer={() => document.getElementById(stateField.id) || document.body}
            options={Object.entries(USState).map(([value, label]) => ({ value, label }))}
            disabled={isDisabledModificationsOfStateAndNaics}
          />
        </CFFormItem>
      </>
    </>
  );
};

export default NewQuoteFormFieldFormField;
