import styled from 'styled-components/macro';

export const UnauthorisedWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const UnauthorisedDescriptionWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: ${(p) => p.theme.colors.common.pageBgColor};
`;

export const UnauthorisedDescriptionStyled = styled.span`
  font-size: ${(p) => p.theme.typography.fontSize.mediumLarge};
`;
