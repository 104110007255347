import { IApplicationInfo, IBuildingInfo } from '@coverforce-platform/cf-common-api-model';
import {
  ApplicationFieldPathEnum,
  ApplicationFieldRequirementLevel,
  Carrier,
  USState,
} from '@coverforce-platform/cf-common-types';
import { FormInstance } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import dayjs from 'dayjs';

import { removeUndefinedProperty } from '../../helpers/Utils';
import { useApplicationStore } from '../../pages/v2/application/store';
import {
  getRequirementLevelOfField,
  getVisibleFormElementKeys,
  modifyVisibilityOfFields,
} from '../../pages/v2/application/utils';
import { optionType } from '../../types';
import { IFormInstance } from '../../ui-core/V2/cfForm/cfForm';
import { getValidDate } from '../../utils/getValidDate';
import { getValidDropdownValue } from '../../utils/getValidDropdownValue';
import { cloneDeep } from '../../utils/lodash';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import { IBopQuestion } from '../bopBusinessQuestion/interface';
import { useLocationDetailsStore } from '../locationDetails/store';
import {
  BUILDING_CONSTRUCTION_FIELDS,
  BUILDING_INFORMATION_QUESTION,
  BUILDING_STORE_KEYS,
  BURGER_ALARM_FIELDS,
  ERROR_MESSAGES,
  QUESTION_KEYS,
  ROOF_CONSTRUCTION_FIELDS,
} from './constants';
import { useBuildingInformationStore } from './store';

export const setBuildingInformationInForm = async (
  locationBuildingInformation: IBuildingInfo | null | undefined,
  state: USState,
  form?: IFormInstance,
) => {
  locationBuildingInformation &&
    form?.setFieldsValue({
      ...locationBuildingInformation,
      constructionTypeCode: getValidDropdownValue(
        locationBuildingInformation?.constructionTypeCode,
        BUILDING_CONSTRUCTION_FIELDS,
      ),
      roofType: getValidDropdownValue(locationBuildingInformation?.roofType, ROOF_CONSTRUCTION_FIELDS),
      burglarAlarmTypeCode: getValidDropdownValue(
        locationBuildingInformation?.burglarAlarmTypeCode,
        BURGER_ALARM_FIELDS,
      ),

      totalStories: locationBuildingInformation?.totalStories,
      isOwner: locationBuildingInformation?.isOwner ? 'true' : 'false',
      roofUpdateYear: getValidDate(locationBuildingInformation?.roofUpdateYear),
      yearBuilt: getValidDate(locationBuildingInformation?.yearBuilt),
      electricalWiringUpdateYear: getValidDate(locationBuildingInformation?.electricalWiringUpdateYear),
      plumbingUpdateYear: getValidDate(locationBuildingInformation?.plumbingUpdateYear),
      heatingUpdateYear: getValidDate(locationBuildingInformation?.heatingUpdateYear),
      //coverages
      buildingCoverage: locationBuildingInformation?.coverages?.buildingCoverage,
      businessPersonalPropertyCoverage: locationBuildingInformation?.coverages?.businessPersonalPropertyCoverage,
    });

  const { applicationData } = useApplicationStore.getState();

  const carriers = applicationData?.basicBusinessDetails?.carrierIndustryCodes?.map((code) => code.carrierId);
  if (carriers && carriers.includes(Carrier.LIBERTYMUTUAL)) {
    const { getIndustryClassCodeDetails } = useBuildingInformationStore.getState();
    const cspOptions = await getIndustryClassCodeDetails({ carrierId: Carrier.LIBERTYMUTUAL, state });

    const selectedBuildingCoverageClassId = cspOptions?.find(
      (el) =>
        el.value ===
        locationBuildingInformation?.coverages?.buildingAndBusinessCoverageDetails?.[0]?.buildingCoverageClassId,
    )?.value;

    const selectedBusinessPersonalPropertyCoverageClassId = cspOptions?.find(
      (el) =>
        el.value ===
        locationBuildingInformation?.coverages?.buildingAndBusinessCoverageDetails?.[0]
          ?.businessPersonalPropertyCoverageClassId,
    )?.value;

    form?.setFieldsValue({
      buildingCoverageClassId: selectedBuildingCoverageClassId,
      businessPersonalPropertyCoverageClassId: selectedBusinessPersonalPropertyCoverageClassId,
    });
  }
};

export const getBuildingInformationByForm = (form?: IFormInstance) => {
  const formData = form?.getFieldsValue();
  const buildingInformation = {
    annualSales:
      formData?.annualSales?.toString()?.trim()?.length > 0 ? Number(formData?.annualSales || '') : undefined,
    areaOccupied:
      formData?.areaOccupied?.toString()?.trim()?.length > 0 ? Number(formData?.areaOccupied || '') : undefined,
    areaOccupiedByOthers:
      formData?.areaOccupiedByOthers?.toString()?.trim()?.length > 0
        ? Number(formData?.areaOccupiedByOthers || '')
        : undefined,
    burglarAlarmTypeCode:
      formData?.burglarAlarmTypeCode?.toString()?.trim()?.length > 0 ? formData?.burglarAlarmTypeCode : undefined,
    constructionTypeCode:
      formData?.constructionTypeCode?.toString()?.trim()?.length > 0 ? formData?.constructionTypeCode : undefined,
    isOwner:
      formData?.isOwner?.toString()?.trim()?.length > 0 ? (formData?.isOwner === 'true' ? true : false) : undefined,
    roofType: formData?.roofType?.toString()?.trim()?.length > 0 ? formData?.roofType : undefined,
    roofUpdateYear: getValidDate(formData?.roofUpdateYear)?.year()?.toString(),
    sprinkleredPercentage:
      formData?.sprinkleredPercentage?.toString()?.trim()?.length > 0
        ? Number(formData?.sprinkleredPercentage || '')
        : undefined,
    totalArea: formData?.totalArea?.toString()?.trim()?.length > 0 ? Number(formData?.totalArea || '') : undefined,
    totalStories:
      formData?.totalStories?.toString()?.trim()?.length > 0 ? Number(formData?.totalStories || '') : undefined,
    yearBuilt: getValidDate(formData?.yearBuilt)?.year()?.toString(),
    electricalWiringUpdateYear: getValidDate(formData?.electricalWiringUpdateYear)?.year()?.toString(),
    plumbingUpdateYear: getValidDate(formData?.plumbingUpdateYear)?.year()?.toString(),
    heatingUpdateYear: getValidDate(formData?.heatingUpdateYear)?.year()?.toString(),
    coverages:
      formData?.buildingCoverage?.toString()?.trim()?.length > 0 ||
      formData?.businessPersonalPropertyCoverage?.toString()?.trim()?.length > 0 ||
      formData?.buildingCoverageClassId ||
      formData?.businessPersonalPropertyCoverageClassId
        ? {
            buildingCoverage:
              formData?.buildingCoverage?.toString()?.trim()?.length > 0 ? formData?.buildingCoverage : undefined,
            businessPersonalPropertyCoverage:
              formData?.businessPersonalPropertyCoverage?.toString()?.trim()?.length > 0
                ? formData?.businessPersonalPropertyCoverage
                : undefined,
            buildingAndBusinessCoverageDetails:
              formData?.buildingCoverageClassId || formData?.businessPersonalPropertyCoverageClassId
                ? [
                    {
                      carrier: Carrier.LIBERTYMUTUAL,
                      buildingCoverageClassId: formData?.buildingCoverageClassId,
                      businessPersonalPropertyCoverageClassId: formData?.businessPersonalPropertyCoverageClassId,
                    },
                  ]
                : [],
          }
        : undefined,
  };
  // In case of CGL, if old application is quoted then there is no buildingInformation in payload even not buildingInformation:{}, for that reason this check added so location update call not send with buildingInformation:{}.
  return Object.keys(removeUndefinedProperty(buildingInformation))?.length > 0
    ? {
        buildingInformation,
      }
    : {};
};

export const clearError = ({ form, field }: { form?: FormInstance; field: string }) => {
  form?.setFields([
    {
      name: field,
      errors: [],
    },
  ]);
};

export const setError = ({ form, field, error }: { form?: FormInstance; field: string; error: string }) => {
  form?.setFields([
    {
      name: field,
      errors: [error],
    },
  ]);
};

export const handleBopFieldChange = (event: any, data: any) => {
  const { currentSubStepIndex: selectedLocationIndex } = useApplicationStore.getState();
  const { locationDetailsForm: form } = useLocationDetailsStore.getState();

  const totalSquareFootageFormValue = form?.getFieldValue(QUESTION_KEYS.totalSquareFootage);
  const totalSqFootage = Number(totalSquareFootageFormValue || 0);

  const sqUnOccupiedFootageFormValue = form?.getFieldValue(QUESTION_KEYS.sqFootageUnOccupied);
  const sqUnOccupiedFootage = Number(sqUnOccupiedFootageFormValue || 0);

  const sqOccupiedFootageFormValue = form?.getFieldValue(QUESTION_KEYS.sqFootageOccupied);
  const sqOccupiedFootage = Number(sqOccupiedFootageFormValue || 0);

  const isOwner = form?.getFieldValue([selectedLocationIndex, QUESTION_KEYS.isOwner]);
  const buildingCoverage = form?.getFieldValue(QUESTION_KEYS.buildingCoverage);
  const buildingCoverageValue = Number(buildingCoverage) || 0;
  const businessPersonalPropertyCoverage = form?.getFieldValue(QUESTION_KEYS.businessPersonalPropertyCoverage);

  const businessPersonalPropertyCoverageValue = Number(businessPersonalPropertyCoverage) || 0;

  switch (data?.dataIndex) {
    case QUESTION_KEYS.totalSquareFootage:
    case QUESTION_KEYS.sqFootageOccupied:
    case QUESTION_KEYS.sqFootageUnOccupied: {
      let isError = false;

      if (
        (totalSquareFootageFormValue || totalSquareFootageFormValue === 0) &&
        (sqUnOccupiedFootageFormValue || sqUnOccupiedFootageFormValue === 0) &&
        (sqOccupiedFootageFormValue || sqOccupiedFootageFormValue === 0) &&
        sqOccupiedFootage + sqUnOccupiedFootage + totalSqFootage <= 0
      ) {
        setError({
          form,
          field: QUESTION_KEYS.totalSquareFootage,
          error: ERROR_MESSAGES.oneAreaMustBeGreaterThan0,
        });
        setError({
          form,
          field: QUESTION_KEYS.sqFootageOccupied,
          error: ERROR_MESSAGES.oneAreaMustBeGreaterThan0,
        });
        setError({
          form,
          field: QUESTION_KEYS.sqFootageUnOccupied,
          error: ERROR_MESSAGES.oneAreaMustBeGreaterThan0,
        });
        isError = true;
      } else if (sqOccupiedFootage + sqUnOccupiedFootage > totalSqFootage) {
        setError({
          form,
          field: QUESTION_KEYS.totalSquareFootage,
          error: ERROR_MESSAGES.totalMustBeGreaterThan,
        });
        isError = true;
      } else if (sqOccupiedFootage + sqUnOccupiedFootage <= totalSqFootage) {
        (totalSquareFootageFormValue || totalSquareFootageFormValue === 0) &&
          clearError({ form, field: QUESTION_KEYS.totalSquareFootage });
      }

      if (!isError) {
        (totalSquareFootageFormValue || totalSquareFootageFormValue === 0) &&
          clearError({ form, field: QUESTION_KEYS.totalSquareFootage });
        (sqOccupiedFootageFormValue || sqOccupiedFootageFormValue === 0) &&
          clearError({ form, field: QUESTION_KEYS.sqFootageOccupied });
        (sqUnOccupiedFootageFormValue || sqUnOccupiedFootageFormValue === 0) &&
          clearError({ form, field: QUESTION_KEYS.sqFootageUnOccupied });
      }

      break;
    }

    case QUESTION_KEYS.yearBuilt: {
      const yearBuilt = form?.getFieldValue(QUESTION_KEYS.yearBuilt);
      const electricalWiringUpdateYear = form?.getFieldValue(QUESTION_KEYS.electricalWiringUpdateYear);
      const plumbingUpdateYear = form?.getFieldValue(QUESTION_KEYS.plumbingUpdateYear);
      const heatingUpdateYear = form?.getFieldValue(QUESTION_KEYS.heatingUpdateYear);
      const roofUpdateYear = form?.getFieldValue(QUESTION_KEYS.roofUpdateYear);

      const currentYear = dayjs();
      if (currentYear?.year() - yearBuilt?.year() < 20) {
        form?.setFieldsValue({
          ...form.getFieldsValue(),
          electricalWiringUpdateYear: undefined,
          plumbingUpdateYear: undefined,
          heatingUpdateYear: undefined,
        });
      }

      if (electricalWiringUpdateYear && electricalWiringUpdateYear?.year() < yearBuilt?.year()) {
        form?.setFieldsValue({
          ...form.getFieldsValue(),
          electricalWiringUpdateYear: undefined,
        });
      }
      if (plumbingUpdateYear && plumbingUpdateYear?.year() < yearBuilt?.year()) {
        form?.setFieldsValue({
          ...form.getFieldsValue(),
          plumbingUpdateYear: undefined,
        });
      }
      if (heatingUpdateYear && heatingUpdateYear?.year() < yearBuilt?.year()) {
        form?.setFieldsValue({
          ...form.getFieldsValue(),
          heatingUpdateYear: undefined,
        });
      }

      if (roofUpdateYear?.year() - yearBuilt?.year() < 0) {
        form?.setFieldsValue({
          ...form.getFieldsValue(),
          roofUpdateYear: undefined,
        });
      }
      break;
    }

    case QUESTION_KEYS.isOwner: {
      if (typeof isOwner === 'string') {
        if (isOwner === 'false') {
          buildingCoverageValue !== undefined &&
            setError({
              form,
              field: QUESTION_KEYS.buildingCoverage,
              error: buildingCoverageValue < 0 ? ERROR_MESSAGES.greaterThanOrEqual0 : '',
            });

          businessPersonalPropertyCoverage !== undefined &&
            setError({
              form,
              field: QUESTION_KEYS.businessPersonalPropertyCoverage,
              error: businessPersonalPropertyCoverageValue <= 0 ? ERROR_MESSAGES.greaterThan0 : '',
            });
        }
        if (isOwner === 'true') {
          if (buildingCoverageValue <= 0 && businessPersonalPropertyCoverageValue <= 0) {
            buildingCoverage !== undefined &&
              setError({
                form,
                field: QUESTION_KEYS.buildingCoverage,
                error: ERROR_MESSAGES.bppOrBuildingCoverageGreaterThan0,
              });

            businessPersonalPropertyCoverage !== undefined &&
              setError({
                form,
                field: QUESTION_KEYS.businessPersonalPropertyCoverage,
                error: ERROR_MESSAGES.bppOrBuildingCoverageGreaterThan0,
              });
          } else {
            clearError({ form, field: QUESTION_KEYS.buildingCoverage });
            clearError({ form, field: QUESTION_KEYS.businessPersonalPropertyCoverage });
          }
        }
      }
      break;
    }

    case QUESTION_KEYS.buildingCoverage: {
      if (
        typeof isOwner === 'string' &&
        getRequirementLevelOfField(ApplicationFieldPathEnum.LOCATION_BUILDING_IS_OWNER) !==
          ApplicationFieldRequirementLevel.NOT_ACCEPTED
      ) {
        if (isOwner === 'true') {
          if (
            businessPersonalPropertyCoverage !== undefined &&
            businessPersonalPropertyCoverageValue === 0 &&
            buildingCoverageValue <= 0
          ) {
            setError({
              form,
              field: QUESTION_KEYS.buildingCoverage,
              error: buildingCoverageValue < 0 ? ERROR_MESSAGES.greaterThanOrEqual0 : '',
            });
          } else {
            clearError({ form, field: QUESTION_KEYS.buildingCoverage });
            clearError({ form, field: QUESTION_KEYS.businessPersonalPropertyCoverage });
          }
        }
      }
      break;
    }
    case QUESTION_KEYS.businessPersonalPropertyCoverage: {
      if (
        typeof isOwner === 'string' &&
        getRequirementLevelOfField(ApplicationFieldPathEnum.LOCATION_BUILDING_IS_OWNER) !==
          ApplicationFieldRequirementLevel.NOT_ACCEPTED
      ) {
        if (isOwner === 'true') {
          if (
            buildingCoverageValue !== undefined &&
            buildingCoverageValue === 0 &&
            businessPersonalPropertyCoverageValue <= 0
          ) {
            setError({
              form,
              field: QUESTION_KEYS.businessPersonalPropertyCoverage,
              error: businessPersonalPropertyCoverageValue < 0 ? ERROR_MESSAGES.greaterThanOrEqual0 : '',
            });
          } else {
            clearError({ form, field: QUESTION_KEYS.buildingCoverage });
            clearError({ form, field: QUESTION_KEYS.businessPersonalPropertyCoverage });
          }
        }
      }
      break;
    }

    case QUESTION_KEYS.buildingCoverageClassId: {
      if (!form?.getFieldValue(QUESTION_KEYS.businessPersonalPropertyCoverageClassId)) {
        form?.setFieldValue(
          QUESTION_KEYS.businessPersonalPropertyCoverageClassId,
          form?.getFieldValue(QUESTION_KEYS.buildingCoverageClassId),
        );
        clearError({ form, field: QUESTION_KEYS.businessPersonalPropertyCoverageClassId });
      }
      break;
    }

    default:
      break;
  }
};

export const updateBuildingInfoQuestionsWithCSPFields = (
  cspOptions: optionType[],
  applicationData?: IApplicationInfo,
) => {
  const { updateBuildingInformationByKey, buildingInformationQuestions } = useBuildingInformationStore.getState();

  const carriers = applicationData?.basicBusinessDetails?.carrierIndustryCodes?.map((code) => code.carrierId);

  if (carriers && carriers.includes(Carrier.LIBERTYMUTUAL)) {
    const buildingInformationQuestionsClone = cloneDeep(buildingInformationQuestions);

    buildingInformationQuestionsClone.forEach((questionArray) => {
      questionArray.forEach((question) => {
        switch (question.dataIndex) {
          case QUESTION_KEYS.buildingCoverage:
          case QUESTION_KEYS.businessPersonalPropertyCoverage:
            if (
              getRequirementLevelOfField(ApplicationFieldPathEnum.LOCATION_BUILDING_COVERAGE_CLASS_ID) !==
                ApplicationFieldRequirementLevel.NOT_ACCEPTED ||
              getRequirementLevelOfField(
                ApplicationFieldPathEnum.LOCATION_BUILDING_BUSINESS_PERSONAL_PROPERTY_COVERAGE_CLASS_ID,
              ) !== ApplicationFieldRequirementLevel.NOT_ACCEPTED
            ) {
              question.colSpan = 5;
            }
            break;

          case QUESTION_KEYS.buildingCoverageClassId:
          case QUESTION_KEYS.businessPersonalPropertyCoverageClassId:
            if (
              getRequirementLevelOfField(
                question.dataIndex === QUESTION_KEYS.buildingCoverageClassId
                  ? ApplicationFieldPathEnum.LOCATION_BUILDING_COVERAGE_CLASS_ID
                  : ApplicationFieldPathEnum?.LOCATION_BUILDING_BUSINESS_PERSONAL_PROPERTY_COVERAGE_CLASS_ID,
              ) !== ApplicationFieldRequirementLevel.NOT_ACCEPTED
            ) {
              question.hidden = false;
            }
            question.options = cspOptions;
            break;

          default:
            break;
        }
      });
    });

    updateBuildingInformationByKey(
      BUILDING_STORE_KEYS.BUILDING_INFORMATION_QUESTIONS,
      buildingInformationQuestionsClone,
    );
  }
};

const modifyVisibilityOfFieldsForSingleQuestion = (question: IBopQuestion): IBopQuestion => {
  if (question.dependentQuestions && question.dependentQuestions?.length > 0) {
    const dependentQuestions: IBopQuestion[] = [];
    question?.dependentQuestions?.forEach((dependentQuestion) => {
      dependentQuestions.push(modifyVisibilityOfFieldsForSingleQuestion(dependentQuestion));
    });
    question.dependentQuestions = dependentQuestions;
  }
  const modifiedQuestion = modifyVisibilityOfFields([question as IApplicationQuestion])[0];

  return modifiedQuestion as IBopQuestion;
};

export const getBuildingInformationQuestions = () => {
  const questions: IBopQuestion[][] = [];
  BUILDING_INFORMATION_QUESTION.forEach((questionArray, outerIndex) => {
    questionArray.forEach((question) => {
      if (questions[outerIndex]) {
        questions[outerIndex].push(modifyVisibilityOfFieldsForSingleQuestion(question));
      } else {
        questions[outerIndex] = [modifyVisibilityOfFieldsForSingleQuestion(question)];
      }
    });
  });

  return questions;
};

const getVisibleFormElementKeyForSingleQuestion = (question: IBopQuestion): NamePath[] => {
  let namePaths: NamePath[] = [];
  if (question.dependentQuestions && question.dependentQuestions?.length > 0) {
    question?.dependentQuestions?.forEach((dependentQuestion) => {
      namePaths = namePaths.concat(getVisibleFormElementKeyForSingleQuestion(dependentQuestion));
    });
  }
  namePaths = namePaths.concat(getVisibleFormElementKeys([question as IApplicationQuestion]));
  return namePaths;
};

export const getVisibleFormElementKeysForBuilding = () => {
  const { buildingInformationQuestions } = useBuildingInformationStore.getState();
  let namePaths: NamePath[] = [];
  buildingInformationQuestions?.forEach((questionArray) => {
    questionArray.forEach((question) => {
      namePaths = namePaths.concat(getVisibleFormElementKeyForSingleQuestion(question));
    });
  });

  return namePaths;
};
