import styled from 'styled-components/macro';

import { CFRadioGroup } from '../cfRadio/cfRadio';

export const RadioChoiceStyled = styled(CFRadioGroup)`
  label {
    background: ${(p) => p.theme?.colors?.grayscale?.white};
  }
  label.ant-radio-button-wrapper {
    height: 32px;
  }

  // label.ant-radio-button-wrapper:hover {
  //   color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primary : 'default')};
  // }
  // label.ant-radio-button-wrapper:active {
  //   background: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primary : 'default')};
  // }
  // label.ant-radio-button-wrapper-checked {
  //   border-color: ${(p) => (!p.disabled ? p.theme?.colorScheme?.primary : 'default')};
  // }
  span.ant-radio-button-checked {
    background-color: ${(p) => p.theme?.colorScheme?.primary};
    border-radius: 5px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${(p) => p.theme?.colorScheme?.primary};
  }
  .ant-radio-button-wrapper-disabled:first-child:not(.ant-radio-button-wrapper-checked) {
    background: ${(p) => p.theme?.colors?.grayscale?.white};
  }
`;
