import { Carrier, PolicyType, USState } from '@coverforce-platform/cf-common-types';
import { Form, RadioChangeEvent } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { INDUSTRY_CODES } from '../../../constants/appetite';
import { BUTTON_TYPE, FEATURE_FLAG, PAGE_ROUTES, SPIN_SIZE, USState as USStatesConst } from '../../../globalConstants';
import { useAccountStore } from '../../../pages/v2/account/store';
import { useAccountsStore } from '../../../pages/v2/accounts/store';
import { useProfileV2Store } from '../../../pages/v2/profile/store';
import { error, toastInfo } from '../../../ui-core/Notification';
import CFButton from '../../../ui-core/V2/cfButton/cfButton';
import CFFileDropper from '../../../ui-core/V2/cfFileDropper/cfFileDropper';
import CFFormItem from '../../../ui-core/V2/cfFormItem/cfFormItem';
import CFLoader from '../../../ui-core/V2/cfLoader/cfLoader';
import CFModal from '../../../ui-core/V2/cfModal/cfModal';
import { useCFModalStore } from '../../../ui-core/V2/cfModal/store';
import CFSelect from '../../../ui-core/V2/cfSelect/cfSelect';
import getBase64FromFile from '../../../utils/getBase64FromFile';
import { pushEvent } from '../../../utils/googleAnalytics';
import { getGrowthbookFeatureValueByKey, isGrowthbookFeatureEnabledByKey } from '../../../utils/growthbook';
import { filterOptionNaicsCodes, getIndustryCodeLink } from '../../../utils/industrySelection/industry';
import { sortBy } from '../../../utils/sortBy';
import AppetiteCarrierList from '../../appetiteCarrierList/appetiteCarrierList';
import {
  ACORD_MESSAGES,
  DEFAULT_MAX_ALLOWED_ACORD_FILE_SIZE_KB,
  FORM_CONFIG,
  GA_ACTION,
  GA_LABEL,
  UPLOAD_ACORD_SUPPORTED_EXT,
} from './constants';
import { IApplicationAcordConfig, INewQuoteModalForm } from './interface';
import { useNewQuoteStore } from './store';
import {
  AccordInfoTagStyled,
  AccordInfoWrapper,
  AcordTextStyled,
  AppetiteErrorStyled,
  AppetiteErrorTitleStyled,
  AppetiteInfoWrapperStyled,
  AppetiteWrapperStyled,
  CFFormItemStyled,
  CFFormStyled,
  CFRadioGroupStyled,
  InboxOutlinedStyled,
  IndustryCodeLinkWrapperStyled,
  SubInfoStyled,
  SubInfoWrapperStyled,
} from './styles';
import {
  extractPolicySpecificRule,
  getAcordUploadMessageForPolicyType,
  getCheckBoxValues,
  getDefaultCarrierInAppetite,
  getGACategory,
  getStateFromAccountId,
  isPolicyTypeAllowedForAcord,
} from './utils';

const NewQuoteModal = () => {
  const { userData, selectedUserProfile } = useProfileV2Store();
  const { clearCfModal } = useCFModalStore();
  const {
    presetAccountId,
    policyTypeStore,
    supportedCarriersStore,
    allAccountsStore,
    naicsCodesStore,
    sicCodesStore,
    appetiteDetailsStore,
    newApplicationId,
    createNewApplicationLoading,
    fetchAvailableCarriersByAgentId,
    fetchPolicyType,
    fetchCarrierRankingByPolicyTypeId,
    fetchAllAccounts,
    fetchNaicsCodes,
    fetchAppetiteDetails,
    fetchBrokerConfigs,
    createNewApplication,
    createNewApplicationFromAcord,
    clearNewQuoteStore,
    selectedIndustryCodeType,
    setSelectedIndustryCodeType,
    fetchSicCodes,
  } = useNewQuoteStore();
  // Fields Config
  const { accountsField, policyTypeField, stateField, industryCodeField, acordField } =
    FORM_CONFIG(selectedIndustryCodeType);
  const { fetchApplications } = useAccountStore();
  const { openNewAccountDrawer } = useAccountsStore();
  const { policyTypesData, policyTypesLoading } = policyTypeStore;
  const { supportedCarriersData, supportedCarriersLoading } = supportedCarriersStore;
  const { allAccountsData, allAccountsLoading } = allAccountsStore;

  const theme = useTheme();

  const { industryCodesLoading, industryCodesData } = useMemo(() => {
    return {
      industryCodesLoading:
        selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE
          ? naicsCodesStore.naicsCodesLoading
          : sicCodesStore.sicCodesLoading,
      industryCodesData:
        selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE
          ? naicsCodesStore.naicsCodesData
          : sicCodesStore.sicCodesData,
    };
  }, [
    naicsCodesStore.naicsCodesData,
    naicsCodesStore.naicsCodesLoading,
    selectedIndustryCodeType,
    sicCodesStore.sicCodesData,
    sicCodesStore.sicCodesLoading,
  ]);

  const fetchIndustryCodes = useMemo(() => fetchNaicsCodes, [fetchNaicsCodes]);

  const { appetiteData, inAppetiteCarriers, appetiteError, appetiteLoading } = appetiteDetailsStore;

  // miscellaneous
  const navigate = useNavigate();

  // states
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [inAppetiteCheckboxGroup, setInAppetiteCheckboxGroup] = useState<string[]>([]);
  const [notInAppetiteCheckboxGroup, setNotInAppetiteCheckboxGroup] = useState<string[]>([]);
  const [isAcordUpload, setIsAcordUpload] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [acordError, setAcordError] = useState(false);
  const [accountId, setAccountId] = useState(presetAccountId);

  const [form] = Form.useForm<INewQuoteModalForm>();

  const createApplicationFromAcord = isGrowthbookFeatureEnabledByKey(FEATURE_FLAG.CREATE_APPLICATION_FROM_ACORD);

  const allowedPolicyTypesForAcord: IApplicationAcordConfig[] = getGrowthbookFeatureValueByKey(
    FEATURE_FLAG.APPLICATION_CREATION_ACORD_CONFIG,
  );

  const isAcordDisabled = () =>
    !isAcordUpload ||
    !isPolicyTypeAllowedForAcord(allowedPolicyTypesForAcord, form.getFieldValue('policyType')) ||
    createNewApplicationLoading ||
    supportedCarriersLoading;

  const policySpecificAcordFormRule = useMemo(
    () => extractPolicySpecificRule(allowedPolicyTypesForAcord, form.getFieldValue('policyType')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowedPolicyTypesForAcord, form.getFieldValue('policyType')],
  );

  const allowedAcordFormSize = useMemo(
    () => policySpecificAcordFormRule?.maxFileSizeInKb || DEFAULT_MAX_ALLOWED_ACORD_FILE_SIZE_KB,
    [policySpecificAcordFormRule?.maxFileSizeInKb],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearNewQuoteStore(), []);

  useEffect(() => {
    setSelectedCarriers([]);
    const newSelectedCarriers = [...inAppetiteCheckboxGroup, ...notInAppetiteCheckboxGroup];
    setSelectedCarriers(newSelectedCarriers);
  }, [inAppetiteCheckboxGroup, notInAppetiteCheckboxGroup]);

  useEffect(() => {
    const policyType = form.getFieldValue('policyType');
    const carriers = getDefaultCarrierInAppetite({ inAppetiteCarriers, policyType });
    setSelectedCarriers(carriers);
    setInAppetiteCheckboxGroup(carriers);
  }, [appetiteData, form, inAppetiteCarriers]);

  useEffect(() => {
    // on mount api calls
    if (userData && selectedUserProfile?.agencyId && userData?.agentId) {
      fetchAllAccounts({ agencyId: selectedUserProfile.agencyId });
      fetchPolicyType();
      fetchAvailableCarriersByAgentId({
        agentId: userData.agentId,
      });
      fetchIndustryCodes();
      fetchBrokerConfigs({ agencyId: selectedUserProfile.agencyId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Prefill state when agent opens newquote modal from accounts page
  useEffect(() => {
    if (presetAccountId) {
      const stateInfo = getStateFromAccountId(allAccountsData, presetAccountId);
      form?.setFieldsValue({ state: stateInfo?.code });
      if (allAccountsData?.find((item) => item.accountId === presetAccountId)) {
        form?.setFieldValue(accountsField.name, presetAccountId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccountsData]);

  useEffect(() => {
    const supportedCarriers = supportedCarriersData?.[form?.getFieldValue('policyType')];
    form?.setFieldsValue({
      carriers: supportedCarriers?.length > 0 ? supportedCarriers.map(({ carrierId }) => carrierId) : [],
    });

    supportedCarriers?.length > 0 && getAppetiteDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedCarriersData]);

  useEffect(() => {
    if (newApplicationId) {
      if (isAcordUpload) {
        toastInfo({
          title: <div style={{ fontSize: '14px' }}>{ACORD_MESSAGES.FORM_UPLOADED_APPLICATION_CREATING}</div>,
          iconColor: theme?.colorScheme?.primary,
        });
        if (presetAccountId) {
          fetchApplications({ accountId: presetAccountId });
        } else {
          navigate(`${PAGE_ROUTES.ACCOUNTS}${accountId}`);
        }
      } else {
        navigate(`${PAGE_ROUTES.APPLICATION}${newApplicationId}`);
      }
      clearCfModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, newApplicationId, clearCfModal, isAcordUpload, accountId]);

  useEffect(() => {
    if (!isAcordUpload) {
      setFileList([]);
    }
  }, [isAcordUpload]);

  useEffect(() => {
    if ((isAcordUpload && fileList.length !== 0) || !isAcordUpload) {
      setAcordError(false);
    }
  }, [isAcordUpload, fileList]);

  useEffect(() => {
    form.setFieldValue('isAcord', false);
  }, [form]);

  const handleAddNewAccount = useCallback(() => {
    clearCfModal();
    navigate('/accounts');
    openNewAccountDrawer();
  }, [navigate, clearCfModal, openNewAccountDrawer]);

  const getAppetiteDetails = () => {
    const formAnswers = form.getFieldsValue();
    const { policyType, naicsCode, state, sicCode } = formAnswers;
    const industryCode = selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE ? naicsCode : sicCode;
    const industryCodePayload = {
      [selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE ? INDUSTRY_CODES.NAICS.KEY : INDUSTRY_CODES.SIC.KEY]:
        industryCode?.split('-')[0]?.trim(),
    };
    const availableAppetiteCarriers: string[] = supportedCarriersData?.[form.getFieldValue('policyType')]
      ? supportedCarriersData?.[form.getFieldValue('policyType')].map(
          ({ carrierId }: { carrierId: string }) => carrierId,
        )
      : [];
    if (policyType && industryCode && state) {
      fetchAppetiteDetails({
        carriers: availableAppetiteCarriers as Carrier[],
        ...industryCodePayload,
        policyType: policyType as PolicyType,
        state: state as USState,
      });
    }
  };

  const handleChangePolicyType = useCallback(
    async (value) => {
      form.setFieldValue('isAcord', false);
      await fetchCarrierRankingByPolicyTypeId({
        agentId: userData!.agentId,
        policyTypeId: value,
      });
      setIsAcordUpload(false);
      setInAppetiteCheckboxGroup([]);
      setNotInAppetiteCheckboxGroup([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, fetchCarrierRankingByPolicyTypeId],
  );

  const createApplicationAcord = async (validation: INewQuoteModalForm) => {
    if (fileList.length === 0) {
      setAcordError(true);
    } else {
      const acordFiles = [];
      for (const file of fileList) {
        if (file.originFileObj) {
          const base64Data = await getBase64FromFile(file.originFileObj);
          acordFiles.push({ fileBase64: base64Data as string, fileName: file.originFileObj.name });
        }
      }
      createNewApplicationFromAcord({
        accountId: presetAccountId || (validation.account as PolicyType),
        carriersSelected: selectedCarriers as Carrier[],
        policyTypes: [validation.policyType as PolicyType],
        files: acordFiles,
      });
    }
  };

  const handleCancelClick = () => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.CLOSE_NEW_QUOTE_MODAL,
      label: GA_LABEL.CLOSE_NEW_QUOTE_MODAL,
    });
  };

  const handleStartClick = async () => {
    pushEvent({
      category: getGACategory(),
      action: GA_ACTION.START_NEW_APPLICATION,
      label: GA_LABEL.START_NEW_APPLICATION,
    });
    const validation = await form.validateFields();
    const formData = await form?.getFieldsValue();
    if (isAcordUpload) {
      await createApplicationAcord(validation);
    } else {
      selectedCarriers?.length > 0 &&
        createNewApplication({
          accountId: validation.account as PolicyType,
          carriersSelected: selectedCarriers as Carrier[],
          policyTypes: [validation.policyType as PolicyType],
          naicsExtendedId: formData?.naicsCode?.split('-')?.[1]?.trim(),
        });
    }
  };

  const handleAccountChange = (selectedAccountId: string) => {
    const stateInfo = getStateFromAccountId(allAccountsData, selectedAccountId);
    form?.setFieldValue('state', stateInfo?.code);
    if (stateInfo?.code) {
      form?.validateFields(['state']);
    }
    setAccountId(selectedAccountId);
    getAppetiteDetails();
  };

  const handleIndustryCodeChange = () => {
    setInAppetiteCheckboxGroup([]);
    setNotInAppetiteCheckboxGroup([]);
    getAppetiteDetails();
  };

  const handleStateChange = () => {
    setInAppetiteCheckboxGroup([]);
    setNotInAppetiteCheckboxGroup([]);
    getAppetiteDetails();
  };

  const handleInAppetiteCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    const { otherGroupCarriers, currentGroupCarriers } = getCheckBoxValues({
      newSelectedCarriers: checkedValues as string[],
      oldSelectedCarriers: selectedCarriers,
      policyType: form?.getFieldValue('policyType'),
      otherGroupCarriers: notInAppetiteCheckboxGroup,
    });
    setInAppetiteCheckboxGroup(currentGroupCarriers);
    setNotInAppetiteCheckboxGroup(otherGroupCarriers);
  };

  const handleNotInAppetiteCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    const { otherGroupCarriers, currentGroupCarriers } = getCheckBoxValues({
      newSelectedCarriers: checkedValues as string[],
      oldSelectedCarriers: selectedCarriers,
      policyType: form?.getFieldValue('policyType'),
      otherGroupCarriers: inAppetiteCheckboxGroup,
    });
    setNotInAppetiteCheckboxGroup(currentGroupCarriers);
    setInAppetiteCheckboxGroup(otherGroupCarriers);
  };

  const fileUploadCustomRequest = async (options: any) => {
    setTimeout(() => {
      options.onSuccess('ok');
    }, 0);
  };

  const onAcordUploadChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    let newFileList = [...info.fileList];
    let fileSize = 0;
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      if (file.size) {
        fileSize += file?.size;
      }
      return file;
    });
    if (fileSize / 1024 > allowedAcordFormSize) {
      error(
        policySpecificAcordFormRule?.maxFileSizeErrorMessage ||
          `Files size must be smaller than ${allowedAcordFormSize}KB!`,
        3,
      );
    } else {
      setFileList(newFileList);
    }
  };

  const onIsAcordUploadChange = (e: RadioChangeEvent) => {
    setIsAcordUpload(e.target.value);
  };

  const getIndustryCodesInfo = () => {
    const formAnswers = form?.getFieldsValue();
    const { naicsCode, sicCode } = formAnswers || {};
    return { naicsCode, sicCode, isIndustryCodeSelected: naicsCode || sicCode };
  };

  const getIndustryCodeLinkNewQuote = () => {
    const { naicsCode, sicCode } = getIndustryCodesInfo();
    return getIndustryCodeLink({
      naicsCode: naicsCode?.split('-')[0]?.trim(),
      sicCode: sicCode?.split('-')[0]?.trim(),
    });
  };

  const handleChangeIndustryType = useCallback(() => {
    setSelectedIndustryCodeType(
      selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE ? INDUSTRY_CODES.SIC.TYPE : INDUSTRY_CODES.NAICS.TYPE,
    );
    switch (selectedIndustryCodeType) {
      case INDUSTRY_CODES.NAICS.TYPE:
        form.setFieldValue(INDUSTRY_CODES.NAICS.KEY, undefined);
        fetchSicCodes();
        pushEvent({
          category: getGACategory(),
          action: GA_ACTION.SWITCH_CODE_TO_SIC,
          label: GA_LABEL.SWITCH_CODE_TO_SIC,
        });
        break;
      case INDUSTRY_CODES.SIC.TYPE:
        form.setFieldValue(INDUSTRY_CODES.SIC.KEY, undefined);
        fetchNaicsCodes();
        pushEvent({
          category: getGACategory(),
          action: GA_ACTION.SWITCH_CODE_TO_NAICS,
          label: GA_LABEL.SWITCH_CODE_TO_NAICS,
        });
        break;

      default:
        break;
    }
  }, [fetchNaicsCodes, fetchSicCodes, form, selectedIndustryCodeType, setSelectedIndustryCodeType]);

  const isAppetiteListVisible = useMemo(() => {
    const formAnswers = form?.getFieldsValue();
    const { policyType, naicsCode, state, sicCode } = formAnswers;
    return policyType && (naicsCode || sicCode) && state && !appetiteLoading;
  }, [appetiteLoading, form]);

  return (
    <CFModal
      title='Start New Quote'
      okText='Start'
      onOk={handleStartClick}
      onCancel={handleCancelClick}
      cancelButtonProps={{
        id: 'btn_new_quote_cancel',
        disabled: createNewApplicationLoading,
      }}
      okButtonProps={{
        id: 'btn_new_quote_ok',
        loading: createNewApplicationLoading,
        disabled: appetiteData?.length > 0 && selectedCarriers?.length === 0,
      }}
      disableCloseIcon={createNewApplicationLoading}
      maskClosable={!createNewApplicationLoading}
      width={622}
    >
      <CFFormStyled
        id='form_new_quote_modal'
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        disabled={isAcordUpload && createNewApplicationLoading}
        style={{
          opacity: isAcordUpload && createNewApplicationLoading ? 0.5 : 1,
        }}
      >
        <>
          <CFFormItem
            id={accountsField.id}
            name={accountsField.name}
            label={accountsField.label}
            rules={accountsField.rules}
          >
            <CFSelect
              id='sel_for_account'
              key={`sel_for_account_${allAccountsLoading}`}
              placeholder={accountsField.placeholder}
              loading={allAccountsLoading}
              onChange={handleAccountChange}
              options={allAccountsData?.map((item) => ({ label: item?.accountName, value: item?.accountId }))}
              defaultValue={!allAccountsLoading ? presetAccountId : undefined}
            />
          </CFFormItem>
          {!presetAccountId && (
            <SubInfoWrapperStyled>
              <SubInfoStyled id='new_account' onClick={handleAddNewAccount}>
                Add new Account
              </SubInfoStyled>
            </SubInfoWrapperStyled>
          )}
        </>
        <CFFormItem
          id={policyTypeField.id}
          name={policyTypeField.name}
          label={policyTypeField.label}
          rules={policyTypeField.rules}
        >
          <CFSelect
            id='sel_policy_type'
            placeholder={policyTypeField.placeholder}
            onChange={handleChangePolicyType}
            loading={policyTypesLoading}
            options={sortBy({
              arr: policyTypesData?.map((item) => ({
                label: item?.policyTypeDisplayName,
                value: item?.policyTypeId,
              })),
              sortKey: 'label',
            })}
          />
        </CFFormItem>
        <>
          <CFFormItem
            id={industryCodeField.id}
            label={industryCodeField.label}
            key={industryCodeField.id}
            rules={industryCodeField.rules}
            name={industryCodeField.name}
          >
            <CFSelect
              id='sel_industryCodes'
              placeholder={industryCodeField.placeholder}
              filterOption={filterOptionNaicsCodes}
              onChange={handleIndustryCodeChange}
              loading={industryCodesLoading}
              getPopupContainer={() => document.getElementById(industryCodeField.id) || document.body}
              options={industryCodesData}
            />
          </CFFormItem>
          <SubInfoWrapperStyled
            style={{ justifyContent: getIndustryCodesInfo()?.isIndustryCodeSelected ? 'space-between' : 'flex-end' }}
          >
            {getIndustryCodesInfo()?.isIndustryCodeSelected && (
              <IndustryCodeLinkWrapperStyled>{getIndustryCodeLinkNewQuote()}</IndustryCodeLinkWrapperStyled>
            )}
            <SubInfoStyled id='industry_code_type' onClick={handleChangeIndustryType}>
              Switch to{' '}
              {selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE
                ? INDUSTRY_CODES.SIC.TYPE
                : INDUSTRY_CODES.NAICS.TYPE}
            </SubInfoStyled>
          </SubInfoWrapperStyled>
          <CFFormItem
            id={stateField.id}
            label={stateField.label}
            key={stateField.id}
            rules={stateField.rules}
            name={stateField.name}
          >
            <CFSelect
              id='sel_state'
              placeholder={stateField.placeholder}
              onChange={handleStateChange}
              getPopupContainer={() => document.getElementById(stateField.id) || document.body}
              options={Object.entries(USStatesConst).map(([value, label]) => ({ value, label }))}
            />
          </CFFormItem>
          {appetiteLoading && <CFLoader marginTop='5px' marginBottom='10px' size={SPIN_SIZE.SMALL} />}

          {isAppetiteListVisible && (
            <AppetiteCarrierList
              handleInAppetiteCheckboxChange={handleInAppetiteCheckboxChange}
              selectedCarriers={selectedCarriers}
              isAcordUpload={isAcordUpload}
              handleNotInAppetiteCheckboxChange={handleNotInAppetiteCheckboxChange}
              policyType={form.getFieldValue('policyType')}
            />
          )}
          {appetiteError && (
            <>
              <AppetiteWrapperStyled>
                <AppetiteErrorTitleStyled>An error occurred while fetching carriers</AppetiteErrorTitleStyled>
                <AppetiteInfoWrapperStyled>
                  <CFButton
                    buttonType={BUTTON_TYPE.PRIMARY}
                    onClick={() => getAppetiteDetails()}
                    loading={appetiteLoading}
                  >
                    Retry
                  </CFButton>
                </AppetiteInfoWrapperStyled>
              </AppetiteWrapperStyled>
            </>
          )}
        </>
        {!createApplicationFromAcord && (
          <AccordInfoWrapper>
            <AccordInfoTagStyled color='yellow'>
              The ACORD form feature is currently disabled. Please check back in a couple of hours.
            </AccordInfoTagStyled>
          </AccordInfoWrapper>
        )}
        {createApplicationFromAcord && (
          <>
            <CFFormItemStyled
              key={acordField.id}
              id={acordField.id}
              name={acordField.name}
              label={acordField.label}
              tooltip={acordField.tooltip}
            >
              <CFRadioGroupStyled
                id='rad_acord_upload'
                options={acordField.options}
                onChange={onIsAcordUploadChange}
                disabled={
                  !isPolicyTypeAllowedForAcord(allowedPolicyTypesForAcord, form.getFieldValue('policyType')) ||
                  createNewApplicationLoading ||
                  supportedCarriersLoading
                }
              />
            </CFFormItemStyled>
            <CFFileDropper
              id='accord_upload'
              multiple
              maxSizeAllowedKb={allowedAcordFormSize}
              sizeExceedMessage={policySpecificAcordFormRule?.maxFileSizeErrorMessage}
              name='acord_files'
              customRequest={fileUploadCustomRequest}
              onChange={onAcordUploadChange}
              fileList={fileList}
              disabled={isAcordDisabled()}
              accept={UPLOAD_ACORD_SUPPORTED_EXT.join(',')}
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlinedStyled disabled={isAcordDisabled()} />
              </p>
              <AcordTextStyled disabled={isAcordDisabled()}>
                {getAcordUploadMessageForPolicyType(allowedPolicyTypesForAcord, form.getFieldValue('policyType'))}
              </AcordTextStyled>
            </CFFileDropper>
            {acordError && <AppetiteErrorStyled>Please upload atleast one ACORD form.</AppetiteErrorStyled>}
          </>
        )}
      </CFFormStyled>
      {/* {isAcordUpload && createNewApplicationLoading && (
        <LoaderWrapperStyled>
          <CFLoader>
            <AcordUploadStatusWrapper>
              <Spin size={SPIN_SIZE.LARGE} />
              <AcordStatusTextStyled>{acordUploadProgressMessage}</AcordStatusTextStyled>
            </AcordUploadStatusWrapper>
          </CFLoader>
        </LoaderWrapperStyled>
      )} */}
    </CFModal>
  );
};

export default memo(NewQuoteModal);
