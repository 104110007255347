import { ADDITIONAL_INTEREST_TYPE_DISPLAY_TEXT } from '../../constants/additionalInterest';
import { FALSY_VALUES } from '../../globalConstants';
import { sortBy } from '../../utils/sortBy';
import {
  ADDITIONAL_INTEREST_QUESTION_KEYS,
  OPTIONAL_NAME_ADDITIONAL_INTEREST_TYPES,
} from '../additionalInterest/constants';
import { ANTD_QUESTION_TYPE } from '../applicationQuestion/constants';
import { IApplicationQuestion } from '../applicationQuestion/interface';
import { IAdditionalInterestModalStore } from './interface';
import { getAdditionalInterestOptions } from './utils';

export const ADDITIONAL_INTEREST_MODAL_STORE_META_DATA = {
  UPDATE_ADDITIONAL_INTEREST_MODAL_STORE_BY_KEY: 'UPDATE_ADDITIONAL_INTEREST_MODAL_STORE_BY_KEY',
  CLEAR_ADDITIONAL_INTEREST_MODAL_STORE: 'CLEAR_ADDITIONAL_INTEREST_MODAL_STORE',
  ANONYMOUS_ACTION_NAME: 'ADDITIONAL_INTEREST_MODAL_ANONYMOUS_ACTION',
  STORE_NAME: 'Additional Interest Modal',
};

export const ADDITIONAL_INTEREST_MODAL_STORE_KEYS: { [key: string]: keyof IAdditionalInterestModalStore } = {
  ADDITIONAL_INTEREST_MODAL_FORM: 'additionalInterestModalForm',
  ADDITIONAL_INTEREST_INFO_INDEX: 'additionalInterestInfoIndex',
};

export const ADDITIONAL_INTEREST_QUESTIONS: IApplicationQuestion[] = [
  {
    type: ANTD_QUESTION_TYPE.STRING,
    name: 'Full Name',
    dataIndex: ADDITIONAL_INTEREST_QUESTION_KEYS.NAME,
    placeholder: 'Additional interest name',
    rules: [
      ({ getFieldValue }: { getFieldValue: Function }) => ({
        validator(_: any, value: any) {
          const additionalInterestTypeValue = getFieldValue(ADDITIONAL_INTEREST_QUESTION_KEYS.TYPE)?.trim();
          if (
            (!additionalInterestTypeValue ||
              (additionalInterestTypeValue &&
                !OPTIONAL_NAME_ADDITIONAL_INTEREST_TYPES.includes(additionalInterestTypeValue))) &&
            FALSY_VALUES?.includes(value?.trim())
          ) {
            return Promise.reject(new Error('Please enter additional interest full name.'));
          }
          return Promise.resolve();
        },
      }),
    ],
    tooltip: `Name is optional for ${OPTIONAL_NAME_ADDITIONAL_INTEREST_TYPES.map((item) => {
      return `'${ADDITIONAL_INTEREST_TYPE_DISPLAY_TEXT[item]}'`;
    }).join(', ')} interest types`,
  },
  {
    type: ANTD_QUESTION_TYPE.DROPDOWN,
    name: 'Type',
    dataIndex: ADDITIONAL_INTEREST_QUESTION_KEYS.TYPE,
    placeholder: 'Select additional interest type',
    rules: [{ required: true, message: 'Please select additional interest type.' }],
    options: sortBy({ arr: getAdditionalInterestOptions(), sortKey: 'label' }),
    filterOption: (input: string, option: any) =>
      (option?.label as unknown as string)?.toLowerCase().includes(input?.toLowerCase()),
    getPopupContainer: () => document.body,
  },
];
