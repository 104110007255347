import { ICFBadgeProps, ICFRibbon } from './interface';
import { CFBadgeStyled, CFRibbonStyled } from './styles';

const CFBadge = (cfBadgeProps: ICFBadgeProps) => {
  const { children, ...restCFBadgeProps } = cfBadgeProps;

  return <CFBadgeStyled {...restCFBadgeProps}>{children}</CFBadgeStyled>;
};

export default CFBadge;

export const CFRibbon = (cfRibbonProps: ICFRibbon) => {
  const { children, ...restCFRibbonProps } = cfRibbonProps;

  return <CFRibbonStyled {...restCFRibbonProps}>{children}</CFRibbonStyled>;
};
