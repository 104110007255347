import { BellOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';

import CFPopover from '../../ui-core/V2/cfPopover/cfPopover';

export const NotificationsWrapperStyled = styled.div`
  position: relative;
  margin-top: -4px;
  padding: 0;
`;

export const NotificationsStyled = styled(CFPopover)``;

export const NotificationIconStyled = styled(BellOutlined)``;

export const UnreadCountWrapperStyled = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: -2px;
  right: -2px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.colors.application.redBg};
`;

export const UnreadCountStyled = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.typography.fontSize.extraMini};
  font-weight: ${(p) => p.theme.typography.fontWeight.mediumBold};
  color: ${(p) => p?.theme?.colors?.grayscale?.white};
`;
