import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useEffect, useMemo } from 'react';

import { BUTTON_TYPE, SPIN_SIZE } from '../../globalConstants';
import CFButton from '../../ui-core/V2/cfButton/cfButton';
import CFLoader from '../../ui-core/V2/cfLoader/cfLoader';
import { useAccordFieldsForNewQuoteStore } from '../accordFieldsForNewQuote/store';
import AppetiteCarrierList from '../appetiteCarrierListV2/appetiteCarrierList';
import { FORM_CONFIG } from '../modals/newQuoteV2/constants';
import { useNewQuoteStore } from '../modals/newQuoteV2/store';
import { getAppetiteDetails } from '../modals/newQuoteV2/utils';
import { useNewQuoteFormFieldStore } from '../newQuoteFormField/store';
import { ACCORD_CARRIER_LIST_STORE_KEYS } from './constants';
import { useAppetiteCarrierListContainerStore } from './store';
import { AppetiteErrorTitleStyled, AppetiteInfoWrapperStyled, AppetiteWrapperStyled } from './styles';
import { getCheckBoxValues, getDefaultCarrierInAppetite } from './utils';

const AppetiteCarrierListContainer = () => {
  const { clonedApplicationId, newQuoteModalForm: form, clonedApplication } = useNewQuoteStore();
  const { selectedIndustryCodeType } = useNewQuoteFormFieldStore();

  const { policyTypeField } = FORM_CONFIG(selectedIndustryCodeType);
  const {
    inAppetiteCheckboxGroup,
    notInAppetiteCheckboxGroup,
    selectedCarriers,
    appetiteDetailsStore,
    updateAppetiteCarrierListContainerByKey,
  } = useAppetiteCarrierListContainerStore();
  const { appetiteError, appetiteLoading, appetiteData, inAppetiteCarriers } = appetiteDetailsStore;

  const { isAcordUpload } = useAccordFieldsForNewQuoteStore();

  useEffect(() => {
    updateAppetiteCarrierListContainerByKey(ACCORD_CARRIER_LIST_STORE_KEYS.SELECTED_CARRIERS, []);
    const newSelectedCarriers = [...inAppetiteCheckboxGroup, ...notInAppetiteCheckboxGroup];
    updateAppetiteCarrierListContainerByKey(ACCORD_CARRIER_LIST_STORE_KEYS.SELECTED_CARRIERS, newSelectedCarriers);
  }, [inAppetiteCheckboxGroup, notInAppetiteCheckboxGroup, updateAppetiteCarrierListContainerByKey]);

  useEffect(() => {
    if (!clonedApplicationId) {
      const policyType = form?.getFieldValue(policyTypeField.name);
      const carriers = getDefaultCarrierInAppetite({ inAppetiteCarriers, policyType });

      updateAppetiteCarrierListContainerByKey(ACCORD_CARRIER_LIST_STORE_KEYS.SELECTED_CARRIERS, carriers);
      updateAppetiteCarrierListContainerByKey(ACCORD_CARRIER_LIST_STORE_KEYS.IN_APPETITE_CHECKBOX_GROUP, carriers);
    }
  }, [
    appetiteData,
    clonedApplicationId,
    form,
    inAppetiteCarriers,
    policyTypeField.name,
    updateAppetiteCarrierListContainerByKey,
  ]);

  const isAppetiteListVisible = useMemo(() => {
    const formAnswers = form?.getFieldsValue();
    const { policyType, naicsCode, state, sicCode } = formAnswers || {};
    return policyType && (naicsCode || sicCode) && state && !appetiteLoading;
  }, [appetiteLoading, form]);

  const handleInAppetiteCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    const { otherGroupCarriers, currentGroupCarriers } = getCheckBoxValues({
      newSelectedCarriers: checkedValues as string[],
      oldSelectedCarriers: selectedCarriers,
      policyType: form?.getFieldValue(policyTypeField.name),
      otherGroupCarriers: notInAppetiteCheckboxGroup,
    });

    updateAppetiteCarrierListContainerByKey(
      ACCORD_CARRIER_LIST_STORE_KEYS.IN_APPETITE_CHECKBOX_GROUP,
      currentGroupCarriers,
    );
    updateAppetiteCarrierListContainerByKey(
      ACCORD_CARRIER_LIST_STORE_KEYS.NOT_IN_APPETITE_CHECKBOX_GROUP,
      otherGroupCarriers,
    );
  };

  const handleNotInAppetiteCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    const { otherGroupCarriers, currentGroupCarriers } = getCheckBoxValues({
      newSelectedCarriers: checkedValues as string[],
      oldSelectedCarriers: selectedCarriers,
      policyType: form?.getFieldValue(policyTypeField.name),
      otherGroupCarriers: inAppetiteCheckboxGroup,
    });
    updateAppetiteCarrierListContainerByKey(
      ACCORD_CARRIER_LIST_STORE_KEYS.NOT_IN_APPETITE_CHECKBOX_GROUP,
      currentGroupCarriers,
    );
    updateAppetiteCarrierListContainerByKey(
      ACCORD_CARRIER_LIST_STORE_KEYS.IN_APPETITE_CHECKBOX_GROUP,
      otherGroupCarriers,
    );
  };

  return (
    <>
      {appetiteLoading && !clonedApplication?.loading && (
        <CFLoader marginTop='5px' marginBottom='10px' size={SPIN_SIZE.SMALL} />
      )}

      {isAppetiteListVisible && (
        <AppetiteCarrierList
          handleInAppetiteCheckboxChange={handleInAppetiteCheckboxChange}
          selectedCarriers={selectedCarriers}
          isAcordUpload={isAcordUpload}
          handleNotInAppetiteCheckboxChange={handleNotInAppetiteCheckboxChange}
          policyType={form?.getFieldValue(policyTypeField.name)}
        />
      )}
      {appetiteError && (
        <>
          <AppetiteWrapperStyled>
            <AppetiteErrorTitleStyled>An error occurred while fetching carriers</AppetiteErrorTitleStyled>
            <AppetiteInfoWrapperStyled>
              <CFButton buttonType={BUTTON_TYPE.PRIMARY} onClick={getAppetiteDetails} loading={appetiteLoading}>
                Retry
              </CFButton>
            </AppetiteInfoWrapperStyled>
          </AppetiteWrapperStyled>
        </>
      )}
    </>
  );
};

export default AppetiteCarrierListContainer;
