import { FileDoneOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import styled from 'styled-components/macro';

import CFCheckbox, { CFCheckboxGroup } from '../../../ui-core/V2/cfCheckbox/cfCheckbox';
import CFModal from '../../../ui-core/V2/cfModal/cfModal';

export const AcordImageLoaderStyled = styled.div`
  display: flex;
  justify-content: center;
`;

export const AcordTextLoaderStyled = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(p) => p?.theme?.typography?.fontSize?.extendedMedium};
  font-weight: ${(p) => p?.theme?.typography?.fontWeight?.medium};
  margin-top: 15px;
`;

export const CFModalStyled = styled(CFModal)`
  margin-top: 20px;
`;

export const AcordCheckboxStyled = styled(CFCheckboxGroup)`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  label {
    padding: 3px 0px;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    background-color: ${(p) => p?.theme?.custom?.fileContainer?.main} !important;
    border-color: ${(p) => p?.theme?.custom?.fileContainer?.main};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${(p) => p?.theme?.custom?.fileContainer?.main} !important;
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(p) => p?.theme?.custom?.fileContainer?.main};
  }
  .ant-checkbox-checked::after {
    border-color: ${(p) => p?.theme?.custom?.fileContainer?.main} !important;
  }
`;

export const CheckboxItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ColStyled = styled(Col)<{ isChecked?: boolean }>`
  .ant-checkbox-wrapper {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px;
    border: 1px solid
      ${(p) => (p?.isChecked ? p?.theme?.custom?.fileContainer?.main : p?.theme?.colors?.primary?.border)};
    border-radius: 8px;
    background-color: ${(p) => p?.isChecked && `${p?.theme?.custom?.fileContainer?.main}80`};
    min-height: 60px;
  }
`;

export const CFCheckboxStyled = styled(CFCheckbox)`
  .ant-checkbox {
    margin-top: -6px;
  }
`;

export const FileDoneOutlinedStyled = styled(FileDoneOutlined)`
  font-size: ${(p) => p?.theme?.typography?.fontSize?.extendedLarge};
`;

export const RowStyled = styled(Row)`
  justify-content: space-between;
`;

export const CheckboxLabelWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AcordLabelTextStyled = styled.div``;

export const PolicyTypeTextStyled = styled.div`
  font-size: ${(p) => p?.theme?.typography?.fontSize?.extraMini};
`;
