import { ICarrierInfo } from '@coverforce-platform/cf-common-api-model';
import { Carrier, PolicyType, USState as USStateType } from '@coverforce-platform/cf-common-types';

import { INDUSTRY_CODES } from '../../../constants/appetite';
import { PAGE_NAME, USState } from '../../../globalConstants';
import { GA_CATEGORY as ACCOUNT_GA_CATEGORY } from '../../../pages/v2/account/constants';
import { useProfileV2Store } from '../../../pages/v2/profile/store';
import { getCurrentPageName } from '../../../utils/getCurrentPageName';
import { APPETITE_CARRIER_LIST_CONTAINER_STORE_META_DATA } from '../../appetiteCarrierListContainer/constants';
import {
  initAppetiteDetailsStore,
  useAppetiteCarrierListContainerStore,
} from '../../appetiteCarrierListContainer/store';
import { useNewQuoteFormFieldStore } from '../../newQuoteFormField/store';
import { FORM_CONFIG, NEW_QUOTE_STORE_KEYS } from './constants';
import { IApplicationAcordConfig } from './interface';
import { useNewQuoteStore } from './store';

export const getSupportedCarriers = (carriersData: ICarrierInfo[], onCarriers: Carrier[], policyTypeId: PolicyType) => {
  const supportedCarriersArr: Array<{
    carrierId: Carrier;
    carrierDisplayName: string;
  }> = carriersData
    .filter((carrier) => {
      const availablePolicyTypes = carrier.availablePolicyTypes?.map((policy) => policy.policyTypeId);
      return availablePolicyTypes?.includes(policyTypeId);
    })
    .filter((carrier) => onCarriers?.includes(carrier?.carrierId))
    .map(({ carrierId, carrierDisplayName }) => ({
      carrierId,
      carrierDisplayName,
    }));

  return supportedCarriersArr;
};

export const getStateFromAccountId = (
  allAccountsData: Array<{ accountId: string; accountName: string; accountPrimaryState?: string }>,
  accountId: string,
) => {
  const stateInfo = {
    code: '',
    state: '',
  };
  const selectedAccountPrimaryStateCode = allAccountsData?.filter(
    (accountData: { accountId: string; accountName: string; accountPrimaryState?: string }) => {
      return accountId === accountData?.accountId;
    },
  )[0]?.accountPrimaryState;
  if (selectedAccountPrimaryStateCode) {
    stateInfo.code = selectedAccountPrimaryStateCode;
    stateInfo.state = USState[selectedAccountPrimaryStateCode];
    return stateInfo;
  }
};

export const isPolicyTypeAllowedForAcord = (
  allowedPolicyTypesForAcord: IApplicationAcordConfig[],
  policyType: PolicyType,
): boolean => {
  const acordDetailByPolicyType = allowedPolicyTypesForAcord?.find(
    (policyTypeAcordDetails: IApplicationAcordConfig) => policyTypeAcordDetails.policyType === policyType,
  );
  /** If ".enabled" is not there it will return false */
  return acordDetailByPolicyType ? Boolean(acordDetailByPolicyType.enabled) : false;
};

export const getGACategory = () => {
  const currentPage = getCurrentPageName();
  switch (currentPage) {
    case PAGE_NAME.ACCOUNT_DETAIL:
      return ACCOUNT_GA_CATEGORY.ACCOUNT_NEW_QUOTE_MODAL;

    default:
      return currentPage;
  }
};

export const autoFillCloneApplicationValues = async () => {
  const {
    clonedApplicationId,
    updateNewQuoteByKey,
    fetchApplicationById,
    fetchCarrierRankingByPolicyTypeId,
    newQuoteModalForm: form,
  } = useNewQuoteStore.getState();
  const { updateAppetiteCarrierListContainerByKey } = useAppetiteCarrierListContainerStore.getState();
  const { naicsCodesStore, allAccountsStore, selectedIndustryCodeType } = useNewQuoteFormFieldStore.getState();
  const { industryCodeField, policyTypeField, stateField, accountsField } = FORM_CONFIG(selectedIndustryCodeType);
  const { userData } = useProfileV2Store.getState();
  if (clonedApplicationId) {
    updateNewQuoteByKey(NEW_QUOTE_STORE_KEYS.CLONED_APPLICATION, {
      loading: true,
      error: undefined,
      data: undefined,
    });
    if (!naicsCodesStore.naicsCodesLoading && !allAccountsStore.allAccountsLoading) {
      const application = await fetchApplicationById(clonedApplicationId);
      if (application) {
        const naicsCodeInfo = naicsCodesStore?.naicsCodesData?.find(
          (naicsCode) =>
            application?.basicBusinessDetails?.naicsExtendedId === naicsCode?.value?.split('-')?.[1]?.trim(),
        );
        if (
          naicsCodeInfo &&
          naicsCodeInfo.value &&
          application.policyDetails?.[0]?.policyType &&
          application?.locationDetails?.[0]?.address?.state
        ) {
          updateAppetiteCarrierListContainerByKey(
            APPETITE_CARRIER_LIST_CONTAINER_STORE_META_DATA.APPETITE_DETAILS_STORE,
            {
              ...initAppetiteDetailsStore,
              appetiteLoading: true,
            },
          );
        }

        form?.setFieldValue(accountsField.name, application?.applicationDetails?.accountId);

        form?.setFieldValue(industryCodeField.name, naicsCodeInfo && naicsCodeInfo.value);

        form?.setFieldValue(policyTypeField.name, application.policyDetails?.[0]?.policyType);
        // At 0 index always have primary location
        form?.setFieldValue(stateField.name, application?.locationDetails?.[0]?.address?.state);

        await fetchCarrierRankingByPolicyTypeId({
          agentId: userData!.agentId,
          policyTypeId: application.policyDetails?.[0]?.policyType!,
        });
      }
    }
  }
};

export const getAppetiteDetails = () => {
  const { newQuoteModalForm: form, supportedCarriersStore } = useNewQuoteStore.getState();
  const { fetchAppetiteDetails } = useAppetiteCarrierListContainerStore.getState();
  const { selectedIndustryCodeType } = useNewQuoteFormFieldStore.getState();
  const { supportedCarriersData } = supportedCarriersStore;
  const { policyTypeField } = FORM_CONFIG(selectedIndustryCodeType);

  const formAnswers = form?.getFieldsValue();
  const { policyType, naicsCode, state, sicCode } = formAnswers;
  const industryCode = selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE ? naicsCode : sicCode;
  const industryCodePayload = {
    [selectedIndustryCodeType === INDUSTRY_CODES.NAICS.TYPE ? INDUSTRY_CODES.NAICS.KEY : INDUSTRY_CODES.SIC.KEY]:
      industryCode?.split('-')[0]?.trim(),
  };
  const availableAppetiteCarriers: string[] = supportedCarriersData?.[form?.getFieldValue(policyTypeField.name)]
    ? supportedCarriersData?.[form?.getFieldValue(policyTypeField.name)].map(
        ({ carrierId }: { carrierId: string }) => carrierId,
      )
    : [];
  if (policyType && industryCode && state) {
    fetchAppetiteDetails({
      carriers: availableAppetiteCarriers as Carrier[],
      ...industryCodePayload,
      policyType: policyType as PolicyType,
      state: state as USStateType,
    });
  }
};
