import { AxiosResponse } from 'axios';

import axiosStaticFileService from '../../axios/staticFilesService/instance';
import { createQueryUrl } from '../../utils/createQueryUrl';
import { S3_BUCKET_ENDPOINTS } from '../helpers/ApiRoutes';

const fetchAgencyList = async (agencyListFileURL: string) => {
  const apiUrl = agencyListFileURL;

  try {
    const response: AxiosResponse = await axiosStaticFileService.get(apiUrl, {
      customConfig: { isS3DirectDownload: true },
    });
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const fetchCarrierStatus = async () => {
  const apiUrl = createQueryUrl({
    url: S3_BUCKET_ENDPOINTS.CARRIER_STATUS,
  });

  try {
    const response: AxiosResponse = await axiosStaticFileService.get(apiUrl, {
      customConfig: { isS3DirectDownload: true },
    });
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const fetchAnnouncements = async () => {
  const apiUrl = createQueryUrl({
    url: S3_BUCKET_ENDPOINTS.PLATFORM_ANNOUNCEMENTS,
  });

  try {
    const response: AxiosResponse = await axiosStaticFileService.get(apiUrl, {
      customConfig: { isS3DirectDownload: true },
    });
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

const fetchReportTypeInfo = async () => {
  const apiUrl = createQueryUrl({
    url: S3_BUCKET_ENDPOINTS.REPORT_TYPE_INFO,
  });

  try {
    const response: AxiosResponse = await axiosStaticFileService.get(apiUrl, {
      customConfig: { isS3DirectDownload: true },
    });
    return response?.data;
  } catch (error: any) {
    return Promise.reject(error?.response?.data?.errors);
  }
};

export { fetchAgencyList, fetchCarrierStatus, fetchAnnouncements, fetchReportTypeInfo };
