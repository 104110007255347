import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { getMessaging, Messaging } from 'firebase/messaging';

import {
  FIREBASE_CONFIG_API_KEY,
  FIREBASE_CONFIG_APP_ID,
  FIREBASE_CONFIG_AUTH_DOMAIN,
  FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  FIREBASE_CONFIG_PROJECT_ID,
} from '../helpers/EnvironmentVariables';

let firebaseMessaging: Messaging;

const FIREBASE_OPTIONS: FirebaseOptions = {
  apiKey: FIREBASE_CONFIG_API_KEY,
  authDomain: FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: FIREBASE_CONFIG_PROJECT_ID,
  messagingSenderId: FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: FIREBASE_CONFIG_APP_ID,
};

export const initialiseFirebase = () => {
  const firebaseApp: FirebaseApp = initializeApp(FIREBASE_OPTIONS);
  firebaseMessaging = getMessaging(firebaseApp);
};

export { firebaseMessaging };
