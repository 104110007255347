import { Carrier } from '@coverforce-platform/cf-common-types';

import { IBrokerCodesStore } from '../components/brokerCodes/interface';
import { helpCenterLink } from '../globalConstants';

export const BROKER_CODE_MESSAGES = {
  ADD: 'Broker Code added successfully!',
  UPDATE: 'Broker Code updated successfully!',
  DELETE: 'Broker Code deleted successfully!',
  CHUBB_ADD_BROKER_CODE_SUCCESS:
    'Broker Code added. Chubb takes upto 4 hours to onboard the code. Please wait 4 hours before using the code!',
  CHUBB_UPDATE_BROKER_CODE_SUCCESS:
    'Broker Code updated. Chubb takes upto 4 hours to onboard the code. Please wait 4 hours before using the code!',
};

export const BROKER_CODE_ACTION_TYPES = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
};

export const BROKER_CONFIG_PAGE_INFO = {
  title: 'Broker ID Setup',
  subTitle:
    'Save your broker IDs here. For recognition by carriers, you’ll be prompted to confirm which ID you want to use as you are completing the single application form for each customer.',
  tabsTitle: 'Carrier',
  tableEmptyMessage:
    'No Broker ID configs have been added yet for this carrier. Add a new Broker ID configuration to get started.',
};

export const DISABLED_BROKER_CONFIG_LIST = [
  Carrier.CNA,
  Carrier.GAIG,
  Carrier.MARKEL,
  Carrier.COALITION,
  Carrier.COALITION_SURPLUS,
  Carrier.EMPLOYERS,
  Carrier.HISCOX,
  Carrier.REPUBLIC_INDEMNITY,
];

export const CARRIER_BROKER_CODE_HELP_LINK: { [k: string]: string } = {
  [Carrier.AMTRUST]: `${helpCenterLink}/amtrust-settings`,
  [Carrier.CHUBB]: `${helpCenterLink}/chubb-find-direct-appointment-information`,
  [Carrier.CNA]: `${helpCenterLink}/cna-find-direct-appointment-information`,
  [Carrier.EMPLOYERS]: `${helpCenterLink}/employers-find-direct-appointment-information`,
  [Carrier.GAIG]: `${helpCenterLink}/great-american-find-direct-appointment-information`,
  [Carrier.LIBERTYMUTUAL]: `${helpCenterLink}/liberty-mutual-find-direct-appointment-information`,
  [Carrier.NATIONWIDE]: `${helpCenterLink}/nationwide-find-direct-appointment-information`,
  [Carrier.PROGRESSIVE]: '',
  [Carrier.TRAVELERS]: `${helpCenterLink}/travelers-find-direct-appointment-information`,
  [Carrier.COALITION]: `${helpCenterLink}/coalition-find-direct-appointment-information`,
  [Carrier.COALITION_SURPLUS]: `${helpCenterLink}/coalition-find-direct-appointment-information`,
  [Carrier.MARKEL]: `${helpCenterLink}/markel-find-direct-appointment-information`,
  [Carrier.MERCHANTS]: `${helpCenterLink}/merchants-find-direct-appointment-information`,
  [Carrier.GUARD]: `${helpCenterLink}/guard-find-direct-appointment-information`,
  [Carrier.ACCIDENTFUND]: `${helpCenterLink}/accident-fund-find-direct-appointment-information`,
  [Carrier.COMPWEST]: `${helpCenterLink}/comp-west-find-direct-appointment-information`,
  [Carrier.COTERIE]: `${helpCenterLink}/coterie-find-direct-appointment-information`,
  [Carrier.HISCOX]: `${helpCenterLink}/hiscox-find-direct-appointment-information`,
  [Carrier.FIRST_INSURANCE]: `${helpCenterLink}/first-insurance-find-direct-appointment-information`,
  [Carrier.REPUBLIC_INDEMNITY]: `${helpCenterLink}/republic-indemnity-find-direct-appointment-information`,
};

export const BROKER_CODES_STORE_KEYS = {
  ALL_CARRIER_BROKER_CONFIGS: 'allCarrierBrokerConfigs' as keyof IBrokerCodesStore,
};
