import { AcordAdditionalFormTypes, AcordFormType, PolicyType } from '@coverforce-platform/cf-common-types';

import { useApplicationStore } from '../../../pages/v2/application/store';
import { useProfileV2Store } from '../../../pages/v2/profile/store';
import { ATTACH_UWQ_VALUE, RADIO_BUTTON_VALUES } from '../../underwritingAccordSection/constants';
import { useDownloadAcordStore } from './store';

export const getPolicyTypeForAcord = (acord: AcordFormType) => {
  switch (acord) {
    case AcordFormType.ACORD_125:
      return PolicyType.CAUTO; /** Commercial Insurance */
    case AcordFormType.ACORD_126:
      return PolicyType.CGL; /** CGL */
    case AcordFormType.ACORD_130:
      return PolicyType.WC; /** WC */
    case AcordFormType.ACORD_160:
      return PolicyType.BOP; /** BOP */
  }
};

export const getRequestAcordPayload = (requestedAcordFormsType: AcordFormType[]) => {
  const { downloadAcordForm } = useDownloadAcordStore.getState();
  const { applicationData } = useApplicationStore.getState();
  const { userData } = useProfileV2Store.getState();

  const formValues = downloadAcordForm?.getFieldsValue();

  return {
    applicationId: applicationData?.applicationId ?? '',
    requestedAcordFormsType,
    emailTo: [userData?.email ?? ''],
    downloadRequestedBy: userData?.agentId,
    ...(formValues?.isAddUnderwritingSupplement === RADIO_BUTTON_VALUES.TRUE
      ? {
          additionalDataPDFs:
            formValues.attachTo?.[0] === ATTACH_UWQ_VALUE.NONE
              ? [AcordAdditionalFormTypes.UNDERWRITING_DETAILS] // if user want to download separate UWQ ACCORD then we need to pass this
              : [], // Apart from none all options for attach one so we dont need to pass this.
          appendUWDetailstoAcordForms:
            formValues.attachTo?.[0] === ATTACH_UWQ_VALUE.NONE
              ? [] // if user want to download separate UWQ ACCORD
              : formValues.attachTo?.[0] === ATTACH_UWQ_VALUE.ALL
              ? requestedAcordFormsType // ALL means we want  to attach UWQ to all selected ACORD FORMS
              : formValues.attachTo, // Only selected accord forms has attached UWQ accord
        }
      : {}),
  };
};
