import { Card, Divider, Tag } from 'antd';
import styled from 'styled-components/macro';

import Anchor from '../../ui-core/V2/anchor/anchor';
import { CFRibbon } from '../../ui-core/V2/cfBadge/cfBadge';

export const QuoteCardBannerTextRibbonStyled = styled(CFRibbon)
  .attrs((props: any) => props)
  .withConfig({
    shouldForwardProp: (props: any) => !['showBanner', 'bannerColour'].includes(props),
  })`
  display: ${(p) => (p?.showBanner ? '' : 'none')};
  box-shadow: ${(p) => p?.theme?.custom?.boxShadow?.black};
  background-color: ${(p) => (p?.showBanner ? p?.bannerColour : '')};
  
  .ant-ribbon-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${(p) => p.theme?.typography?.fontWeight?.mediumBold};
    font-size: ${(p) => p?.theme?.typography?.fontSize?.extendedMediumLarge};
  }

  .ant-ribbon-corner {
    display: none;
  }
`;

export const QuoteCardTagStyled = styled(Tag)`
  position: relative;
  height: auto;
  width: auto;
  padding: 4px;
  z-index: 1;
`;

export const QuoteCardTagWrapperStyled = styled.div`
  position: absolute;
  right: -8px;
`;

export const QuoteCardStyled = styled(Card)
  .attrs((props: any) => props)
  .withConfig({
    shouldForwardProp: (props: any) => !['showBanner', 'bannerColour'].includes(props),
  })`
  border: ${(p) => (p?.showBanner ? `2px solid ${p?.bannerColour}` : '')};

  .ant-card-head {
    margin: 0px 24px;
    padding: 12px 0px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  }
  .ant-form-item:last-child {
    margin-bottom: 10px;
  }
`;

export const LoadingCarrierLogoStyled = styled.div`
  display: flex;
  align-items: center;
  max-width: 250px;
  min-height: 45px;
  margin-left: 5px;
  margin-top: 6px;
`;

export const QuoteBannerTextDividerStyled = styled(Divider)`
  border-left: 1px solid rgba(255, 255, 255);
  height: 1.5em;
`;

export const QuoteBridgeAnchorLinkStyled = styled(Anchor)`
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;
