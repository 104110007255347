import { Divider } from 'antd';
import styled from 'styled-components/macro';

import Anchor from '../../ui-core/V2/anchor/anchor';
import CFCheckbox, { CFCheckboxGroup } from '../../ui-core/V2/cfCheckbox/cfCheckbox';

export const AppetiteCheckboxTitleStyled = styled.div<{
  disabled: boolean;
}>`
  font-size: ${(p) => p.theme.typography.fontSize.extendedMedium};
  font-weight: ${(p) => p.theme.typography.fontWeight.medium};
  opacity: ${(p) => (p.disabled ? 0.25 : 1)};
`;

export const AppetiteCheckboxWrapperStyled = styled(CFCheckboxGroup)`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  label {
    padding: 3px 0px;
  }
`;

export const AppetiteWrapperStyled = styled.div`
  padding-top: 10px;
  box-shadow: ${(p) => p.theme.custom.boxShadow.dashBoard};
  padding-bottom: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const AppetiteTitleStyled = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  justify-content: center;
  font-weight: ${(p) => p.theme.typography.fontWeight.bold};
  padding-bottom: 8px;
  border-bottom: 1px solid ${(p) => p.theme.colors.primary.borderLight};
  opacity: ${(p) => (p.disabled ? 0.25 : 1)};
`;

export const AppetiteInfoWrapperStyled = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding: 0 20px;
`;

export const AppetiteDetailsWrapperStyled = styled.div`
  width: 35%;
`;

export const AppetiteErrorStyled = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  color: ${(p) => p.theme.colors.primary.requiredText};
  font-weight: ${(p) => p.theme.typography.fontWeight.mediumBold};
  margin-bottom: 20px;
`;

export const DividerStyled = styled(Divider)`
  height: inherit;
  margin: 8px 0px;
`;

export const CarrierLabelStyled = styled.p`
  padding: 0;
  margin: 4px 0px;
`;
export const CFCheckboxStyled = styled(CFCheckbox)``;

export const AnchorStyled = styled(Anchor)`
  color: inherit;
  text-decoration: underline;
`;
