import styled from 'styled-components/macro';

import CFButton from '../../ui-core/V2/cfButton/cfButton';

export const ErrorTextStyled = styled.div`
  margin-top: 20px;
`;

export const RetryButtonStyled = styled(CFButton)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: fit-content;
`;
