import { ICFTableProps } from './interface';
import { CFTableLoaderStyled, CFTableStyled } from './styles';

const { Column } = CFTableStyled;

export { Column as CFTableColumn };

const CFTable = (props: ICFTableProps) => {
  const { children, dataSource, ...restTableProps } = props;

  return (
    <>
      {(!dataSource || dataSource?.length === 0) && (
        <CFTableLoaderStyled {...restTableProps} scroll={undefined}>
          {children}
        </CFTableLoaderStyled>
      )}
      {dataSource && dataSource?.length > 0 && (
        <CFTableStyled {...restTableProps} dataSource={dataSource}>
          {children}
        </CFTableStyled>
      )}
    </>
  );
};

export default CFTable;
