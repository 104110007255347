import {
  IApplicationStatusInfo,
  IBOPLiabilityLimitsInfo,
  ICGLLiabilityLimitsInfo,
  ICyberLiabilityLimitsInfo,
  ILiabilityLimitsInfo,
  IWCLiabilityLimitsInfo,
} from '@coverforce-platform/cf-common-api-model';
import { ApplicationStatus, ApplicationTag, PolicyType } from '@coverforce-platform/cf-common-types';
import dayjs from 'dayjs';
import { DefaultTheme } from 'styled-components';

import { each, isObject, unset } from '../utils/lodash';

export const getEmailRegex = () => {
  return new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
};

export const digitsOnlyRegex = () => {
  return new RegExp(/[^0-9]+/g);
};

export const getStateRegex = () => {
  return new RegExp(/^[a-z]{2}$/i);
};

export const getZipRegex = () => {
  return new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/i);
};

export const normalizePhoneNumber = (value?: string) => {
  // return nothing if no value
  if (!value) {
    return '';
  }

  if (value.startsWith('+1')) {
    value = value.slice(2);
  }

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  // returns: "x", "xx", "xxx"
  if (cvLength < 4) {
    return currentValue;
  }

  // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
  if (cvLength < 7) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  }

  // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
};

export const deNormalizePhoneNumber = (value?: string) => {
  // return nothing if no value
  if (!value) {
    return '';
  }

  // only allows 0-9 inputs and replace all the other things with blank string
  const currentValue = value.replace(/[^\d]/g, '');
  return `+1${currentValue}`;
};

export const getPhoneNumberRegex = (value: string) => {
  if (!value) {
    return false;
  }
  return /^(\([2-9][0-9]{2}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
};

export const normalizeFeinNumber = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }

  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  return cvLength > 2 ? `${currentValue.slice(0, 2)}-${currentValue.slice(2, 9)}` : currentValue;
};

export const deNormalizeFeinNumber = (value: string | undefined) => {
  if (!value) {
    return '';
  }
  return value.replace('-', '');
};

export const formatDateTime = (
  dateTime: string | undefined,
  format: string = 'MMM D, YYYY',
  convertToLocalTime: boolean = true,
): string => {
  if (!dateTime) {
    return '';
  }
  /**
   * .format('L')      // 06/09/2014
   * .format('l')      // 6/9/2014
   * .format('LL')     // June 9 2014
   * .format('ll')     // Jun 9 2014
   * .format('LLL')    // June 9 2014 9:32 PM
   * .format('lll')    // Jun 9 2014 9:32 PM
   * .format('LLLL')   // Monday, June 9 2014 9:32 PM
   * .format('llll')   // Mon, Jun 9 2014 9:32 PM
   */
  // Dayjs Formats:- https://day.js.org/docs/en/display/format
  const utcDateTime = dayjs(dateTime);
  if (convertToLocalTime) {
    utcDateTime.local();
  }
  return utcDateTime.format(format);
};

export const getFeinNumberRegex = (value: string) => {
  if (!value) {
    return false;
  }
  return /^\d{2}-\d{7}$/.test(value);
};

export const capitalizeText = ({
  text,
  specialCharacter = '_',
}: {
  text: string | undefined;
  specialCharacter?: string;
}): string => {
  if (!text) {
    return '';
  }
  return text.charAt(0)?.toUpperCase() + text.toLowerCase()?.slice(1)?.replaceAll(specialCharacter, ' ');
};

export const deNormalizeMoneyInput = (value?: string) => {
  // return nothing if no value
  if (value === undefined) {
    return '';
  }

  // only allows 0-9 inputs and replace all the other things with blank string
  const currentValue = value.replace(/[^\d]/g, '');
  return currentValue;
};

export const normalizeMoneyInput = (value?: string) => {
  // return nothing if no value
  if (!value || value === '$') {
    return '';
  }

  // only allows 0-9 inputs
  const currentValue = Number(value.replace(/[^\d]/g, ''));

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  return formatter.format(currentValue);
};

export const getApplicationStatusColorCodeMap = (theme: DefaultTheme) => {
  const colorCodeMap: Map<ApplicationStatus | ApplicationTag, string> = new Map();

  colorCodeMap.set(ApplicationStatus.ACORD_IN_PROGRESS, theme.custom.applicationStatus.acordInProgress);
  colorCodeMap.set(ApplicationStatus.CREATED, theme.custom.applicationStatus.created);
  colorCodeMap.set(ApplicationStatus.IN_PROGRESS, theme.custom.applicationStatus.inProgress);
  colorCodeMap.set(ApplicationStatus.QUOTING, theme.custom.applicationStatus.quoting);
  colorCodeMap.set(ApplicationStatus.QUOTED, theme.custom.applicationStatus.quoted);
  colorCodeMap.set(ApplicationStatus.REFERRED, theme.custom.applicationStatus.referred);
  colorCodeMap.set(ApplicationStatus.BOUND, theme.custom.applicationStatus.bound);
  colorCodeMap.set(ApplicationStatus.DELETED, theme.custom.applicationStatus.deleted);
  colorCodeMap.set(ApplicationTag.REASSIGNED, theme.custom.applicationStatus.reassign);
  colorCodeMap.set(ApplicationTag.BIND_REQUESTED, theme.custom.applicationStatus.bindRequested);

  return colorCodeMap;
};

export const getIsApplicationNonEditable = (applicationStatus: IApplicationStatusInfo | undefined) => {
  if (applicationStatus === undefined) {
    return false;
  }
  return [ApplicationStatus.REFERRED, ApplicationStatus.BOUND, ApplicationStatus.DELETED].includes(
    applicationStatus.statusCode,
  );
};

const normalizeMoney = (amount: string, decimalCount = 2, decimal = '.', separator = ',') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = parseFloat(amount) < 0 ? '-' : '';

    const decimalNumber: any = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    const reminder = decimalNumber.length > 3 ? decimalNumber.length % 3 : 0;

    return (
      negativeSign +
      (reminder ? decimalNumber.substr(0, reminder) + separator : '') +
      decimalNumber.substr(reminder).replace(/(\d{3})(?=\d)/g, `$1${separator}`) +
      (decimalCount
        ? decimal +
          Math.abs(parseFloat(amount) - decimalNumber)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const removeDecimal = (value?: string) => {
  if (!value) {
    return;
  }
  return value.split('.')[0];
};

export const formatDate = (date?: string, defaultFormat = 'MM-DD-YYYY', convertToLocalTime = true): string => {
  if (!date) {
    return '';
  }
  const utcDateTime = dayjs(date);
  if (convertToLocalTime) {
    utcDateTime.local();
  }
  return utcDateTime.format(defaultFormat);
};

export const formatPrice = (price: string | undefined) => {
  if (price === undefined) {
    return normalizeMoney('0');
  }
  const currentValue = price.replace(/[^\d]\./g, '');
  return normalizeMoney(currentValue);
};

export const formatToUSCurrencySystem = (price: string | undefined) => {
  // Nine Zeroes for Billions
  const parsedPrice = Number(price);
  let displayValue =
    Math.abs(parsedPrice) >= 1.0e9
      ? `${(Math.abs(parsedPrice) / 1.0e9).toFixed(2)}B`
      : // Six Zeroes for Millions
      Math.abs(parsedPrice) >= 1.0e6
      ? `${(Math.abs(parsedPrice) / 1.0e6).toFixed(2)}M`
      : // Three Zeroes for Thousands
      Math.abs(parsedPrice) >= 1.0e3
      ? `${(Math.abs(parsedPrice) / 1.0e3).toFixed(2)}K`
      : Math.abs(parsedPrice);
  displayValue = `${displayValue}`;
  const numberValue = Number(displayValue.substring(0, displayValue.length - 1));
  const currenyDenotor = displayValue.charAt(displayValue.length - 1);
  if (numberValue % 1 === 0) {
    return Number(numberValue).toFixed(0) + currenyDenotor;
  }
  return displayValue;
};

export const normalizeExperience = (val: string) => {
  if (!val) {
    return undefined;
  }

  const currentValue = val.replace(/[^\d]/g, '');

  return currentValue.slice(0, 2);
};

const omitByRecursivelyInPlace = (value: any, iteratee: Function) => {
  each(value, (v, k) => {
    if (iteratee(v, k)) {
      unset(value, k);
    } else if (isObject(v)) {
      omitByRecursivelyInPlace(v, iteratee);
    }
  });

  return value;
};

export const removeUndefinedProperty = (object: any) => {
  return omitByRecursivelyInPlace(object, (value: any) => {
    return value === undefined;
  });
};

export const removeFalseProperty = (object: any) => {
  return omitByRecursivelyInPlace(object, (value: any) => {
    return !value;
  });
};

export const getPolicyTypeSpecificLiabilityLimits = ({
  policyType,
  liabilityLimit,
}: {
  policyType?: PolicyType;
  liabilityLimit?: ILiabilityLimitsInfo;
}) => {
  if (!liabilityLimit) {
    return {};
  }
  switch (policyType) {
    case PolicyType.WC: {
      const limits = liabilityLimit as IWCLiabilityLimitsInfo;
      return {
        wcPerAccidentLimit: limits?.wcPerAccidentLimit?.toString(),
        wcPerDiseasePolicyLimit: limits?.wcPerDiseasePolicyLimit?.toString(),
        wcPerDiseaseEmployeeLimit: limits?.wcPerDiseaseEmployeeLimit?.toString(),
      };
    }
    case PolicyType.BOP: {
      const limits = liabilityLimit as IBOPLiabilityLimitsInfo;
      return {
        bopPerOccurrenceLimit: limits?.bopPerOccurrenceLimit?.toString(),
        bopAggregateLimit: limits?.bopAggregateLimit?.toString(),
      };
    }
    case PolicyType.CGL: {
      const limits = liabilityLimit as ICGLLiabilityLimitsInfo;
      return {
        cglAggregateLimit: limits?.cglAggregateLimit?.toString(),
        cglPerOccurrenceLimit: limits?.cglPerOccurrenceLimit?.toString(),
      };
    }

    case PolicyType.CYBER: {
      const limits = liabilityLimit as ICyberLiabilityLimitsInfo;
      return {
        cyberAggregateLimit: limits?.cyberAggregateLimit?.toString(),
        cyberRetentionLimit: limits?.cyberRetentionLimit?.toString(),
      };
    }

    default:
      return {};
  }
};
