import styled from 'styled-components/macro';

import CFForm from '../../ui-core/V2/cfForm/cfForm';
import ApplicationQuestion from '../applicationQuestion/applicationQuestion';

export const ScheduleReportQuestionStyled = styled(ApplicationQuestion)`
  .ant-picker-range {
    width: 100%;
  }
  .ant-checkbox-wrapper {
    padding-bottom: 7px;
  }
`;

export const ScheduleReportFormStyled = styled(CFForm)`
  .ant-picker-cell-selected > .ant-picker-cell-inner {
    background-color: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-picker-range .ant-picker-active-bar {
    background: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: ${(p) => p?.theme?.colorScheme?.primary};
    opacity: 0.1;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: ${(p) => p?.theme?.colorScheme?.primary};
    opacity: 0.1;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: ${(p) => p?.theme?.colorScheme?.primary};
  }

  .ant-picker-time-panel-column {
    width: 60px;
  }
`;

export const ReportTypeColumnInfoStyled = styled.div`
  margin-bottom: 30px;

  .ant-table-cell {
    padding: 0 !important; //Intentional override, as !important is given in the core component.
    > div {
      padding: 8px 20px;
    }
  }
`;

export const ReportTypeColumnInfoHeaderStyled = styled.div`
  margin-bottom: 5px;
`;

export const ReportTypeColumnInfoTitleStyled = styled.span`
  font-weight: ${(p) => p.theme?.typography?.fontWeight?.mediumBold};
`;
